import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import playCircle from "../Image/PlayCircle.svg";
import eye from "../Image/Eye.svg";
import profileImage from "../Image/ProfileImage.svg";
import ProfileModal from "./ProfileModal";
import pause_icon from "../Image/pause_icon.png";
import AudioPlayer from "./AudioPlayer";

const Container = styled.div`
  border-radius: 10px;
  padding: 8px;
  color: #000;
  margin-bottom: 8px;
  min-height: 90px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: ${(props) => bgcolorChange(props)};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const TextContent = styled.div``;

const SkillKeywordsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
`;

const SkillKeyword = styled.div`
  background-color: #0a65cc;
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 8px;
  margin-bottom: 8px;
`;
const SkillKeywordsLabel = styled.span`
  font-weight: bold;
`;

const SkillKeywordsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
`;

const SkillKeywordItem = styled.li`
  background-color: #e7f0fa;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
`;

function bgcolorChange(props) {
  return props.isDragging
    ? "outline"
    : props.isDraggable
    ? props.isBacklog
      ? "#F2D7D5"
      : "#DCDCDC"
    : props.isBacklog
    ? "#F2D7D5"
    : "#FFF";
}

function Task({ task, index }) {
  const [data, setData] = useState(null);
  const [filenamee, setFilenamee] = useState(null);
  const [audioElement, setAudioElement] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [dataURL, setDataURL] = useState(null);
  // const [audio] = useState(new Audio());
  const [isClicked, setIsClicked] = useState(false);

  // Extracting analysis and score
  const extractAnalysisAndScore = (task) => {
    try {
      console.log("taa", task.matching[0]);

      // Check if 'matching' array is present in the task
      if (!task.matching || task.matching.length === 0) {
        throw new Error("Matching array not found or empty");
      }

      // Extracting the first element from the array
      const matchingItem = task.matching[0];
      const startIndex = matchingItem.indexOf('{');
      const endIndex = matchingItem.lastIndexOf('}');
      // Extract the JSON substring
      const jsonPart = matchingItem.substring(startIndex, endIndex + 1);
        console.log("checking json part", jsonPart)
      // Parsing the JSON
      const jsonResponse = JSON.stringify(jsonPart);
      const cleanedJsonString = jsonPart.replace(/\\_/g, "_")
      let parsedJson = JSON.parse(cleanedJsonString);
      console.log("parsedJson", parsedJson);
      console.log("type checking",typeof parsedJson);
      console.log("checking fieldnames", parsedJson["industries"])
      const skillkeywords =
      parsedJson["Skill Keywords"] || parsedJson["skill\_keywords"] || parsedJson["Skills"] || "No Skill Keywords available";

      const reasoning = parsedJson["Reasoning"] || parsedJson["reasoning"] || "No Reasoning available";

      // Extracting the "Match Score"
      // const matchScore = jsonResponse["Match Score"] || "N/A";
      //matchScore
      console.log("match", task.matchScore)
      const matchScore = task.matchScore || parsedJson["score"] || 0;


      return { skillkeywords, matchScore };
    } catch (error) {
      console.error("Error extracting analysis and score:", error);
      // Return a default value or handle the error gracefully
      return {
        skillkeywords: "No Skill Keywords available",
        matchScore: "N/A",
      };
    }
  };
  
  const { skillkeywords, matchScore } = extractAnalysisAndScore(task);
  console.log("Analysis:", skillkeywords);
  console.log("Matching Score:", matchScore);

  const skillkeywordsArr = Array.isArray(skillkeywords)
    ? skillkeywords
    : skillkeywords
    ? skillkeywords.split(/,/).map((keyword) => keyword.trim())
    : [];

  console.log("skillarr", skillkeywordsArr);

  // Extracting only the numeric value from the matchScore
  const numericMatchScore = parseInt(matchScore, 10);

  const handlePlay = (jobid, userid) => {
    //${process.env.REACT_APP_BACKEND_BASE_URL}/clients/totalapplicants
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/${userid}/${jobid}`)
      .then((res) => res.json())
      .then((responseData) => {
        console.log("Audio file name:", responseData.msg);

        setFilenamee(responseData.msg.audioData); // Set the data in the state
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const toggleAudio = () => {
    if (!audioElement) {
      const newAudioElement = new Audio(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/gridStorage/${filenamee}`
      );
      newAudioElement
        .play()
        .then(() => {
          setIsAudioPlaying(true);
        })
        .catch((error) => {
          console.error("Error playing audio:", error);
        });
      setAudioElement(newAudioElement);
    } else {
      // Toggle play/pause
      if (audioElement.paused) {
        audioElement
          .play()
          .then(() => {
            setIsAudioPlaying(true);
          })
          .catch((error) => {
            console.error("Error resuming audio playback:", error);
          });
      } else {
        audioElement.pause();
        setIsAudioPlaying(false);
      }
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    // window.location.reload();
  };

  return (
    <div>
      <Draggable draggableId={`${task._id}`} key={task._id} index={index}>
        {(provided, snapshot) => (
          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
          >
            <div
              style={{ display: "flex", justifyContent: "start", padding: 2 }}
            >
              <span>
                <small>
                  {/* #{task._id} */}
                  {"  "}
                </small>
              </span>
            </div>
            <div style={{ padding: 2 }}>
              <ul>
                <TextContent>
                  <div style={{ display: "flex" }}>
                    <div>
                      <img
                        src={profileImage}
                        alt="profileImage.svg"
                        style={{ marginLeft: "-30px", paddingRight: "12px" }}
                      />
                    </div>
                    <div>
                      <span style={{ fontWeight: "600" }}>{task.name}</span>

                      <p>Los Angeles CA, 90017</p>
                    </div>
                  </div>
                  <hr style={{ marginTop: "-1px", marginLeft: "-30px" }} />
                </TextContent>
                <li>
                  <TextContent style={{ marginBottom: "5px" }}>
                    Avaliable to start : {task.Availability}
                  </TextContent>
                </li>
                <li>
                  <TextContent style={{ marginBottom: "5px" }}>
                    Interview Avaliablity : {task.interviewAvailability}
                  </TextContent>
                </li>
                <li>
                  <TextContent style={{ marginBottom: "5px" }}>
                    Sponsorship required : {task.sponsorship}
                  </TextContent>
                </li>
                <li>
                  <TextContent style={{ marginBottom: "5px" }}>
                    Match Score : {(parseFloat(matchScore).toFixed(1))}/10
                  </TextContent>
                </li>
                <li>
                  <TextContent style={{ marginBottom: "5px" }}>
                    Skill Keywords:
                    {skillkeywordsArr && skillkeywordsArr.length > 0 ? (
                      <SkillKeywordsList>
                        {skillkeywordsArr.slice(0, 6).map((keyword, index) => (
                          <SkillKeywordItem key={index}>
                            {keyword}
                          </SkillKeywordItem>
                        ))}
                      </SkillKeywordsList>
                    ) : (
                      <span>No Skill Keywords available</span>
                    )}
                  </TextContent>
                </li>

                {/* <TextContent
                  style={{
                    color: "#0A65CC",
                    marginLeft: "-25px",
                    marginTop: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => handlePlay(task.jobUniqueID, task.userID)}
                >
                  {!isAudioPlaying ? (
                    <img
                      src={playCircle}
                      alt="PlayCricle.svg"
                      style={{ paddingRight: "7px" }}
                      onClick={(e) => {
                        toggleAudio();
                      }}
                    />
                  ) : (
                    <img
                      src={pause_icon}
                      alt="PlayCricle.svg"
                      style={{ paddingRight: "7px" }}
                      width="25px"
                      onClick={(e) => {
                        toggleAudio();
                      }}
                    />
                  )}
                  Play Voice Intro
        
                </TextContent> */}
                <TextContent
                  style={{
                    color: "#0A65CC",
                    marginLeft: "-25px",
                    marginTop: "8px",
                    cursor: "pointer",
                  }}
                  onClick={openModal}
                >
                  <img
                    src={eye}
                    alt="Eye.svg"
                    style={{ paddingRight: "7px" }}
                  />
                  View full profile
                </TextContent>
              </ul>
            </div>
            {provided.placeholder}
          </Container>
        )}
      </Draggable>
      <ProfileModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        userData={task}
      />
    </div>
  );
}

export default Task;
