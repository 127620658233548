import React, { useEffect, useState } from 'react';
import CompanySidebar from './CompanySidebar';
import Navbar from './Navbar';

function CompanyAdminProfile() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState(null);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
 
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    const token = localStorage.getItem('token');

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/company/companyinfo`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                Authorization: token
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setFirstName(data.msg.firstName)
                setLastName(data.msg.lastName)
                setEmail(data.msg.email)
                console.log("Company Name: ", data.msg)
            })
            .catch((err) => {
                console.log("comp id check", err.message);
            });
    }, []);

    const handleUpdate = (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage("New password and confirm password do not match.");
            setMessageType("error");
            return;
        }

        const obj = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            currentPassword: currentPassword,
            newPassword: newPassword
        };

        console.log(obj);
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/company/updateCompanyadminInfo`, {
            method: 'PUT',
            headers: {
                Authorization: token,
                "content-type": "application/json",
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((data) => {
                if(data.msg === "Invalid current password"){
                    setMessage(data.msg);
                    setMessageType("error");
                }else{
                    setMessage(data.msg);
                    setMessageType("success")
                }
                console.log("admin_Info Updated:", data.msg);
            })
            .catch((err) => {
                setMessage("Error updating company info.");
                setMessageType("error");
                console.log("companyInfo Error:", err.message);
            });
    };

    return (
        <div className="MainContainer">
            <div style={{ height: "70px" }}>
                <Navbar />
            </div>

            <hr />
            <div className="container second-main">
                <div className="row">
                    <div className="col-1 col-lg-3 sidebarDiv">
                        <CompanySidebar />
                    </div>
                    <div className="col-11 col-lg-9 animate__animated animate__fadeIn">
                        <h2 className="text-center my-4">Admin Profile</h2>
                        {message && (
                            <div className={`alert ${messageType === 'success' ? 'alert-success' : 'alert-danger'}`} role="alert">
                                {message}
                            </div>
                        )}
                        <form onSubmit={handleUpdate}>
                            <div className="form-group">
                                <label htmlFor="firstName">First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lastName"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="currentPassword">Current Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="currentPassword"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="newPassword">New Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="newPassword"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="confirmPassword">Confirm Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary mt-3">Update</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="dashboard-footer text-center body-font-4 text-gray-500">
                Copyright © 2024 Neverhunt All rights reserved.
            </div>
        </div>
    );
}

export default CompanyAdminProfile;
