import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import EditPopup from './EditPopup';
import "../styles/ResPopup.css";
import { useNavigate } from "react-router-dom";

function Post() {
  const token = localStorage.getItem('token');
  const email = JSON.parse(localStorage.getItem('email'));
  const [data, setData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/activeJobs`, {
      method: 'GET',
      headers: {
        "Authorization": token
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.msg);
      });
  }, []);

  useEffect(()=>{
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/token-expire?email=${email}`, {
     method: 'GET',
     headers: {
       "Authorization": token
     }
    })
    .then((res)=> res.json())
    .then((data)=>{
      console.log("data token",data.msg);
     if(data.msg === "Invalid token. Please login"){
      //  setIsToeknValid(false)
       localStorage.removeItem("token");
       navigate(`/`);

     }else {
      //  setIsToeknValid(true)
     }
   
    })
    .catch((err)=>{
     console.log(err);
    })
 }, [token])

  //edit sucessfully, mesg
  
  const showSuccessMessage = (msg) => {
    setMessage(msg);
    setShowMessage(true);

    // Hide the message after a few seconds
    setTimeout(() => {
      setShowMessage(false);
      setMessage('');
    }, 5000); // 3000 milliseconds (3 seconds)
  };

  const handleEdit = (index) => {
    setSelectedItem(data[index]);
    const updatedData = [...data];
    updatedData[index].status = "Draft";
    setData(updatedData);
    setShowPopup(true); // Show the popup
  };

  const handleEditSubmit = (formData) => {
    const updatedData = data.map((item, i) =>
      i === selectedItem.index ? { ...item, ...formData, status: "Posted" } : item
    );
    setData(updatedData);
    setShowPopup(false); // Hide the popup after submission

    showSuccessMessage("Job updated successfully!");

    // Reload the page after a short delay (for displaying the success message)
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleEditCancel = () => {
    // Reset the status of the corresponding post back to "Posted"
    const updatedData = data.map((item, index) =>
      index === selectedItem.index ? { ...item, status: "Posted" } : item
    );
  
    setData(updatedData); // Update the data array
    setShowPopup(false); // Hide the popup when cancel is clicked
    window.location.reload();
  };
  

  const handleArchive = (jobID) => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/archive/${jobID}`, {
      method: 'POST',
      headers: {
        "content-type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((data) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.message)
      })
  };

  const handleCopyLink = (link) => {
    try {
      // Copy the link to the clipboard
      navigator.clipboard.writeText(link);
      setMessage("Link copied to clipboard!");
      setShowMessage(true);

      // Hide the message after a few seconds
      setTimeout(() => {
        setShowMessage(false);
      }, 3000); // 3000 milliseconds (3 seconds)
    } catch (err) {
      console.error("Failed to copy link: ", err);
      setMessage("Failed to copy link!");
      setShowMessage(true);

      // Hide the message after a few seconds
      setTimeout(() => {
        setShowMessage(false);
      }, 3000); // 3000 milliseconds (3 seconds)
    }
  };

  return (
    <>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ flex: "10%" }}>
          <Sidebar />
        </div>
        <div className="pageContent" style={{ flex: "75%", display: "flex", flexDirection: "column" }}>
          <Navbar />
          <div className="container">
            {/* <h1 className="text-center">Posts</h1> */}
            <h4>Total : <span>{data.length}</span></h4>
            {data.length === 0 ? (
              <p className="text-center mt-4">No data available.</p>
            ) : (
              <table className="table table-bordered table-striped text-center mt-4">
                <thead className="thead-dark">
                  <tr>
                    <th>Position</th>
                    <th>Job ID</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Edit</th>
                    <th>Archive</th>
                    <th>Job Link</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.position}</td>
                      <td>{item.uniqueID.slice(0, 4)}</td>
                      <td>{new Date(item.date).toLocaleDateString('en-GB')}</td>
                      <td>{item.status}</td>
                      <td>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleEdit(index)}
                        >
                          Edit
                        </button>
                      </td>
                      <td>
                        {item.status === 'Draft' ? (
                          <span className="archived-text">Archived</span>
                        ) : (
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleArchive(item.uniqueID)}
                          >
                            Archive
                          </button>
                        )}
                      </td>
                      <td>
                        <button
                          className="btn btn-info btn-sm"
                          onClick={() => handleCopyLink(item.jobLink)}
                        >
                          Copy Link
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      {/* Show the message at the top if showMessage is true */}
      {showMessage && (
        <div className="message">
          <p>{message}</p>
        </div>
      )}

      {showPopup && selectedItem && (
        <EditPopup
          item={selectedItem}
          onSave={handleEditSubmit}
          onCancel={handleEditCancel}
        />
      )}
    </>
  );
}

export default Post;
