import React, { useState, useEffect, useMemo } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Column from "./Column";
import { Link, useNavigate, useParams } from "react-router-dom";
import Navbar from "./Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/kanban.css";
import briefcase1 from "../Image/briefcaselogo.svg";
import EmployersProfile from "../Image/UserCircle.svg";
import ClientColumn from "./ClientColumn";

export default function ClientKanbanBoard() {
  const [completed, setCompleted] = useState({ id: "1", tasks: [] });
  const [shortlisted, setShortlisted] = useState({ id: "2", tasks: [] });
  const [hired, setHired] = useState({ id: "4", tasks: [] });
  const [interviewed, setInterviewed] = useState({ id: "3", tasks: [] });
  const [rejected, setRejected] = useState({ id: "5", tasks: [] });
  const [positionName, setPositionName] = useState("");
  const { id } = useParams();
  const [dragid, setDragid] = useState("");

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/ClientDashboard");
  };

  const position = localStorage.getItem("position");
  const token = localStorage.getItem("token");
  const email = JSON.parse(localStorage.getItem("email"));

  useEffect(()=>{
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/token-expire?email=${email}`, {
     method: 'GET',
     headers: {
       "Authorization": token
     }
    })
    .then((res)=> res.json())
    .then((data)=>{
      console.log("data token",data.msg);
     if(data.msg === "Invalid token. Please login"){
      //  setIsToeknValid(false)
       localStorage.removeItem("token");
       navigate(`/`);

     }else {
      //  setIsToeknValid(true)
     }
   
    })
    .catch((err)=>{
     console.log(err);
    })
 }, [token])

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/ClientSeeker/getapp/${id}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const arr = [{
          "Availability":"Immediate",
        }]
        console.log("checking.........", data.msg);
        setCompleted({ ...completed, tasks: data.msg });
      });
  }, [id, token]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/ClientSeeker/onejobapplications/shortlisted/${id}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setShortlisted({ ...shortlisted, tasks: data.msg });
      });
  }, [id, token]);

  //get for inteviewed
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/ClientSeeker/onejobapplications/interviewed/${id}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setInterviewed({ ...interviewed, tasks: data.msg });
      });
  }, [id, token]);

  //get for hired
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/ClientSeeker/onejobapplications/hired/${id}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setHired({ ...hired, tasks: data.msg });
      });
  }, [id, token]);

  //get for rejected
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/ClientSeeker/onejobapplications/rejected/${id}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setRejected({ ...rejected, tasks: data.msg });
      });
  }, [id, token]);

  //for getting postion name
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/Company/postionname/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setPositionName(data.msg.position);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const columnTransitions = {
    1: ["2", "3", "4", "5"],
    2: ["1", "3", "4", "5"],
    3: ["1", "2", "4", "5"],
    4: ["1", "2", "3", "5"],
    5: ["1", "2", "3", "4"],
  };

  const handleDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    setDragid(draggableId);

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const sourceColumnId = source.droppableId;
    const destinationColumnId = destination.droppableId;

    if (!columnTransitions[sourceColumnId].includes(destinationColumnId)) {
      return;
    }

    const sourceColumn = getColumnById(sourceColumnId);
    const destinationColumn = getColumnById(destinationColumnId);

    const task = sourceColumn.tasks.find((task) => task._id === draggableId);

    if (!task) return;

    const updatedSourceColumn = {
      ...sourceColumn,
      tasks: [
        ...sourceColumn.tasks.slice(0, source.index),
        ...sourceColumn.tasks.slice(source.index + 1),
      ],
    };

    const updatedDestinationColumn = {
      ...destinationColumn,
      tasks: [
        ...destinationColumn.tasks.slice(0, destination.index),
        task,
        ...destinationColumn.tasks.slice(destination.index),
      ],
    };

    updateColumn(updatedSourceColumn);
    updateColumn(updatedDestinationColumn);
    console.log("url updated", `${process.env.REACT_APP_BACKEND_BASE_URL}/ClientSeeker/onejobapplications/${destinationColumnId}/${draggableId}`) 

    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/ClientSeeker/onejobapplications/${destinationColumnId}/${draggableId}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          candidateStatus: destinationColumn.title,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(
          `Item updated to '${destinationColumn.title}' in the database`,
          data.msg
        );
      })
      .catch((error) => {
        console.error("Error updating item status in the database:", error);
      });
  };

  const getColumnById = (columnId) => {
    switch (columnId) {
      case "1":
        return completed;
      case "2":
        return shortlisted;
      case "3":
        return interviewed;
      case "4":
        return hired;
      case "5":
        return rejected;
      default:
        return null;
    }
  };

  const updateColumn = (updatedColumn) => {
    switch (updatedColumn.id) {
      case "1":
        setCompleted(updatedColumn);
        break;
      case "2":
        setShortlisted(updatedColumn);
        break;
      case "3":
        setInterviewed(updatedColumn);
        break;
      case "4":
        setHired(updatedColumn);
        break;
      case "5":
        setRejected(updatedColumn);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="dashboard-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-xl-12 ">
              <div className="dashboard-right">
                <header
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "20px 150px 0px 150px",
                  }}
                >
                  <div className="">
                    <a className="navbar-brand logo" href="#">
                      <img
                        src={briefcase1}
                        alt="Logo"
                        width="40"
                        height="40"
                        className="d-inline-block align-text-top mr-2"
                      />
                      <span className="logo-name">Neverhunt</span>
                    </a>
                  </div>
                  <div
                    className=" d-flex justify-content-between align-items-center"
                    style={{ width: "220px" }}
                  >
                    <a className="navbar-brand" href="#">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.26904 10.5002C5.26657 9.61461 5.43885 8.73727 5.77603 7.91841C6.1132 7.09956 6.60864 6.35528 7.23394 5.72822C7.85925 5.10116 8.60214 4.60365 9.42006 4.26419C10.238 3.92474 11.1148 3.75 12.0004 3.75C12.8859 3.75 13.7628 3.92474 14.5807 4.26419C15.3986 4.60365 16.1415 5.10116 16.7668 5.72822C17.3921 6.35528 17.8876 7.09956 18.2247 7.91841C18.5619 8.73727 18.7342 9.61461 18.7317 10.5002V10.5002C18.7317 13.8579 19.4342 15.8063 20.0529 16.8712C20.1196 16.985 20.1551 17.1144 20.1558 17.2462C20.1565 17.3781 20.1224 17.5078 20.0569 17.6223C19.9915 17.7368 19.8971 17.832 19.7831 17.8984C19.6691 17.9647 19.5397 17.9998 19.4078 18.0002H4.59222C4.46034 17.9998 4.33087 17.9647 4.21689 17.8984C4.1029 17.832 4.00844 17.7368 3.94301 17.6223C3.87759 17.5077 3.84352 17.378 3.84425 17.2461C3.84498 17.1142 3.88048 16.9849 3.94716 16.8711C4.56622 15.8061 5.26904 13.8577 5.26904 10.5002H5.26904Z"
                          stroke="#18191C"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M9 18V18.75C9 19.5456 9.31607 20.3087 9.87868 20.8713C10.4413 21.4339 11.2044 21.75 12 21.75C12.7956 21.75 13.5587 21.4339 14.1213 20.8713C14.6839 20.3087 15 19.5456 15 18.75V18"
                          stroke="#18191C"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M17.1968 2.24902C18.7229 3.21245 19.9531 4.57885 20.7516 6.19736"
                          stroke="#18191C"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M3.24829 6.19736C4.04681 4.57885 5.27703 3.21245 6.80315 2.24902"
                          stroke="#18191C"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </a>
                    <a className="navbar-brand" href="#">
                      <img
                        src={EmployersProfile}
                        alt="Instagram"
                        width="30"
                        height="30"
                        className="d-inline-block align-text-top instagram"
                      />
                    </a>
                    <a href="/ClientJobPost">
                      <button
                        className="postjobbtn"
                        // type="button"
                      >
                        Post Job
                      </button>
                    </a>
                  </div>
                </header>
                <hr />
                <div
                  className="custom-breadcrumb"
                  style={{ padding: "0px 150px" }}
                >
                  <p>
                    <span class="inactive">Home</span>
                    <span>/</span>
                    <span class="inactive">My Jobs</span>
                    <span>/</span>
                    <span class="inactive">{positionName}</span>
                    <span>/</span>
                    <span class="iactive">Applications</span>
                  </p>
                </div>
                <div
                  className="application-wrapper"
                  style={{ padding: "0px 150px" }}
                >
                  <div className="application-wrapper-top">
                    <h2 class="title">Applications</h2>
                    <button class="btn btn-primary back-buttn" onClick={handleNavigate}>back</button>
                  </div>
                  <div id="app">
                    <div>
                      <div className="mt-5 application-wrapper-bottom position-relative">
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Droppable droppableId="1">
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  <ClientColumn
                                    title={"All Applications"}
                                    tasks={completed.tasks}
                                    id={"1"}
                                    dragid={dragid}
                                  />
                                </div>
                              )}
                            </Droppable>
                            <Droppable droppableId="2">
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  <ClientColumn
                                    title={"Shortlisted"}
                                    tasks={shortlisted.tasks}
                                    id={"2"}
                                    dragid={dragid}
                                  />
                                </div>
                              )}
                            </Droppable>
                            <Droppable droppableId="3">
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  <ClientColumn
                                    title={"Interview"}
                                    tasks={interviewed.tasks}
                                    id={"3"}
                                    dragid={dragid}
                                  />
                                </div>
                              )}
                            </Droppable>
                            <Droppable droppableId="4">
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  <ClientColumn
                                    title={"Hired"}
                                    tasks={hired.tasks}
                                    id={"4"}
                                    dragid={dragid}
                                  />
                                </div>
                              )}
                            </Droppable>
                            <Droppable droppableId="5">
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  <ClientColumn
                                    title={"Rejected"}
                                    tasks={rejected.tasks}
                                    id={"5"}
                                  />
                                </div>
                              )}
                            </Droppable>
                          </div>
                        </DragDropContext>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
