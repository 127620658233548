import React, { useState } from 'react';
import JSZip from 'jszip';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import "../styles/Resumepool.css";

function Resumepool() {
  const [fileDetails, setFileDetails] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentFile, setCurrentFile] = useState('');
  const [resumeParser, setResumeParser] = useState({});

  const token = localStorage.getItem("token");

  const handleFileUpload = async (event) => {
    setIsLoading(true);
    const files = event.target.files;
    const fileArray = Array.from(files);
    let details = [];
    let totalResumes = 0;

    try {
      // First, upload all files to the backend
      await sendFileDetailsToBackend(fileArray);

      for (const file of fileArray) {
        if (file.name.endsWith('.zip')) {
          try {
            const zip = new JSZip();
            const content = await zip.loadAsync(file);
            const zipFiles = Object.keys(content.files);

            for (const filename of zipFiles) {
              if (/\.(pdf|doc|docx)$/i.test(filename)) {
                const sanitizedFilename = filename.replace(/^icedrive-dl-1sarg\//, '');
                setCurrentFile(sanitizedFilename);
                const fileData = await content.files[filename].async('blob');
                const resumeFile = new File([fileData], sanitizedFilename, { type: fileData.type });
                await processSingleResume(resumeFile);
                totalResumes++;
                details.push({ name: sanitizedFilename, count: 1 });
              }
            }
          } catch (error) {
            console.error('Error reading ZIP file:', error);
          }
        } else if (/\.(pdf|doc|docx)$/i.test(file.name)) {
          const sanitizedFilename = file.name.replace(/^icedrive-dl-1sarg\//, '');
          setCurrentFile(sanitizedFilename); 
          const sanitizedFile = new File([file], sanitizedFilename, { type: file.type });
          await processSingleResume(sanitizedFile);
          totalResumes++;
          details.push({ name: sanitizedFilename, count: 1 });
        } else {
          details.push({ name: file.name, count: 0 });
        }
      }

      setFileDetails(details);
      setSuccessMessage(`${totalResumes} resumes have been successfully processed`);
    } catch (error) {
      console.error('Error uploading or processing files:', error);
    } finally {
      setIsLoading(false);
      setCurrentFile(''); 
    }
  };

  const processSingleResume = async (file) => {
    try {
      const formdata = new FormData();
      formdata.append("userid", "someUserId");
      formdata.append("resume", file);

      const response = await fetch(`${process.env.REACT_APP_PYTHON_BASE_URL}/process_resume`, {
        method: "POST",
        body: formdata,
      });
      //f
      
      const data = await response.json();
      if(data){
        console.log("response data: ", data);
        const forndata = new FormData()
        forndata.append("parsed_resume", data.parsed_resume)
        forndata.append("filename", data.filename);
         fetch(`${process.env.REACT_APP_PYTHON_BASE_URL}/resumes_attribute_matcher`, {
           method: "POST",
           body: forndata
          })
          .then((res)=>res.json())
          .then((data)=>{
            console.log("python response", data);
          })
      }
      await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/parsedResume/add`, {
        method: "POST",
        headers:{
          "content-type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify({
          parsedResume: data.parsed_resume,
          filename: data.filename
        })
      });

      await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/token/addToken`, {
        method: "POST",
        headers:{
          "content-type": "application/json",
        },
        body: JSON.stringify({
          inputToken: data.input_token,
          outputToken: data.output_token,
        })
      });

      setResumeParser(prevState => ({ ...prevState, [file.name]: data.answer }));
    } catch (err) {
      console.error("Error processing resume:", err.message);
    }
  };

  const sendFileDetailsToBackend = async (files) => {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('resumes', file);
    });
    formData.append('jobid', 'someJobId'); 
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/uploadResumes`, {
        method: 'POST',
        headers:{
          "Authorization": token,
        },
        body: formData
      });
      if (!response.ok) {
        throw new Error('Failed to upload file details');
      }
      console.log('File details uploaded successfully');
    } catch (error) {
      console.error('Error uploading file details:', error);
    }
  };

  return (
    <div className="MainContainer">
      <div style={{ height: '70px' }}>
        <Navbar />
      </div>
      <hr />
      <div className="container second-main">
        <div className="row">
          <div className="col-1 col-lg-3 sidebarDiv">
            <Sidebar />
          </div>
          <div className="col-11 col-lg-9">
            <input
              type="file"
              accept=".pdf,.doc,.docx,.zip"
              multiple
              onChange={handleFileUpload}
              disabled={isLoading}
            />
            <div>
              <h4>Uploaded Files:</h4>
              <ul>
                {fileDetails.map((detail, index) => (
                  <li key={index}>
                    <span>{detail.name}</span>
                    <span>{detail.count} resumes</span>
                  </li>
                ))}
              </ul>
              {currentFile && (
                <div className="alert alert-info" role="alert">
                  Processing {currentFile}, please wait...
                </div>
              )}
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              {isLoading && !currentFile && (
                <div className="alert alert-info" role="alert">
                  Processing files, please wait...
                </div>
              )}
            </div>
            {/* <div>
              <button onClick={handleMatches}>Get Matches</button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
}

export default Resumepool;
