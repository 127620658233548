import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/myjob.css";

function Myjobs() {
  const [activeJobs, setActiveJobs] = useState(0);
  const [Jobs, setJobs] = useState([]);
  const [sortOption, setSortOption] = useState("");
  const [jobsWithNoResponse, setJobsWithNoResponse] = useState(0);
  const [unreadResponses, setUnreadResponses] = useState(0);
  const [openOverlays, setOpenOverlays] = useState([]); 
  const [singleJobUnread, setSingleJobUnread] = useState([]);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null); 
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 10;
  const navigate = useNavigate();
  const startIndex = (currentPage - 1) * jobsPerPage;
  const endIndex = startIndex + jobsPerPage;

  // Function to toggle active dropdown index
  const toggleDropdown = (rowIndex) => {
    setActiveDropdownIndex(activeDropdownIndex === rowIndex ? null : rowIndex);
  };

  const token = localStorage.getItem("token");
  const email = JSON.parse(localStorage.getItem("email"));

  useEffect(() => {
    if (!token) {
      navigate(`/`);
    }
  }, []);

  useEffect(()=>{
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/token-expire?email=${email}`, {
     method: 'GET',
     headers: {
       "Authorization": token
     }
    })
    .then((res)=> res.json())
    .then((data)=>{
      console.log("data token",data.msg);
     if(data.msg === "Invalid token. Please login"){
      //  setIsToeknValid(false)
       localStorage.removeItem("token");
       navigate(`/`);

     }else {
      //  setIsToeknValid(true)
     }
   
    })
    .catch((err)=>{
     console.log(err);
    })
 }, [token])

  const handleSortChange = (e) => {
    const selectedOption = e.target.value;
    setSortOption(selectedOption);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/activeJobs?sortValue=${sortOption}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
      setJobs(data.msg)
      if(sortOption==="asc"){
        setSingleJobUnread(singleJobUnread.sort((a,b) => a-b)) 
      }else if (sortOption==="des"){
        setSingleJobUnread(singleJobUnread.sort((a,b) => b-a)) 
      }else{
        setSingleJobUnread(singleJobUnread.sort((a,b) => b-a)) 
      }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [sortOption]);
   

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/activeJobs?page=${currentPage}&perPage=${jobsPerPage}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setActiveJobs(data.msg.length);
        setJobs(data.msg);
        setSingleJobUnread(data.unread);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [currentPage]);

  const totalPages = Math.ceil(activeJobs / jobsPerPage);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Function to handle next page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/jobsWithNoResponse`, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setJobsWithNoResponse(data.msg.length);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/unreadResponses`, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnreadResponses(data.msg);
      })
      .catch((err) => {
       console.log(err.message);
      });
  }, []);

  return (
    <div className="MainContainer">
      <div style={{ height: "60px" }}>
        <Navbar />
      </div>
      <hr />
      <div className="container second-main">
        <div className="row">
          <div className="col-1 col-lg-3 sidebarDiv">
            <Sidebar />
          </div>

          <div className="col-lg-9">
            <div className="dashboard-right">
              <div className="recently-applied-wrap d-flex justify-content-between align-items-center rt-mb-15">
                <h3 className="f-size-16 ft-wt-5 text-gray-900">
                  My Jobs <span className="jobsCount">({activeJobs})</span>{" "}
                </h3>
                <div className="header-dropdown">
                  {/* <h4 className="f-size-14 text-gray-900 m-0">Job Status</h4> */}
                  <select
                    name="status"
                    className="rt-selectactive select2-hidden-accessible"
                    tabIndex="-1"
                    aria-hidden="true"
                    value={sortOption}
                    onChange={handleSortChange}
                  >
                    {/* <option value="">All</option> */}
                    <option value="asc">Newest First</option>
                    <option value="des">Oldest First</option>
                    
                  </select>
                 
                </div>
              </div>
              <div className="db-job-card-table">
                <table>
                  <thead>
                    <tr>
                      <th>Job</th>
                      <th>Status</th>
                      <th>Unread Responses</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Jobs.slice(startIndex, endIndex).map((el, rowIndex) => (
                      <tr key={el.RuserID}>
                        <td>
                          <div class="iconbox-content">
                            <div class="post-info2">
                              <div class="post-main-title">
                                <a
                                  href="#"
                                  class="text-gray-900 f-size-16  ft-wt-5"
                                >
                                  {el.position}
                                </a>
                              </div>
                              <div class="body-font-4 text-gray-600 pt-2">
                                <span class="info-tools rt-mr-8">
                                  {el.positionType[0]}
                                </span>
                                <span class="info-tools">{el.city}, {el.state}</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="text-success-500 ft-wt-5 d-flex align-items-center">
                            <i class="ph-check-circle f-size-18 mt-1 rt-mr-4">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="CheckCircle">
                                  <path
                                    id="Vector"
                                    d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                                    stroke="#0BA02C"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    id="Vector_2"
                                    d="M13.4375 8.125L8.85414 12.5L6.5625 10.3125"
                                    stroke="#0BA02C"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                              </svg>
                            </i>
                            Active
                          </div>
                        </td>
                        <td>
                          <div class="d-flex align-items-center mt-3">
                            <i class="ph-users f-size-20 rt-mr-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M8.25006 15C10.9424 15 13.1251 12.8174 13.1251 10.125C13.1251 7.43261 10.9424 5.25 8.25006 5.25C5.55767 5.25 3.37506 7.43261 3.37506 10.125C3.37506 12.8174 5.55767 15 8.25006 15Z"
                                  stroke="#5E6670"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                />
                                <path
                                  d="M14.5699 5.4316C15.2405 5.24268 15.9437 5.19964 16.6322 5.30538C17.3208 5.41113 17.9787 5.66319 18.5616 6.04461C19.1445 6.42602 19.639 6.92793 20.0116 7.51652C20.3842 8.10511 20.6264 8.76672 20.7218 9.45678C20.8172 10.1468 20.7636 10.8493 20.5646 11.5169C20.3657 12.1845 20.026 12.8018 19.5684 13.327C19.1108 13.8523 18.5459 14.2734 17.9119 14.562C17.2779 14.8506 16.5893 14.9999 15.8927 15"
                                  stroke="#5E6670"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1.49963 18.5059C2.26101 17.4229 3.27178 16.539 4.44662 15.9288C5.62145 15.3186 6.92586 15.0001 8.24971 15C9.57356 14.9999 10.878 15.3184 12.0529 15.9285C13.2278 16.5386 14.2386 17.4225 15.0001 18.5054"
                                  stroke="#5E6670"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M15.8927 15C17.2167 14.999 18.5214 15.3171 19.6964 15.9273C20.8713 16.5375 21.882 17.4218 22.6427 18.5054"
                                  stroke="#5E6670"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </i>
                            <span>{singleJobUnread[rowIndex]}</span>
                          </div>
                        </td>
                        <td>
                          <div class="db-job-btn-wrap d-flex justify-content-center">
                            <a
                              href={`/kanban/${el.uniqueID}`}
                              className="btn view-button bg-gray-50 text-primary-500 rt-mr-8"
                            >
                              <span class="button-text">View Applications</span>
                            </a>
                            <button
                              type="button"
                              class="btn btn-icon"
                              id="dropdownMenuButton5"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 13.125C12.6213 13.125 13.125 12.6213 13.125 12C13.125 11.3787 12.6213 10.875 12 10.875C11.3787 10.875 10.875 11.3787 10.875 12C10.875 12.6213 11.3787 13.125 12 13.125Z"
                                  fill="#767F8C"
                                  stroke="#767F8C"
                                ></path>
                                <path
                                  d="M12 6.65039C12.6213 6.65039 13.125 6.14671 13.125 5.52539C13.125 4.90407 12.6213 4.40039 12 4.40039C11.3787 4.40039 10.875 4.90407 10.875 5.52539C10.875 6.14671 11.3787 6.65039 12 6.65039Z"
                                  fill="#767F8C"
                                  stroke="#767F8C"
                                ></path>
                                <path
                                  d="M12 19.6094C12.6213 19.6094 13.125 19.1057 13.125 18.4844C13.125 17.8631 12.6213 17.3594 12 17.3594C11.3787 17.3594 10.875 17.8631 10.875 18.4844C10.875 19.1057 11.3787 19.6094 12 19.6094Z"
                                  fill="#767F8C"
                                  stroke="#767F8C"
                                ></path>
                              </svg>
                            </button>
                            <ul
                              className="dropdown-menu dropdown-menu-end company-dashboard-dropdown"
                              aria-labelledby="dropdownMenuButton5"
                            >
                              <li>
                                <a href={`/editJobPost/${el.uniqueID}`} class="dropdown-item">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g
                                      id="fi:edit"
                                      clip-path="url(#clip0_26_477)"
                                    >
                                      <path
                                        id="Vector"
                                        d="M9.16669 3.33334H3.33335C2.89133 3.33334 2.4674 3.50894 2.15484 3.8215C1.84228 4.13406 1.66669 4.55798 1.66669 5.00001V16.6667C1.66669 17.1087 1.84228 17.5326 2.15484 17.8452C2.4674 18.1577 2.89133 18.3333 3.33335 18.3333H15C15.442 18.3333 15.866 18.1577 16.1785 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6667V10.8333"
                                        stroke="#191F33"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        id="Vector_2"
                                        d="M15.4166 2.08332C15.7481 1.7518 16.1978 1.56555 16.6666 1.56555C17.1355 1.56555 17.5851 1.7518 17.9166 2.08332C18.2481 2.41484 18.4344 2.86448 18.4344 3.33332C18.4344 3.80216 18.2481 4.2518 17.9166 4.58332L9.99996 12.5L6.66663 13.3333L7.49996 9.99999L15.4166 2.08332Z"
                                        stroke="#0A65CC"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_26_477">
                                        <rect
                                          width="20"
                                          height="20"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  Edit Post
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g id="Archive">
                                      <path
                                        id="Vector"
                                        d="M17.5 4.375H2.5C2.15482 4.375 1.875 4.65482 1.875 5V6.875C1.875 7.22018 2.15482 7.5 2.5 7.5H17.5C17.8452 7.5 18.125 7.22018 18.125 6.875V5C18.125 4.65482 17.8452 4.375 17.5 4.375Z"
                                        stroke="#5E6670"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        id="Vector_2"
                                        d="M16.875 7.5V15C16.875 15.1658 16.8092 15.3247 16.6919 15.4419C16.5747 15.5592 16.4158 15.625 16.25 15.625H3.75C3.58424 15.625 3.42527 15.5592 3.30806 15.4419C3.19085 15.3247 3.125 15.1658 3.125 15V7.5"
                                        stroke="#5E6670"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        id="Vector_3"
                                        d="M8.125 10.625H11.875"
                                        stroke="#18191C"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </g>
                                  </svg>
                                  Archive
                                </a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="rt-pt-30">
                <div className="pagination ">
                  <div
                    onMouseEnter={(e) => {
                      const paths = e.target.querySelectorAll("path");
                      paths.forEach((path) => {
                        path.setAttribute("stroke", "#0A65CC");
                      });
                    }}
                    onMouseLeave={(e) => {
                      const paths = e.target.querySelectorAll("path");
                      paths.forEach((path) => {
                        path.setAttribute("stroke", "#99C2FF");
                      });
                    }}
                    onClick={handlePreviousPage}
                    className="hover-effect"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M19 12H5"
                        stroke="#99C2FF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 5L5 12L12 19"
                        stroke="#99C2FF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <div className="pageButtons">
                    {Array.from({ length: totalPages }, (_, index) => (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={currentPage === index + 1 ? "active" : ""}
                      >
                        {index + 1}
                      </button>
                    ))}
                  </div>
                  <div
                    onMouseEnter={(e) => {
                      const paths = e.target.querySelectorAll("path");
                      paths.forEach((path) => {
                        path.setAttribute("stroke", "#0A65CC");
                      });
                    }}
                    onMouseLeave={(e) => {
                      const paths = e.target.querySelectorAll("path");
                      paths.forEach((path) => {
                        path.setAttribute("stroke", "#84afef");
                      });
                    }}
                    className="hover-effect"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      onClick={handleNextPage}
                    >
                      <path
                        d="M5 12H19"
                        stroke="#84afef"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 5L19 12L12 19"
                        stroke="#84afef"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dashboard-footer text-center body-font-4 text-gray-500">
      Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
}

export default Myjobs;