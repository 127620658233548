import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./jobPost.css";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import JobThankyou from "./JobThankyou";
import ClientSidebar from "../components/ClientSidebar";
import ClientNavbar from "../components/ClientNavbar";

const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

const usStates = [
  "",
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawali",
  "Idaho",
  "Illinois",
  "Indiana",
  "lowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermout",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const ClientEditJobPost = () => {
  const [position, setPosition] = useState("");
  const [salary, setSalary] = useState("");
  const [description, setDescription] = useState("");
  const [positionType, setPositionType] = useState(["W2"]);
  const [jobType, setJobType] = useState(["Onsite"]);
  const [questions, setQuestions] = useState([]);
  const [jobLocType, setjobLocType] = useState(["Fulltime"]);
  const [benefits, setBenefits] = useState("Available");
  const [status, setStatus] = useState("Draft");
  const [message, setMessage] = useState("");
  const [additionalRequirements, setAdditionalRequirements] = useState("");
  const [months, setMonths] = useState("")
  const [companyName, setCompanyName] = useState("");
  const [editableGeneratedQuestions, setEditableGeneratedQuestions] = useState(
    []
  );
  const [isDescriptionEmpty, setIsDescriptionEmpty] = useState(true);
  const [userQuestions, setUserQuestions] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showAddQuestionInput, setShowAddQuestionInput] = useState(false);
  const [underId, setUnderId] = useState("");
  const [location, setLocation] = useState({
    city: "",
    state: usStates[0],
    zipCode: "",
  });

  const [isAddingNewQuestion, setIsAddingNewQuestion] = useState(false);
  const [newQuestion, setNewQuestion] = useState("");
  const [newQuestionSave, setNewQuestionSave] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [questionOptions, setQuestionOptions] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [initialFocus, setInitialFocus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uniqueID, setUniqueID] = useState("");
  const email = JSON.parse(localStorage.getItem("email"));
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  useEffect(()=>{
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/token-expire?email=${email}`, {
     method: 'GET',
     headers: {
       "Authorization": token
     }
    })
    .then((res)=> res.json())
    .then((data)=>{
      console.log("data token",data.msg);
     if(data.msg === "Invalid token. Please login"){
      //  setIsToeknValid(false)
       localStorage.removeItem("token");
       navigate(`/`);

     }else {
      //  setIsToeknValid(true)
     }
   
    })
    .catch((err)=>{
     console.log(err);
    })
 }, [token])

  const { id } = useParams();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/ClientDashboard")
  };

  const quillStyles = {
    // border: "1px solid #ced4da",
    borderRadius: "5px",
    // paddingBottom:"-150px",
    height: "300px", // Set a static height for testing
    // overflowY: "scroll"
  };
  const reactquillStyles = {
    // border: "1px solid #ced4da",
    borderRadius: "5px",
    height: "280px", 
    // overflowY: "scroll"
  };

  //for getting particular job post
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/Company/getJobPost/${id}`, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnderId(data.msg._id);
        setPosition(data.msg.position);
        setCompanyName(data.msg.companyName)
        setLocation(data.msg.city);
        setLocation({ ...location, city: data.msg.state });
        setLocation({ ...location, city: data.msg.zip });
        setBenefits(data.msg.benefits);
        setJobType(data.msg.jobType);
        setSalary(data.msg.salary);
        setMonths(data.msg.months);
        setAdditionalRequirements(data.msg.additionalRequirements);
        setDescription(data.msg.jobDescription);
        setLocation({
          city: data.msg.city,
          state: data.msg.state,
          zipCode: data.msg.zip,
        });
        setUniqueID(data.msg.uniqueID)
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleInputFocus = () => {
    setIsDropdownOpen(true);
    if (!initialFocus) {
      // console.log("focus")
      setInitialFocus(true);
    }
  };

  const handleInputBlur = () => {
    // setIsDropdownOpen(false);
  };

  const handleDropdownClick = () => {
    setIsDropdownOpen(true);
  };

  const toggleAddingNewQuestion = (e) => {
    e.preventDefault();
    setIsAddingNewQuestion(!isAddingNewQuestion);
  };

  const addQuestion = () => {
    if (newQuestion.trim() === "") {
      return;
    }
    const newQuestionObject = {
      title: newQuestion,
      required: isRequired,
    };
    if (newQuestionSave === true) {
      const obj = {
        saveQuestion: newQuestion,
      };
      fetch( `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/saveQuestion`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(obj),
      })
        .then((res) => res.json())
        .then((data) => {
          toast.success("Question saved successfully!", {
            position: "top-right",
            autoClose: 3000, // 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          console.log(err.message);
        });

      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getQuestions`, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setShowSearchBar(!showSearchBar);
          setUserQuestions(data.msg);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }

    setQuestions([...questions, newQuestionObject]);
    setNewQuestion("");
    setIsRequired(false);
    // setIsAddingNewQuestion(false);
  };

  const removeQuestion = (questionId) => {};

 

  
  const audioFileRef = useRef(null);

  const handleJobLocTypeChange = (e) => {
    const selectedjobLocType = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setjobLocType([...jobLocType, selectedjobLocType]);
    } else {
      setjobLocType(jobLocType.filter((type) => type !== selectedjobLocType));
    }
  };

  const handlePositionChange = (e) => {
    setPosition(e.target.value);
  };

  const handleSalaryChange = (e) => {
    setSalary(e.target.value);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const handleAdditionalChange = (value)=>{
    setAdditionalRequirements(value);
  }

  const handleMonthChange = (e)=>{
    setMonths(e.target.value);
  }

  const handleCompanyChange = (e)=>{
    setCompanyName(e.target.value)
    }

  const handleSearchInputChange = (e) => {
    const newSearchKeyword = e.target.value;
    setSearchKeyword(newSearchKeyword);

    // Update search results instantly based on the new keyword
    const filteredResults = userQuestions.filter((question) =>
      question.toLowerCase().includes(newSearchKeyword.toLowerCase())
    );
    setSearchResults(filteredResults);

    // Toggle visibility of search results
    setShowSearchResults(newSearchKeyword !== "");
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    const filteredResults = userQuestions.filter((question) =>
      question.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    setSearchResults(filteredResults);
  };

  const handlePositionTypeChange = (e) => {
    const selectedPositionType = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setPositionType([...positionType, selectedPositionType]);
    } else {
      setPositionType(
        positionType.filter((type) => type !== selectedPositionType)
      );
    }
  };

  const handleJobTypeChange = (e) => {
    const selectedJobType = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setJobType([...jobType, selectedJobType]);
    } else {
      setJobType(jobType.filter((type) => type !== selectedJobType));
    }
  };

  const handleQuestionChange = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = e.target.value;
    setQuestions(updatedQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, ""]);
    setShowAddQuestionInput(true);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);

    // if (index >= questions.length) {
    //   const generatedIndex = index - questions.length;
    //   const updatedGeneratedQuestions = [...generatedQuestions];
    //   updatedGeneratedQuestions.splice(generatedIndex, 1);
    //   setGeneratedQuestions(updatedGeneratedQuestions);
    // }
  };

  const handleBenefitsChange = (e) => {
    setBenefits(e.target.value);
  };

  //gen button diasable
  useEffect(() => {
    if (description.trim() !== "") {
      setIsDescriptionEmpty(false);
    }

    if (description.trim() === "") {
      setIsDescriptionEmpty(true);
      // window.location.reload();
    }
    // setIsDescriptionEmpty(!description.trim());
  }, [description]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let obj = {
      position: position,
      jobDescription: description,
      positionType: positionType,
      salary: salary,
      months:months,
      additionalRequirements: additionalRequirements,
      jobType: jobType,
      city: location.city,
      state: location.state,
      zip: location.zipCode,
      status: "Posted",
    };

    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/Company/updateJobPost/${underId}`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        setMessage(data.msg)
        // setIsModalOpen(true);
        setStatus("Posted");
        const uniqueID = data.uniqueID;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddSearchedQuestion = (question) => {
    setInitialFocus(false);
    const newQuestionObject = {
      title: question,
      required: isRequired,
    };

    setQuestions([...questions, newQuestionObject]);
    setSearchKeyword(""); // Clear the search keyword
    setShowSearchResults(false); // Hide the search results
    setIsDropdownOpen(false);
  };
  //for getting questions
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getQuestions`, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setShowSearchBar(!showSearchBar);
        setUserQuestions(data.msg);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const AdquillStyles = {
    // border: "1px solid green",
    borderRadius: "5px",
    height: "150px", // Set a static height for testing
    marginBottom:"70px"
  };
  const AdreactquillStyles = {
    // border: 1px solid red;
    // border: "1px solid red",
    borderRadius: "5px",
    height: "255px", 
    // overflowY: "scroll"
  };

  
  return (
    <div className="MainContainer">
      <div style={{ height: "70px" }}>
        <ClientNavbar />
      </div>

      <hr />
      <div className="container second-main">
        <div className="row">
          <div className="col-1 col-lg-3 sidebarDiv">
            <ClientSidebar />
          </div>
          <div className="col-lg-9">
            <div className="container w-100">
              {message && (
                <div className="mt-3 alert alert-info text-center">
                  {message}
                </div>
              )}
              <form className="text-left">
                <center
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h2 class="tw-text-2xl tw-font-medium tw-text-[#18191C] tw-mb-8.5">
                    Edit Job Post
                  </h2>
                  <p className="status">Status: {status}</p>
                </center>

                <div className="form-group text-start label-txt">
                  <label htmlFor="position">
                    Job Title{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control placeholder-gray`}
                    id="position"
                    value={position}
                    onChange={handlePositionChange}
                    placeholder="Add job tittle, role, vacancies etc"
                    required
                  />
                </div>

                <div className="form-group text-start label-txt">
                  <label>
                    Location{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="city">City</label>
                      <input
                        type="text"
                        className={`form-control placeholder-gray`}
                        id="city"
                        value={location.city}
                        onChange={(e) =>
                          setLocation({ ...location, city: e.target.value })
                        }
                        placeholder="City"
                        required
                      />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="state">State</label>
                      <select
                        className={`form-control placeholder-gray`}
                        id="state"
                        value={location.state}
                        onChange={(e) =>
                          setLocation({ ...location, state: e.target.value })
                        }
                        required
                      >
                        {usStates.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="zipCode">Zip</label>
                      <input
                        type="text"
                        className={`form-control placeholder-gray`}
                        id="zipCode"
                        value={location.zipCode}
                        onChange={(e) =>
                          setLocation({ ...location, zipCode: e.target.value })
                        }
                        placeholder="Zip Code"
                        required
                      />
                    </div>
                  </div>
                </div>
                
                <div className="form-group text-start label-txt ">
                  <label>
                    Job Type{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="d-flex flex-wrap justify-content-start ">
                    <div className="form-check gap-2">
                      <input
                        type="checkbox"
                        className="form-check-input checkbox-input"
                        id="jobTypeFulltime"
                        value="Fulltime"
                        checked={jobLocType.includes("Fulltime")}
                        onChange={handleJobLocTypeChange}
                        required
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeFulltime"
                      >
                        Full time
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="checkbox"
                        className="form-check-input checkbox-input"
                        id="jobTypeContract"
                        value="Contract"
                        checked={jobLocType.includes("Contract")}
                        onChange={handleJobLocTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeContract"
                      >
                        Contract
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="checkbox"
                        className="form-check-input checkbox-input"
                        id="jobTypeInternship"
                        value="Contract to hire"
                        checked={jobLocType.includes("Contract to hire")}
                        onChange={handleJobLocTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeInternship"
                      >
                        Contract to hire
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="checkbox"
                        className="form-check-input checkbox-input"
                        id="jobTypeInternship"
                        value="Internship"
                        checked={jobLocType.includes("Internship")}
                        onChange={handleJobLocTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeInternship"
                      >
                        Internship
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt ">
                  <label>
                    Job Location Type{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="d-flex flex-wrap justify-content-start ">
                    <div className="form-check gap-2">
                      <input
                        type="checkbox"
                        className="form-check-input checkbox-input"
                        id="jobTypeOnsite"
                        value="Onsite"
                        checked={jobType.includes("Onsite")}
                        onChange={handleJobTypeChange}
                        required
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeOnsite"
                      >
                        Onsite
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="checkbox"
                        className="form-check-input checkbox-input"
                        id="jobTypeRemote"
                        value="Remote"
                        checked={jobType.includes("Remote")}
                        onChange={handleJobTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeRemote"
                      >
                        Remote
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="checkbox"
                        className="form-check-input checkbox-input"
                        id="jobTypeHybrid"
                        value="Hybrid"
                        checked={jobType.includes("Hybrid")}
                        onChange={handleJobTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeHybrid"
                      >
                        Hybrid
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt">
                  <label htmlFor="position">
                    Duration in months{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control placeholder-gray`}
                    id="salary"
                    value={months}
                    onChange={handleMonthChange}
                    placeholder="6 months"
                    required
                  />
                </div>

                <div className="form-group text-start label-txt">
                  <label htmlFor="position">
                    Company Name{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control placeholder-gray `}
                    id="salary"
                    value={companyName}
                    onChange={handleCompanyChange}
                    placeholder="Company name"
                    required
                  />
                </div>

                <div className="form-group text-start label-txt">
                  <label htmlFor="position">
                    Compensation{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control placeholder-gray`}
                    id="salary"
                    value={salary}
                    onChange={handleSalaryChange}
                    placeholder="75/hr"
                    required
                  />
                </div>
                <div className="form-group text-start label-txt mb-4 ">
                  <label htmlFor="description">
                    Job Description
                    <span className="form-label-required text-danger">*</span>
                  </label>
                  <div  className={``}  style={quillStyles} >
                    <ReactQuill
                      theme="snow"
                      id="description"
                      value={description}
                      onChange={handleDescriptionChange}
                      style={reactquillStyles} 
                    />
                  </div>
                </div>

                <div className="form-group text-start label-txt mb-5 ">
                  <label htmlFor="description">
                    Additional Requirements
                    <span className="form-label-required text-danger">*</span>
                  </label>
                  <div  className={``}  style={AdquillStyles} >
                    <ReactQuill
                      theme="snow"
                      id="description"
                      value={additionalRequirements}
                      onChange={handleAdditionalChange}
                      style={AdquillStyles} 
                    />
                  </div>
               
                </div>

                <div class="post-job-item rt-mb-15 tw-w-full tw-overflow-hidden">
                  <button
                    type="submit"
                    class="btn btn-primary rt-mr-10"
                    onClick={handleFormSubmit}
                  >
                    <span class="button-content-wrapper ">
                      <span class="button-icon align-icon-right">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M5 12H19"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 5L19 12L12 19"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      <span class="button-text">Post Job</span>
                    </span>
                  </button>
                </div>
              </form>
            </div>
            <JobThankyou
              isOpen={isModalOpen}
              closeModal={closeModal}
              uniqueID={uniqueID}
            />
            <ToastContainer />
          </div>
        </div>
      </div>
      <div class="dashboard-footer text-center body-font-4 text-gray-500">
      Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
};

export default ClientEditJobPost;
