import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import "react-circular-progressbar/dist/styles.css";
import AdminSidebar from "./AdminSidebar";
import "../styles/AdminStatus.css"; 

function AdminStatus() {
  const [recruiters, setRecruiters] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/getrecruiters`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("data msg received", data.msg);
        console.log("email check", data.msg[0].isEmailApproved);
        setRecruiters(data.msg);
      });
  }, []);

  const handleApprovalToggle = (id, email, firstName) => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/updateStatus`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, email, firstName }),
    })
      .then((res) => res.json())
      .then((data) => {
        // Update the recruiters state with the new approval status
        setRecruiters((prevRecruiters) =>
          prevRecruiters.map((recruiter) =>
            recruiter._id === id ? { ...recruiter, isApproved: !recruiter.isApproved } : recruiter
          )
        );
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  return (
    <div className="MainContainer">
      <div style={{ height: "70px" }}>
        <Navbar />
      </div>
      <hr />
      <div className="container second-main">
        <div className="row">
          <div className="col-1 col-lg-3 sidebarDiv">
            <AdminSidebar />
          </div>
          <div className="col-11 col-lg-9">
            <h2>Recruiters List</h2>
            <table className="table">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Role</th>
                  <th>Email</th>
                  <th>IsEmailApproved</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {recruiters.map((recruiter) => (
                  <tr key={recruiter._id}>
                    <td>{recruiter.firstName}</td>
                    <td>{recruiter.role}</td>
                    <td>{recruiter.email}</td>
                    <td>{recruiter.isEmailApproved ? "Yes" : "No"}</td>
                    <td>
                      <button
                        className="btn btn-primary action-button"
                        onClick={() => handleApprovalToggle(recruiter._id, recruiter.email, recruiter.firstName)}
                      >
                        {recruiter.isApproved ? "Disapprove" : "Approve"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
}

export default AdminStatus;
