import React, { useState } from "react";
import Navbar from "./Navbar";
import "react-circular-progressbar/dist/styles.css";
import AdminSidebar from "./AdminSidebar";
import "../styles/AdminChat.css";
import { saveAs } from "file-saver";

function AdminChat() {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSendMessage = () => {
    if (inputValue.trim() !== "") {
      const newMessage = { type: "user", text: inputValue };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInputValue("");
      setLoading(true);

      const formdata = new FormData();
      formdata.append("message", inputValue.trim());

      fetch("https://neverhunt-python.apps.neverhunt.io/user_chat_bot", {
        method: "POST",
        body: formdata,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("data received from python ", data);
          const botResponse = { type: "bot", text: data };
          setMessages((prevMessages) => [...prevMessages, botResponse]);
        })
        .catch((err) => {
          console.log("err", err.message);
          const botResponse = {
            type: "bot",
            text: { error: "An error occurred: " + err.message },
          };
          setMessages((prevMessages) => [...prevMessages, botResponse]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const formatBotResponse = (data) => {
    if (!data.result || !data.result.answer) return <p>Invalid response format</p>;

    return data.result.answer.map((item, index) => (
      <div key={index} className="response-item">
        <p><strong>City:</strong> {item.city}</p>
        <p><strong>Experience:</strong> {item.experience}</p>
        <p><strong>Filename:</strong> {item.filename}</p>
        <p><strong>Candidate Name:</strong> {item.parsed_resume.candidate_name}</p>
        <p><strong>Potential Roles:</strong> {item.potential_roles.join(", ")}</p>
        <p><strong>Full Score:</strong> {item.full_score}</p>
        <p><strong>State:</strong> {item.state}</p>
        <p><strong>Work Authorization:</strong> {item.work_auth}</p>
  
          <button onClick={() => handleDownload(item.filename)} className="download-button">Download Resume</button>
        
        {index < data.result.answer.length - 1 && <hr />}
      </div>
    ));
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDownload = (filename, resumeText) => {
    const fileurl = `${process.env.REACT_APP_BACKEND_BASE_URL}/ResumeUploads/${filename}`;
    // Implement download logic here
    fetch(fileurl)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, filename);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <div className="MainContainer">
      <div style={{ height: "70px" }}>
        <Navbar />
      </div>
      <hr />
      <div className="container second-main">
        <div className="row">
          <div className="col-1 col-lg-3 sidebarDiv">
            <AdminSidebar />
          </div>
          <div className="col-11 col-lg-9 chatContainer">
            <div className="chatBox">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`message ${
                    message.type === "user" ? "userMessage" : "botMessage"
                  }`}
                >
                  {message.type === "user" ? (
                    message.text
                  ) : (
                    formatBotResponse(message.text)
                  )}
                </div>
              ))}
              {loading && (
                <div className="message botMessage">
                  Loading...
                </div>
              )}
            </div>
            <div className="inputBox">
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Type your message here..."
              />
              <button onClick={handleSendMessage}>Send</button>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
}

export default AdminChat;
