import React, { useEffect, useState } from "react";
import "../styles/AdminChat.css";
import { saveAs } from "file-saver";
import CompanySidebar from "./CompanySidebar";
import Navbar from "./Navbar";

function CompanySemanticSearch() {
  const [messages, setMessages] = useState([]);
  const [conversationHistory, setConversationHistory] = useState([]); // To store the entire conversation
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [companyID, setCompanyID] = useState("");
  const [socket, setSocket] = useState(null);

  const token = localStorage.getItem("token");

  // WebSocket setup inside useEffect
  useEffect(() => {
    const ws = new WebSocket("ws://neverhunt-python.apps.neverhunt.io/ws_soc");

    ws.onopen = () => {
      console.log("WebSocket connection established");
    };

    ws.onmessage = (event) => {
      console.log("Message received from server:", event.data);
      const newMessage = { type: "bot", text: event.data };
      setMessages((prevMessages) => [...prevMessages, newMessage]);

      // Update conversation history
      setConversationHistory((prevHistory) => [...prevHistory, newMessage]);
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    setSocket(ws);

    // Cleanup function to close the WebSocket connection when the component unmounts
    return () => {
      ws.close();
    };
  }, []);

  const handleSendMessage = (messageText) => {
    if (messageText.trim() !== "") {
      const newMessage = { type: "user", text: messageText };
      const updatedConversationHistory = [...conversationHistory, newMessage];

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setConversationHistory(updatedConversationHistory); // Update conversation history
      setInputValue("");
      setLoading(true);

      // Send the conversation history and new message to the WebSocket server
      if (socket && socket.readyState === WebSocket.OPEN) {
        const payload = {
          conversationHistory: updatedConversationHistory, // Send the entire conversation history
          message: messageText,
          companyID: companyID,
        };
        console.log("payload: ", payload);
        socket.send(JSON.stringify(payload));
      } else {
        console.error("WebSocket connection is not open");
      }

      const formdata = new FormData();
      formdata.append("message", messageText.trim());
      formdata.append("companyid", JSON.stringify(companyID));

      // The fetch API calls are commented out as we're focusing on WebSocket communication
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/company/getid`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("data company", data.msg);
        setCompanyID(data.msg);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [token]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage(inputValue);
    }
  };

  const handleDownload = (filename) => {
    const fileurl = `${process.env.REACT_APP_BACKEND_BASE_URL}/ResumeUploads/${filename}`;
    fetch(fileurl)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, filename);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const formatBotResponse = (message) => {
    try {
      const data = JSON.parse(message);
      if (data.result && data.result.answer) {
        return data.result.answer.map((item, index) => (
          <div key={index} className="response-item">
            <p>{item.content}</p>
            {index < data.result.answer.length - 1 && <hr />}
          </div>
        ));
      }
    } catch (e) {
      // If the message is not JSON, return it as is
      return <p>{message}</p>;
    }

    return <p>Invalid response format</p>;
  };

  return (
    <div className="MainContainer">
      <div style={{ height: "70px" }}>
        <Navbar />
      </div>
      <hr />
      <div className="container second-main">
        <div className="row">
          <div className="col-1 col-lg-3 sidebarDiv">
            <CompanySidebar />
          </div>
          <div className="col-11 col-lg-9 chatContainer">
            <div className="chatBox">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`message ${
                    message.type === "user" ? "userMessage" : "botMessage"
                  }`}
                >
                  {message.type === "user" ? message.text : formatBotResponse(message.text)}
                </div>
              ))}
              {loading && <div className="message botMessage">Loading...</div>}
            </div>
            <div className="inputBox">
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Type your message here..."
              />
              <button onClick={() => handleSendMessage(inputValue)}>Send</button>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
}

export default CompanySemanticSearch;
