import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fi_plus_circle from "../Image/fi_plus-circle.svg";
import fi_file_text from "../Image/fi_file-text.svg";
import JobSidebar from "../components/Jobsidebar";
import JobSeekerNavbar from "../components/JobSeekerNavbar";
import "../pages/socialLinks.css";
import Jobsidebar from "../components/Jobsidebar";


function MyProfileSeeker() {
  const [Sponsorship, setSponsorship] = useState("No");
  const [Availability, setAvailability] = useState("Immediately");
  const [interviewAvailability, setInterviewAvailability] = useState("24hrs");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userid, setUserid] = useState("");
  const [jobType, setjobType] = useState("Fulltime");
  const [jobLocType, setJobLocType] = useState("Onsite");
  const [jobStatus, setJobStatus] = useState("passive");

  const [location, setLocation] = useState({
    city: "",
    state: "",
    zipCode: "",
  });

  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [cityval, setCityval] = useState(false);
  const locationInputRef = useRef(null);
  const [radius, setRadius] = useState("5km");
  const [resumeparser, setResumeparser] = useState("")

  const [phoneEmpty, setPhoneEmpty] = useState(false);
  const [cityEmpty, setCityEmpty] = useState(false);
  const [stateEmpty, setStateEmpty] = useState(false);
  const [zipEmpty, setZipEmpty] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [jd, setJd] = useState(null);

  const navigate = useNavigate();
  const { uniqueID } = useParams();

  const token = localStorage.getItem("token");
  const email = JSON.parse(localStorage.getItem("email"));

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/user/token-expire?email=${email}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("data token", data.msg);
        if (data.msg === "Invalid token. Please login") {
          localStorage.removeItem("token");
          navigate(`/`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  useEffect(() => {
    if (!token) {
      navigate(`/SingleJobPost/${uniqueID}/a`);
    }
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/getDetails`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Success", data.msg);
        setSponsorship(data.msg.sponsorship);
        setAvailability(data.msg.Availability);
        setInterviewAvailability(data.msg.interviewAvailability);
        setPhoneNumber(data.msg.phone);
        setJobStatus(data.msg.jobStatus)
        setLocation({
          city: data.msg.city,
          state: data.msg.state,
          zipCode: String(data.msg.zip),
        });
        setSelectedFile({ ...selectedFile, name: data.msg.resumeFilename });
      })
      .catch((err) => {
        console.log("Error", err.message);
      });
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/get-jd/${uniqueID}`)
      .then((res) => res.json())
      .then((data) => {
        setJd(data.msg);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [uniqueID]);

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  function divideStringIntoTenths(data) {
    const length = data.length;
    const segmentLength = Math.floor(length / 10);

    const segments = [];
    for (let i = 0; i < 10; i++) {
      const start = i * segmentLength;
      const end = (i + 1) * segmentLength;
      segments.push(data.substring(start, end));
    }

    return segments;
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getUid`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUserid(data.msg);
      });
  }, []);

  const handleFileChange = async (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    console.log("file", file);
    const base64 = await convertToBase64(file);
    const segments = divideStringIntoTenths(base64);
    setSelectedFile(file);
  
    const formdata = new FormData();
    formdata.append("userid", userid);
    formdata.append("resume", file);
  
    fetch(`${process.env.REACT_APP_PYTHON_BASE_URL}/process_resume`, {
      method: "POST",
      body: formdata,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("resume parsed data", data);
        // Ensure parsed_resume and parsed_resume1 are JSON strings
        const parsedResume = JSON.stringify(data.parsed_resume) + JSON.stringify(data.parsed_resume1);
        console.log("full parsed data", parsedResume);
        setResumeparser(parsedResume);
      })
      .catch((err) => {
        console.log("error", err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  

  const handleLocationSuggestionClick = (suggestion) => {
    setLocation({
      city: suggestion.city,
      state: suggestion.state,
      zipCode: suggestion.zip,
    });
    setCityval(true);
    setLocationSuggestions([]);
  };

  const handleCity = (e) => {
    const city = e.target.value;
    setLocation({ ...location, city: e.target.value });
  };

  const searchHandle = () => {
    fetchLocationSuggestions(location.city);
  };

  const handleJobLocTypeChange = (e) => {
    const selectedjobLocType = e.target.value;
    // const isChecked = e.target.checked;
    setjobType(selectedjobLocType);
  };

  const fetchLocationSuggestions = async (input) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/locations?search=${input}`
      );
      const data = await response.json();
      setLocationSuggestions(data.msg);
      console.log("location suggestion check", data.msg);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
    }
  };

  const renderLocationSuggestions = () => {
    if (locationSuggestions.length === 0) return null;
    return (
      <div
        className="location-suggestions-overlay"
        style={{
          position: "absolute",
          zIndex: 1000,
          background: "white",
          border: "1px solid #ccc",
          width: "100%",
          marginTop: "2px",
        }}
        ref={locationInputRef}
      >
        {locationSuggestions.length === 0 ? (
          <p>No Matching jobs found</p>
        ) : (
          <ul className="list-group">
            {locationSuggestions?.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleLocationSuggestionClick(suggestion)}
                className="list-group-item"
                style={{ cursor: "pointer" }}
              >
                {suggestion.city} {suggestion.state}, {suggestion.zip}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchHandle();
    }
  };

  const handleRadiusChange = (e) => {
    setRadius(e.target.value);
  };

  const handleSponsorshipChange = (e) => {
    setSponsorship(e.target.value);
  };

  const handleStatusChange = (e) => {
    setJobStatus(e.target.value);
  };


  const handleJobTypeChange = (e) => {
    const selectedJobType = e.target.value;
    setJobLocType(selectedJobType);
  };

  const handleAvailabilityChange = (e) => {
    setAvailability(e.target.value);
  };

  const handleinterviewAvailabilityChange = (e) => {
    setInterviewAvailability(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleFormSubmit = (e) => {
    if (!selectedFile || !selectedFile.name) {
      setMessage("Please upload your resume to continue");
      return;
    }
  
    if (isLoading) {
      return;
    }
  
    e.preventDefault();
  
    const isPhoneEmpty = phoneNumber.trim() === "";
    const isCityEmpty = location.city.trim() === "";
    const isStateEmpty = location.state.trim() === "";
    const isZipEmpty = location.zipCode.trim() === "";
  
    setPhoneEmpty(isPhoneEmpty);
    setCityEmpty(isCityEmpty);
    setStateEmpty(isStateEmpty);
    setZipEmpty(isZipEmpty);
  
    if (isPhoneEmpty || isCityEmpty || isStateEmpty || isZipEmpty) {
      return;
    } else {
      console.log("sponsorship type", typeof Sponsorship);
      console.log("Availability type", typeof Availability);
      console.log("interviewAvailability type", typeof interviewAvailability);
      console.log("city type", typeof location.city);
      console.log("state type", typeof location.state);
      console.log("zip type", typeof location.zipCode);
      console.log("phoneNumber type", typeof phoneNumber);
      console.log("jobType type", typeof jobType);
      console.log("jobLocType type", typeof jobLocType);
      console.log("radius type", typeof radius);
      console.log("jobStatus type", typeof jobStatus);
  
      const formdata = new FormData();
      formdata.append("file", selectedFile);
      formdata.append("parsedResume", resumeparser);
      formdata.append("sponsorship", Sponsorship);
      formdata.append("Availability", Availability);
      formdata.append("interviewAvailability", interviewAvailability);
      formdata.append("city", location.city);
      formdata.append("state", location.state);
      formdata.append("zip", location.zipCode);
      formdata.append("phone", phoneNumber);
      formdata.append("jobType", jobType);
      formdata.append("jobLocType", jobLocType);
      formdata.append("radius", radius);
      formdata.append("jobStatus", jobStatus);
  
      console.log("FormData entries before update fetch:");
      for (const pair of formdata.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
  
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/update`, {
        method: "PATCH",
        headers: {
          Authorization: token,
        },
        body: formdata,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("data: ", data.msg);
          setMessage(data.msg);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  

  return (
    <div className="MainContainer">
      <div className="mobile-screen">
        <div style={{ height: "70px" }}>
          <JobSeekerNavbar />
        </div>
        <div
          className="col-1 col-lg-3 col-xl-3 hamburgIcon"
          style={{ marginRight: "60px" }}
        >
          <Jobsidebar />
        </div>
      </div>
      <hr />
      <div className="container second-main" >
        <div className="row">
          <div
            className="col-1 col-lg-3 sidebarDiv"
          >
            <JobSidebar />
          </div>
          <div className="col-lg-9" style={{marginLeft:"-40px"}}>
            <div className="container w-100">
              {message && (
                <div className="mt-3 alert alert-info text-center">
                  {message}
                </div>
              )}
              <div style={{ marginBottom: "20px" }}>
                <h5>My Profile</h5>
              </div>
              <form className="text-left">
                <div className="form-group text-start label-txt">
                  <label htmlFor="resume" style={{ marginBottom: "14px" }}>
                    Upload your Resume{" "}
                    <span className="form-label-required text-danger">*</span>
                  </label>
                  <div className="resume-upload-container">
                    {selectedFile ? (
                      <>
                        <div className="uploaded-file">
                          <span className="file-icon">
                            <img
                              src={fi_file_text}
                              alt=""
                              style={{ marginRight: "5px" }}
                            />
                          </span>
                          <span className="file-name">{selectedFile.name}</span>
                        </div>
                        <label
                          htmlFor="resume-file"
                          className="resume-upload-label"
                        >
                          <div
                            className="upload-box"
                            style={{ marginLeft: "25px", width: "293px" }}
                          >
                            <span className="add-resume-text">
                              <img
                                src={fi_plus_circle}
                                alt=""
                                style={{ marginRight: "10px" }}
                              />
                              Change Resume
                            </span>
                          </div>
                          {isLoading && (
                            <div className="loading-spinner">
                              <div className="loader-spn"></div>
                            </div>
                          )}
                        </label>
                      </>
                    ) : (
                      <>
                        <label
                          htmlFor="resume-file"
                          className="resume-upload-label"
                        >
                          <div
                            className="upload-box"
                            style={{ width: "313px" }}
                          >
                            <span className="add-resume-text">
                              <img
                                src={fi_plus_circle}
                                alt=""
                                style={{ marginRight: "10px" }}
                              />
                              Add/change Resume
                              <span>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    marginLeft: "33px",
                                    marginTop: "5px",
                                  }}
                                >
                                  Supported file formats PDF and Word doc
                                </p>
                              </span>
                            </span>
                          </div>
                        </label>
                      </>
                    )}
                    <input
                      type="file"
                      id="resume-file"
                      accept=".pdf, .doc, .docx"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                </div>

                <div className="form-group text-start label-txt ">
                  <label>
                    Status{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="d-flex flex-wrap justify-content-start ">
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="jobTypeFulltime"
                        value="passive"
                        checked={jobStatus === "passive"}
                        onChange={handleStatusChange}
                        required
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeFulltime"
                      >
                        Passive
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="jobTypeContract"
                        value="active"
                        checked={jobStatus === "active"}
                        onChange={handleStatusChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeContract"
                      >
                        Active
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt ">
                  <label>
                    Need Sponsorship{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="d-flex flex-wrap justify-content-start ">
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="jobTypeFulltime"
                        value="No"
                        checked={Sponsorship === "No"}
                        onChange={handleSponsorshipChange}
                        required
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeFulltime"
                      >
                        No
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="jobTypeContract"
                        value="Yes"
                        checked={Sponsorship === "Yes"}
                        onChange={handleSponsorshipChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeContract"
                      >
                        Yes
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt ">
                  <label>
                    Availability{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="d-flex flex-wrap justify-content-start ">
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="jobTypeFulltime"
                        value="Immediately"
                        checked={Availability === "Immediately"}
                        onChange={handleAvailabilityChange}
                        required
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeFulltime"
                      >
                        Immediately
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="jobTypeContract"
                        value="2weeksNotice"
                        checked={Availability === "2weeksNotice"}
                        onChange={handleAvailabilityChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeContract"
                      >
                        In 2 weeks Notice
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt ">
                  <label>
                    Interview Availability{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="d-flex flex-wrap justify-content-start ">
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="jobTypeFulltime"
                        value="24hrs"
                        checked={interviewAvailability === "24hrs"}
                        onChange={handleinterviewAvailabilityChange}
                        required
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeFulltime"
                      >
                        Within 24hrs
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="jobTypeContract"
                        value="48hrs"
                        checked={interviewAvailability === "48hrs"}
                        onChange={handleinterviewAvailabilityChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeContract"
                      >
                        48hrs
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group text-start label-txt">
                  <label>
                    Job Type{" "}
                    <span className="form-label-required text-danger">*</span>
                  </label>
                  <div className="d-flex flex-wrap justify-content-start ">
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="jobTypeFulltime"
                        value="Fulltime"
                        checked={jobType === "Fulltime"}
                        onChange={handleJobLocTypeChange}
                        required
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeFulltime"
                      >
                        Full time
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input checkbox-input"
                        id="jobTypeContract"
                        value="Contract"
                        checked={jobType === "Contract"}
                        onChange={handleJobLocTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeContract"
                      >
                        Contract
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input checkbox-input"
                        id="jobTypeInternship"
                        value="Contract to hire"
                        checked={jobType === "Contract to hire"}
                        onChange={handleJobLocTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeInternship"
                      >
                        Contract to hire
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input checkbox-input"
                        id="jobTypeInternship"
                        value="Internship"
                        checked={jobLocType === "Internship"}
                        onChange={handleJobLocTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeInternship"
                      >
                        Internship
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt ">
                  <label>
                    Job Location Type{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="d-flex flex-wrap justify-content-start ">
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input checkbox-input"
                        id="jobTypeOnsite"
                        value="Onsite"
                        checked={jobLocType === "Onsite"}
                        onChange={handleJobTypeChange}
                        required
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeOnsite"
                      >
                        Onsite
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input checkbox-input"
                        id="jobTypeRemote"
                        value="Remote"
                        checked={jobLocType === "Remote"}
                        onChange={handleJobTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeRemote"
                      >
                        Remote
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input checkbox-input"
                        id="jobTypeHybrid"
                        value="Hybrid"
                        checked={jobLocType === "Hybrid"}
                        onChange={handleJobTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeHybrid"
                      >
                        Hybrid
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt">
                  <label htmlFor="position">
                    Phone Number{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-4">
                      <input
                        type="number"
                        className={`form-control placeholder-gray ${
                          phoneEmpty ? "border-red" : ""
                        }`}
                        id="salary"
                        value={phoneNumber}
                        onChange={handlePhoneChange}
                        placeholder="1234567890"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt">
                  <label>
                    Location{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-4" style={{ position: "relative" }}>
                      <label htmlFor="city">City/Zip</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control placeholder-gray ${
                            cityEmpty ? "border-red" : ""
                          }`}
                          ref={locationInputRef}
                          id="city"
                          value={location.city}
                          onChange={handleCity}
                          onKeyPress={handleKeyPress}
                          placeholder="City or Zip"
                          required
                        />
                        <div
                          className="input-group-append"
                          style={{ cursor: "pointer" }}
                          onClick={searchHandle}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Find"
                        >
                          {(location.city || location.zipCode) && (
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="fa fa-search"></i>
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      {renderLocationSuggestions()}
                    </div>
                    {cityval ? (
                      <>
                        <div className="col-md-4">
                          <label htmlFor="state">State</label>
                          <input
                            type="text"
                            className={`form-control cursor-none ${
                              stateEmpty ? "border-red" : ""
                            }`}
                            id="state"
                            value={location.state}
                            placeholder="State"
                            readOnly
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="zipCode">Zip</label>
                          <input
                            type="text"
                            className={`form-control cursor-none ${
                              zipEmpty ? "border-red" : ""
                            }`}
                            id="zipCode"
                            value={location.zipCode}
                            placeholder="Zip Code"
                            readOnly
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>

                <div className="form-group text-start label-txt" style={{width:"31%"}}>
                  <label htmlFor="radius">
                    Radius
                    <span className="form-label-required text-danger">*</span>
                  </label>
                  <select
                    className="form-control custom-select"
                    id="radius"
                    value={radius}
                    onChange={handleRadiusChange}
                    required
                    style={{ width: "100%" }}
                  >
                    <option value="5km">5km</option>
                    <option value="10km">10km</option>
                    <option value="25km">25km</option>
                    <option value="50km">50km</option>
                  </select>
                </div>
              </form>
              <button
                className={`btns ${isLoading ? "disabled" : ""}`}
                onClick={handleFormSubmit}
                disabled={isLoading}
              >
                Save
              </button>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
      <div class="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2023 Neverhunt All rights reserved.
      </div>
    </div>
  );
}

export default MyProfileSeeker;