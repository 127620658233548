import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import JobSeekerNavbar from "../components/JobSeekerNavbar";
import "../pages/socialLinks.css";
import Jobsidebar from "../components/Jobsidebar";
import JobSidebar from "../components/Jobsidebar";
import DOMPurify from "dompurify";
import io from 'socket.io-client';
import { jsonrepair } from "jsonrepair";
import "../styles/Suggestions.css"; 

// const socket = io('http://localhost:8081');


function Suggestions() {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isModalOpenjd, setIsModalOpenjd] = useState(false);
  const [parsedResume, setParsedResume] = useState("")
  const [resumefilename, setResumefilename] = useState("")
  const [seekerid, setSeekerid] = useState("")
  const token = localStorage.getItem("token");



  const handleApply = (jd, jobid) => {

    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/getDetails`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: token,
      },
    })
    .then((res)=> res.json())
    .then((data)=>{
      console.log("data received", data.msg)
    })
    .catch((err)=>{
      console.log("error", err.message)
    })
   
    console.log("jobid", jobid)
      // Handle the form submission logic here
      const formData = new FormData();
      formData.append("jobdesc", JSON.stringify(jd));
      formData.append("resume", JSON.stringify(parsedResume));
      formData.append("filename", resumefilename);
      formData.append("seekerid", JSON.stringify(seekerid));
  
          setTimeout(async () => {
        
            const response = await fetch(
              `${process.env.REACT_APP_PYTHON_BASE_URL}/get_resume_two`,
              {
                method: "POST",
                body: formData,
              }
            );
            const data = await response.json();
            if (data.answer && data.answer.length > 0) {
              console.log("dataAnswer: ", data.answer);
              console.log("check id", jobid)
              // fetch request to save the results
              fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/ResumeResults`, {
                method: "POST",
                headers: {
                  "content-type": "application/json",
                },
                body: JSON.stringify({
                  uniqueJobID: jobid,
                  matchingData: data.answer,
                }),
              })
                .then((res) => res.json())
                .then((data) => {
                  console.log("saved successfully", data.msg);
                });
            }
          }, 500);
      
        
            //fetch req to save the job
            // fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/applyjob/jobform`, {
            //   method: "POST",
            //   headers: {
            //     "content-type": "application/json",
            //   },
            //   body: JSON.stringify({
            //     jobUniqueID : id,
            //     email:email,
            //     position:jobData.position,
            //     resumeFilename: file.name
            //   })
            // })
            // .then((res)=> res.json())
            // .then((data)=>{
            //   console.log(data.msg)
            // }).catch((err)=>{
            //   console.log(err.message)
            // })
   
  };

  useEffect(()=>{
   fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/getseekerid`, {
    method: "GET",
    headers:{
      Authorization: token
    }
   })
   .then((res)=> res.json())
   .then((data)=>{
    console.log("response", data.msg)
    setSeekerid(data.msg._id)
   })
  }, []);

  //${process.env.REACT_APP_BACKEND_BASE_URL}/clients/getDetails

  const handleJob = () => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/getDetails`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Success: getDetails data", data.msg);
        setParsedResume(data.msg.parsedResume)
        setResumefilename(data.msg.resumeFilename)
        const formdata = new FormData();
        formdata.append("resumeInfo", JSON.stringify(data.msg.parsedResume));
        fetch(`${process.env.REACT_APP_PYTHON_BASE_URL}/match_with_jd`, {
          method: "POST",
          body: formdata,
        })
          .then((res) => res.json())
          .then((data) => {
            console.log("Raw match_with_jd response:", data.result.answer);
            if (data && data.result) {
              console.log("Success: match_with_jd data", data.result);
              setJobData(data.result.answer); 
              fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/suggestions/addJobApplicant`, {
                method: "POST",
                headers: {
                  "content-type":"application/json",
                  Authorization: token
                },
                body: JSON.stringify({
                  jobData: data.result.answer
                })
              })
              .then((res) => res.json())
              .then((data)=>{
                console.log("Success(", data.msg)
              }).catch((err)=>{
                console.log("Error", err.message);
              })
            } else {
              console.log("Unexpected data format from match_with_jd:", data);
              setJobData([]);
            }
          })
          .catch((err) => {
            console.log("Error in match_with_jd:", err.message);
            setJobData([]);
          });
      })
      .catch((err) => {
        console.log("Error in getDetails:", err.message);
      });
  };

//   const handleUserChat = (id) => {
//     console.log("UserChat initialized", id);
//     socket.emit('register', { userId: id, userType: 'seeker' });

//     // Listening for incoming private messages
//     socket.on('privateMessage', ({ fromUserId, message }) => {
//         console.log(`Message from ${fromUserId}: ${message}`);
//         // Handle incoming message
//         // displayMessage(fromUserId, message);
//     });
// };

// Function to display the message in the chat UI (implementation depends on your UI framework)
const displayMessage = (fromUserId, message) => {
    // Code to display the message on the user's chat interface
    console.log(`Displaying message from ${fromUserId}: ${message}`);
};

// Example usage: Initializing chat for a seeker with ID 'seeker123'

  // handleUserChat(seekerid);


  // useEffect(() => {
  //   socket.on('cronMessage', (data) => {
  //     console.log("socket msg...", data.msg);
  //     handleJob();
  //   });

  //   return () => {
  //     socket.off('cronMessage');
  //   };
  // }, []);

  const handleViewJob = (job) => {
    setSelectedJob(job);
    setIsModalOpenjd(true);
  };

  const handleCloseModaljd = () => {
    setIsModalOpenjd(false);
    setSelectedJob(null);
  };

  useEffect(()=>{
   fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/suggestions/getJobApplicant`, {
    method: "GET",
    headers:{
      Authorization: token,
    }
   })
   .then((res)=> res.json())
   .then((data)=>{
    console.log(data.msg)
    setJobData(data.msg.jobData)
   })
  }, [])


  return (
    <div className="MainContainer">
      <div className="mobile-screen">
        <div style={{ height: "70px" }}>
          <JobSeekerNavbar />
        </div>
        <div
          className="col-1 col-lg-3 col-xl-3 hamburgIcon"
          style={{ marginRight: "60px" }}
        >
          <Jobsidebar />
        </div>
      </div>
      <hr />
      <div className="container second-main">
        <div className="row">
          <div className="col-1 col-lg-3 sidebarDiv">
            <JobSidebar />
          </div>
          <div className="col-11 col-lg-9 job-cards-container">
            <h2 className="recommended-jobs-title" onClick={handleJob}>
              Recommended Jobs
            </h2>
            {jobData && jobData.length > 0 ? (
              jobData.map((job, index) => {
      
                const jobDetails = JSON.parse(job.payload.job_des);
         
                return (
                  <div key={index} className="job-card-wrapper">
                    <div className="job-card">
                      <h3 className="job-position">{jobDetails.roles[0]}</h3>
                      <p className="job-summary">
                        <strong>Summary:</strong> {jobDetails.responsibilities}
                      </p>
                      <p className="job-detail">
                        <strong>Job Type:</strong> {jobDetails.jobType}
                      </p>
                      <p className="job-detail">
                        <strong>Location Type:</strong> {jobDetails.locationType}
                      </p>
                      <p className="job-detail">
                        <strong>Salary:</strong> {jobDetails.compensation}
                      </p>
                      <div className="buttons">
                        <button onClick={() => handleViewJob(job)} className="view-button">View</button>
                        <button
                          className="apply-button"
                          onClick={() => handleApply(jobDetails, job.payload.jobid)}
                          disabled={appliedJobs[index]}
                        >
                          {appliedJobs[index] ? "Applied" : "Apply Now"}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No job recommendations available at the moment.</p>
            )}
          </div>
        </div>
      </div>
      {isModalOpenjd && selectedJob && (
        <div className="modaljd">
          <div className="modal-contentjd">
            <span className="close" onClick={handleCloseModaljd}>
              &times;
            </span>
            <div className="job-details-container">
              {/* <h2>{selectedJob.payload.jobDescription}</h2> */}
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(selectedJob.payload.jobDescription),
                }}
              ></p>
            </div>
          </div>
        </div>
      )}
      <div className="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
}

export default Suggestions;
