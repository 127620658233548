// ErrorPage.js
import React from 'react';
import './ErrorPage.css';

function ErrorPage() {
  return (
    <div className="error-container">
      <h1>Something went wrong</h1>
      <p>We're sorry, but something went wrong. Please try again later.</p>
    </div>
  );
}

export default ErrorPage;
