import React, { Component } from 'react';
import ErrorPage from './ErrorPage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  async componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    
    // Log the error to the console
    console.error("Something went wrong:", error, errorInfo);

    // Send the error details to the backend server
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/log/logError`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: error.toString(),
          stack: error.stack,
          componentStack: errorInfo.componentStack,
        }),
      }).then((res)=> res.json())
      .then((data)=>{
        console.log("data: " + data.msg)
      })
    } catch (err) {
      console.error("Error logging to server:", err);
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
