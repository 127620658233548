import React, { useEffect, useState } from "react";

function AudioPlayer({ dataURL }) {
    console.log("dataurifromaudio", dataURL);
  const [audio] = useState(new Audio(dataURL));

  useEffect(() => {
    // Play the audio when the component mounts
    console.log("intoaudioplay");
    audio.play().catch((error) => {
      console.error("Error playing audio:", error);
    });

    // Clean up the audio element when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [audio]);

  return (
    <div>
      <audio controls>
        <source src={dataURL} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

export default AudioPlayer;
