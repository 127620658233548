import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { Modal, Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./jobPost.css";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import JobThankyou from "./JobThankyou";

const JobPost = () => {
  const [position, setPosition] = useState("");
  const [salary, setSalary] = useState();
  const [description, setDescription] = useState("");
  const [positionType, setPositionType] = useState(["W2"]);
  const [jobType, setJobType] = useState("Onsite");
  const [jobLocType, setjobLocType] = useState("Fulltime");
  const [questions, setQuestions] = useState([]);
  const [benefits, setBenefits] = useState("Available");
  const [salaryRadio, setSalaryRadio] = useState("hr");
  const [status, setStatus] = useState("Draft");
  const [message, setMessage] = useState("");
  const locationInputRef = useRef(null);
  const [isDescriptionEmpty, setIsDescriptionEmpty] = useState(true);
  const [userQuestions, setUserQuestions] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showAddQuestionInput, setShowAddQuestionInput] = useState(false);
  const [location, setLocation] = useState({
    city: "",
    state: "",
    zipCode: "",
  });

  const [isAddingNewQuestion, setIsAddingNewQuestion] = useState(true);
  const [newQuestion, setNewQuestion] = useState("");
  const [newQuestionSave, setNewQuestionSave] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [questionOptions, setQuestionOptions] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [companyName, setCompanyName] = useState("");

  const [positionEmpty, setPositionEmpty] = useState(false);
  const [salaryEmpty, setSalaryEmpty] = useState(false);
  const [descriptionEmpty, setDescriptionEmpty] = useState(false);
  const [cityEmpty, setCityEmpty] = useState(false);
  const [stateEmpty, setStateEmpty] = useState(false);
  const [zipEmpty, setZipEmpty] = useState(false);
  const [companyNameEmpty, setCompanyNameEmpty] = useState(false);
  const [salaryradioEmpty, setSalaryRadioEmpty] = useState(false);
  const [cityval, setCityval] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [initialFocus, setInitialFocus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uniqueID, setUniqueID] = useState("");
  const token = localStorage.getItem("token");
  const email = JSON.parse(localStorage.getItem("email"));
  const [gen, setGen] = useState(false);
  const [processedJ, setProcessedJ] = useState("");
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [jobidrec, setJobidrec] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/user/token-expire?email=${email}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("data token", data.msg);
        if (data.msg === "Invalid token. Please login") {
          //  setIsToeknValid(false)
          localStorage.removeItem("token");
          navigate(`/`);
        } else {
          //  setIsToeknValid(true)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/dashboard");
  };

  useEffect(() => {
    if (!token) {
      navigate(`/`);
    }
  }, []);

  const handleInputFocus = () => {
    setIsDropdownOpen(true);
    if (!initialFocus) {
      // console.log("focus")
      setInitialFocus(true);
    }
  };

  const handleInputBlur = () => {
    // setIsDropdownOpen(false);
  };

  const handleDropdownClick = () => {
    setIsDropdownOpen(true);
  };

  const toggleAddingNewQuestion = (e) => {
    e.preventDefault();
    setIsAddingNewQuestion(!isAddingNewQuestion);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getQuestions`, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setShowSearchBar(!showSearchBar);
        setUserQuestions(data.msg);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [newQuestionSave]);

  const addQuestion = () => {
    if (newQuestion.trim() === "") {
      return;
    }
    const newQuestionObject = {
      title: newQuestion,
    };
    if (newQuestionSave === true) {
      const obj = {
        saveQuestion: newQuestion,
      };
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/saveQuestion`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(obj),
      })
        .then((res) => res.json())
        .then((data) => {
          toast.success("Question saved successfully!", {
            position: "top-right",
            autoClose: 2000, // 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
        })
        .catch((err) => {
          console.log(err.message);
        });

      // fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getQuestions`, {
      //   method: "GET",
      //   headers: {
      //     Authorization: token,
      //   },
      // })
      //   .then((res) => res.json())
      //   .then((data) => {
      //     setShowSearchBar(!showSearchBar);
      //     setUserQuestions(data.msg);
      //   })
      //   .catch((err) => {
      //     console.log(err.message);
      //   });
    }

    setQuestions([...questions, newQuestionObject]);
    setNewQuestion("");
    setIsRequired(false);
    // setIsAddingNewQuestion(false);
  };

  const removeQuestion = (questionId) => {};

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/mapping`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("data", data.msg);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  const handlePositionChange = (e) => {
    setPosition(e.target.value);
  };

  const handleSalaryChange = (e) => {
    setSalary(e.target.value);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const handleSearchInputChange = (e) => {
    const newSearchKeyword = e.target.value;
    setSearchKeyword(newSearchKeyword);

    // Update search results instantly based on the new keyword
    const filteredResults = userQuestions?.filter((question) =>
      question.toLowerCase().includes(newSearchKeyword.toLowerCase())
    );
    setSearchResults(filteredResults);

    // Toggle visibility of search results
    setShowSearchResults(newSearchKeyword !== "");
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    const filteredResults = userQuestions?.filter((question) =>
      question.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    setSearchResults(filteredResults);
  };

  const handlePositionTypeChange = (e) => {
    const selectedPositionType = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setPositionType([...positionType, selectedPositionType]);
    } else {
      setPositionType(
        positionType.filter((type) => type !== selectedPositionType)
      );
    }
  };

  const handleJobTypeChange = (e) => {
    const selectedJobType = e.target.value;
    // const isChecked = e.target.checked;
    setJobType(selectedJobType);

    // if (isChecked) {
    //   setJobType([...jobType, selectedJobType]);
    // } else {
    //   setJobType(jobType.filter((type) => type !== selectedJobType));
    // }
  };

  const handleCompanyChange = (e) => {
    setCompanyName(e.target.value);
    console.log("company name", e.target.value);
  };

  const handleJobLocTypeChange = (e) => {
    const selectedjobLocType = e.target.value;
    // const isChecked = e.target.checked;
    setjobLocType(selectedjobLocType);
  };

  const handleQuestionChange = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = e.target.value;
    setQuestions(updatedQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, ""]);
    setShowAddQuestionInput(true);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const handleBenefitsChange = (e) => {
    setBenefits(e.target.value);
  };

  const handleSalaryRadio = (e) => {
    setSalaryRadio(e.target.value);
    console.log("salary type is ", e.target.value);
  };

  //gen button diasable
  useEffect(() => {
    if (description.trim() !== "") {
      setIsDescriptionEmpty(false);
    }

    if (description.trim() === "") {
      setIsDescriptionEmpty(true);
      // window.location.reload();
    }
    // setIsDescriptionEmpty(!description.trim());
  }, [description]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (position.trim() === "") {
      setPositionEmpty(true);
    } else {
      setPositionEmpty(false);
    }

    if (salary === 0) {
      setSalaryEmpty(true);
    } else {
      setSalaryEmpty(false);
    }

    if (location.city.trim() === "") {
      setCityEmpty(true);
    } else {
      setCityEmpty(false);
    }

    if (companyName.trim() === "") {
      setCompanyNameEmpty(true);
    } else {
      setCompanyNameEmpty(false);
    }

    if (salaryRadio.trim() === "") {
      setSalaryRadioEmpty(true);
    } else {
      setSalaryRadioEmpty(false);
    }

    if (location.zipCode.trim() === "") {
      setZipEmpty(true);
    } else {
      setZipEmpty(false);
    }

    if (description.trim() === "") {
      setDescriptionEmpty(true);
    } else {
      setDescriptionEmpty(false);
    }

    // If any required field is empty, prevent form submission
    if (
      position.trim() === "" ||
      salary.trim() === "" ||
      description.trim() === "" ||
      companyName.trim() === "" ||
      location.city.trim() === "" ||
      location.state.trim() === "" ||
      salary === "" ||
      salaryRadio.trim() === "" ||
      location.zipCode.trim() === ""
    ) {
      return;
    } else {
      let obj = {
        position: position,
        jobDescription: description,
        processedJd: processedJ,
        positionType: positionType,
        companyName: companyName,
        salary: salary,
        addNewQuestion: questions,
        benefits: benefits,
        jobType: jobType,
        jobLocType: jobLocType,
        city: location.city,
        state: location.state,
        zip: location.zipCode,
        salaryType: salaryRadio,
        jobidrec: jobidrec,
        status: "Posted",
      };
      //`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/postjob`
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/postjob`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(obj),
      })
        .then((res) => res.json())
        .then((data) => {
          setMessage(data.msg);
          if (data.msg === "Job posted successfully") {
            setIsModalOpen(true);
            setStatus("Posted");
            setUniqueID(data.uniqueID);
            const uniqueID = data.uniqueID;
            // navigate(`/JobThankyou/${uniqueID}`);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleAddSearchedQuestion = (question) => {
    setInitialFocus(false);
    const newQuestionObject = {
      title: question,
      required: isRequired,
    };

    setQuestions([...questions, newQuestionObject]);
    setSearchKeyword(""); // Clear the search keyword
    setShowSearchResults(false); // Hide the search results
    setIsDropdownOpen(false);
  };

  const handleQuestions = (e) => {
    setGen(true);
    const fData = new FormData();
    fData.append("jd", description);
    e.preventDefault();
    //https://resume-matching.dev.neverhunt.io/process_jd
    fetch("https://resume-matching.dev.neverhunt.io/process_jd", {
      method: "POST",
      body: fData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.questions);
        setProcessedJ(data.jd_processed);

        // Remove number sequence from fetched questions
        const questionsArray = data.questions
          .split("\n")
          .map((question) => question.replace(/^\d+\.\s*/, ""))
          .filter((question) => question.trim()); 

        const updatedQuestions = [...questions, ...questionsArray];
        console.log("qst", questionsArray);
        setQuestions(updatedQuestions);
        setGen(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getQuestions`
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getQuestions`, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("response", data.msg);
        setShowSearchBar(!showSearchBar);
        setUserQuestions(data.msg);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const quillStyles = {
    borderRadius: "5px",
    height: "300px", // Set a static height for testing
    // overflowY: "scroll"
  };
  const reactquillStyles = {
    // border: 1px solid red;
    // border: "1px solid red",
    borderRadius: "5px",
    height: "255px",
    // overflowY: "scroll"
  };

  const borderRed = {
    border: "1px solid red",
  };

  const searchHandle = () => {
    fetchLocationSuggestions(location.city);
  };

  const fetchLocationSuggestions = async (input) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/locations?search=${input}`
      );
      const data = await response.json();
      setLocationSuggestions(data.msg);
      console.log("location suggestion check", data.msg);
      // setSearchLocation(data.msg[data.msg.length-1].zip)
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
    }
  };

  const handleCity = (e) => {
    const city = e.target.value;
    setLocation({ ...location, city: e.target.value });
  };

  useEffect(() => {
    // Function to handle clicks outside of the dropdown
    const handleClickOutside = (event) => {
      // alert("clikced outside of dropdown")

      if (
        locationInputRef.current &&
        !locationInputRef.current.contains(event.target)
      ) {
        // Click occurred outside of the dropdown, so close it
        setLocationSuggestions([]);
      }
    };

    // Adding event listener to detect clicks on the document body
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLocationSuggestionClick = (suggestion) => {
    setLocation({
      city: suggestion.city,
      state: suggestion.state,
      zipCode: suggestion.zip,
    });
    setCityval(true);
    // setLocationSuggestions([]);
  };

  useEffect(() => {
    setLocationSuggestions([]);
  }, [cityval]);

  const renderLocationSuggestions = () => {
    if (locationSuggestions.length === 0) return null;
    return (
      <div
        className="form-group text-start label-txt"
        style={{ position: "relative" }}
      >
        <div className="location-suggestions-overlay" ref={locationInputRef}>
          {/* <ul> */}
          {locationSuggestions.length === 0 ? (
            <p>No Matching jobs found</p>
          ) : (
            <ul>
              {locationSuggestions?.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleLocationSuggestionClick(suggestion)}
                >
                  {suggestion.city} {suggestion.state}, {suggestion.zip}
                </li>
              ))}
            </ul>
          )}
          {/* </ul> */}
        </div>
      </div>
    );
  };

  return (
    <div className="MainContainer">
      <div style={{ height: "70px" }}>
        <Navbar />
      </div>

      <hr />
      <div className="container second-main">
        <div className="row">
          <div className="col-1 col-lg-3 sidebarDiv">
            <Sidebar />
          </div>
          <div className="col-lg-9">
            <div className="container w-100">
              {message && (
                <div className="mt-3 alert alert-info text-center">
                  {message}
                </div>
              )}
              <form className="text-left">
                <center
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h2 class="tw-text-2xl tw-font-medium tw-text-[#18191C] tw-mb-8.5">
                    Post Job
                  </h2>
                  <p className="status">Status: {status}</p>
                </center>

                <div className="form-group text-start label-txt">
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="position">
                        Job Title{" "}
                        <span class="form-label-required text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control placeholder-gray ${
                          positionEmpty ? "border-red" : ""
                        }`}
                        id="position"
                        value={position}
                        onChange={handlePositionChange}
                        placeholder="Add job tittle, role, vacancies etc"
                        required
                      />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="position">
                        Job ID{" "}
                        {/* <span class="form-label-required text-danger">*</span> */}
                      </label>
                      <input
                        type="text"
                        className={`form-control placeholder-gray}`}
                        id="position"
                        value={jobidrec}
                        onChange={(e) => setJobidrec(e.target.value)}
                        placeholder="Add job id"
                        maxLength={8}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt locat">
                  <label>
                    Location{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="row">
                    <div className="form-group text-start label-txt">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="city">City/Zip</label>
                          <div className="input-group">
                            <input
                              type="text"
                              className={`form-control placeholder-gray ${
                                cityEmpty ? "border-red" : ""
                              }`}
                              ref={locationInputRef}
                              id="city"
                              value={location.city}
                              onChange={handleCity}
                              placeholder="City or Zip"
                              required
                            />
                            <div
                              className="input-group-append"
                              onClick={searchHandle}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Find"
                            >
                              {(location.city || location.zipCode) && (
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i className="fa fa-search"></i>
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {cityval ? (
                          <>
                            <div className="col-md-4">
                              <label htmlFor="state">State</label>
                              <input
                                type="text"
                                className={`form-control placeholder-gray cursor-none ${
                                  cityEmpty ? "border-red" : ""
                                }`}
                                id="state"
                                value={location.state}
                                placeholder="State"
                              />
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="zipCode">Zip</label>
                              <input
                                type="text"
                                className={`form-control placeholder-gray cursor-none${
                                  zipEmpty ? "border-red" : ""
                                }`}
                                id="zipCode"
                                value={location.zipCode}
                                placeholder="Zip Code"
                              />
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                {renderLocationSuggestions()}

                <div className="form-group text-start label-txt">
                  <label>
                    Job Type{" "}
                    <span className="form-label-required text-danger">*</span>
                  </label>
                  <div className="d-flex flex-wrap justify-content-start ">
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="jobTypeFulltime"
                        value="Fulltime"
                        checked={jobLocType === "Fulltime"}
                        onChange={handleJobLocTypeChange}
                        required
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeFulltime"
                      >
                        Full time
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input checkbox-input"
                        id="jobTypeContract"
                        value="Contract"
                        checked={jobLocType === "Contract"}
                        onChange={handleJobLocTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeContract"
                      >
                        Contract
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input checkbox-input"
                        id="jobTypeInternship"
                        value="Contract to hire"
                        checked={jobLocType === "Contract to hire"}
                        onChange={handleJobLocTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeInternship"
                      >
                        Contract to hire
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input checkbox-input"
                        id="jobTypeInternship"
                        value="Internship"
                        checked={jobLocType === "Internship"}
                        onChange={handleJobLocTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeInternship"
                      >
                        Internship
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt ">
                  <label>
                    Job Location Type{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="d-flex flex-wrap justify-content-start ">
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input checkbox-input"
                        id="jobTypeOnsite"
                        value="Onsite"
                        checked={jobType === "Onsite"}
                        onChange={handleJobTypeChange}
                        required
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeOnsite"
                      >
                        Onsite
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input checkbox-input"
                        id="jobTypeRemote"
                        value="Remote"
                        checked={jobType === "Remote"}
                        onChange={handleJobTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeRemote"
                      >
                        Remote
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input checkbox-input"
                        id="jobTypeHybrid"
                        value="Hybrid"
                        checked={jobType === "Hybrid"}
                        onChange={handleJobTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeHybrid"
                      >
                        Hybrid
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt ">
                  <label>
                    Tax Term{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="d-flex flex-wrap justify-content-start ">
                    <div className="form-check gap-2">
                      <input
                        type="checkbox"
                        className="form-check-input checkbox-input"
                        id="positionTypeW2"
                        value="W2"
                        checked={positionType.includes("W2")}
                        onChange={handlePositionTypeChange}
                        required
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="positionTypeW2"
                      >
                        W2
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="checkbox"
                        className="form-check-input checkbox-input"
                        id="positionTypeC2H"
                        value="1099"
                        checked={positionType.includes("1099")}
                        onChange={handlePositionTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="positionTypeC2H"
                      >
                        Independent Contractor (1099)
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="checkbox"
                        className="form-check-input checkbox-input"
                        id="positionTypeC2C"
                        value="C2C"
                        checked={positionType.includes("C2C")}
                        onChange={handlePositionTypeChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="positionTypeC2C"
                      >
                        Corp to Corp (C2C)
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt">
                  <label>
                    Benefits{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="d-flex flex-wrap justify-content-start">
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="benefitsAvailable"
                        value="Available"
                        checked={benefits === "Available"}
                        onChange={handleBenefitsChange}
                        required
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="benefitsAvailable"
                      >
                        Available
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="benefitsNotAvailable"
                        value="Optional"
                        checked={benefits === "Optional"}
                        onChange={handleBenefitsChange}
                        required
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="benefitsNotAvailable"
                      >
                        Optional
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt company">
                  <label htmlFor="position">
                    Company Name{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control placeholder-gray ${
                      salaryEmpty ? "border-red" : ""
                    }`}
                    id="salary"
                    value={companyName}
                    onChange={handleCompanyChange}
                    placeholder="Company name"
                    required
                  />
                </div>

                <div className="form-group text-start label-txt compensation">
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="position">
                        Compensation{" "}
                        <span class="form-label-required text-danger">*</span>
                      </label>
                      <div class="input-group-prepend">
                        <span
                          class="dolr"
                          style={{ position: "relative", left: "4px" }}
                        >
                          <span className="dollr">$</span>
                        </span>
                        <input
                          style={{ paddingLeft: "20px" }}
                          type="number"
                          className={`form-control placeholder-gray ${
                            salaryEmpty ? "border-red" : ""
                          }`}
                          id="salary"
                          value={salary}
                          onChange={handleSalaryChange}
                          placeholder="75"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4 radiohour">
                      <div className="d-flex flex-wrap justify-content-start ">
                        <div className="form-check gap-2">
                          <input
                            type="radio"
                            className={`form-check-input radio-input`}
                            id="hour"
                            value="hr"
                            checked={salaryRadio === "hr"}
                            onChange={handleSalaryRadio}
                            required
                          />
                          <label
                            className={`form-check-label label-checks`}
                            htmlFor="benefitsAvailable"
                          >
                            hour
                          </label>
                        </div>
                        <div className="form-check gap-2">
                          <input
                            type="radio"
                            className="form-check-input radio-input"
                            id="year"
                            value="year"
                            checked={salaryRadio === "year"}
                            onChange={handleSalaryRadio}
                            required
                          />
                          <label
                            className="form-check-label label-checks"
                            htmlFor="benefitsNotAvailable"
                          >
                            year
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt mb-5 ">
                  <label htmlFor="description">
                    Job Description
                    <span className="form-label-required text-danger">*</span>
                  </label>
                  <div
                    className={`${descriptionEmpty ? "border-red" : ""}`}
                    style={quillStyles}
                  >
                    <ReactQuill
                      theme="snow"
                      id="description"
                      defaultValue={description}
                      onChange={handleDescriptionChange}
                      style={reactquillStyles}
                    />
                  </div>
                </div>

                <div className="post-job-item rt-mb-15 tw-w-full tw-overflow-hidden mt-4">
                  <div className="tw-flex justify-content-between">
                    <h4 className="f-size-18 ft-wt-5 rt-mb-20 lh-1">
                      Add Screening Questions
                    </h4>
                    <button
                      class="btn btn-primary w-auto pt-1 pb-1"
                      onClick={handleQuestions}
                    >
                      {!gen ? "Generate Questions" : "Generating..."}
                    </button>
                  </div>

                  {isAddingNewQuestion ? (
                    <div className="tw-flex justify-content-between mt-3">
                      <label className="tw-text-sm tw-mb-2 mb-2" htmlFor="for">
                        Create new screening question
                      </label>
                      <a href="#" onClick={toggleAddingNewQuestion}>
                        Choose from existing question
                      </a>
                    </div>
                  ) : (
                    <div className="tw-flex main-search mt-3">
                      <div className="tw-flex justify-content-between">
                        <label
                          className="tw-text-sm tw-mb-2 mb-2"
                          htmlFor="for"
                        >
                          Choose from existing question
                        </label>
                        <a href="#" onClick={toggleAddingNewQuestion}>
                          Create new screening question
                        </a>
                      </div>
                      <div className="mt-3">
                        <div onClick={handleSearchSubmit}>
                          <input
                            type="text"
                            className="form-control placeholder-gray"
                            value={searchKeyword}
                            onChange={handleSearchInputChange}
                            placeholder="Select Questions"
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            // onClick={handleDropdownClick}
                            style={{ borderRadius: "5px", width: "100%" }}
                          />
                        </div>
                        {/* Conditionally render search results */}
                      </div>
                    </div>
                  )}
                  {isAddingNewQuestion && (
                    <input
                      value={newQuestion}
                      onChange={(e) => setNewQuestion(e.target.value)}
                      className="form-control mt-3"
                      type="text"
                      placeholder="Add Question"
                    />
                  )}
                  {isAddingNewQuestion && (
                    <div className="tw-flex tw-gap-5 mb-3 flex justify-content-between tw-mt-4 mt-4">
                      <div className="tw-flex justify-between checks-text">
                        <div className="ll-radio tw-flex tw-items-center tw-border tw-border-gray-200 tw-rounded tw-ps-1 tw-mr-4">
                          <label
                            className={`mt-2 ${isRequired ? "checked" : ""}`}
                          >
                            <input
                              checked={newQuestionSave}
                              onChange={() =>
                                setNewQuestionSave(!newQuestionSave)
                              }
                              className="tw-scale-150"
                              type="checkbox"
                              style={{ marginRight: "10px" }}
                            />
                            Save for later
                          </label>
                        </div>
                      </div>
                      <div>
                        <button
                          onClick={addQuestion}
                          type="button"
                          className="postjobbtn"
                          // disabled={true}
                        >
                          {newQuestionSave ? "Save" : "Add"}
                        </button>
                      </div>
                    </div>
                  )}

                  {!isAddingNewQuestion && (
                    <div className="q-select">
                      {/* Implement your select dropdown here */}
                      {initialFocus && searchResults.length > 0 && (
                        <div className="search-results-dropdown">
                          {searchResults?.map((result, index) => (
                            <div
                              key={index}
                              className="search-result"
                              onClick={() => handleAddSearchedQuestion(result)}
                            >
                              {result}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}

                  {questions.length > 0 && (
                    <div>
                      <h4 className="f-size-18 ft-wt-5 rt-mb-20 lh-1 mt-4">
                        Selected Screening Questions
                      </h4>
                      <ul style={{ paddingLeft: "20px" }}>
                        {questions.map((question, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <li>
                              <span>
                                {question.length > 0
                                  ? question
                                  : question.required
                                  ? `${question.title} (required)`
                                  : question.title}
                              </span>
                            </li>
                            <div
                              className="cursor-pointer"
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={() => handleRemoveQuestion(index)}
                            >
                              <svg
                                width="20"
                                height="20"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </div>
                          </div>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <div>
                  <button
                    type="submit"
                    className="postjobbtn"
                    onClick={handleFormSubmit}
                  >
                    <div class="button-content-wrapper ">
                      <span style={{ marginRight: "10px" }}>Post Job</span>

                      <span class="button-icon align-icon-right">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M5 12H19"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 5L19 12L12 19"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                  </button>
                </div>
              </form>
            </div>
            <JobThankyou
              isOpen={isModalOpen}
              closeModal={closeModal}
              uniqueID={uniqueID}
            />
            <ToastContainer />
          </div>
        </div>
      </div>
      <div class="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
};

export default JobPost;
