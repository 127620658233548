import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "../styles/Sidebar.css";
import overview from "../Image/Stack.svg";
import EmployersProfile from "../Image/UserCircle.svg";
import PostJob from "../Image/PlusCircle.svg";
import Myjob from "../Image/Briefcase.svg";
import savedCandidates from "../Image/BookmarkSimple.svg";
import PlansAndBilling from "../Image/Notebook.svg";
import AllCompanys from "../Image/User List.svg";
import settings from "../Image/Gear.svg";
import signout from "../Image/SignOut.svg";

function CompanySidebar() {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeOption, setActiveOption] = useState(location.pathname);

    const handleClient = () => {
        localStorage.clear();
        navigate("/");
    };

    const handleOptionClick = (path) => {
        setActiveOption(path);
    };

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-auto col-sm-2 d-flex flex-column justify-content-between min-vh-100 w-100'>
                    <div>
                        <ul className="nav nav-pills flex-column mt-sm-0" id='parentM'>
                            <li className={`nav-item my-1 py-2 py-sm-0 custom-li-class ${activeOption === "/companySearch" ? "active" : ""} sidebar-l` }>
                                <Link to="/companySearch" className="nav-link text-white text-center text-sm-start name-img" onClick={() => handleOptionClick("/companySearch")}>
                                    <img src={overview} alt="error" className="sidebar-icon" />
                                    <span className='ms-2 d-none d-sm-inline span-t'>Home</span>
                                </Link>
                            </li>
                            <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "/Companyusers" ? "active" : ""} sidebar-l`}>
                                <Link to="/Companyusers" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/Companyusers")}>
                                    <img src={EmployersProfile} alt="error" className="sidebar-icon" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>Manage Users</span>
                                </Link>
                            </li>
                            <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "/companypool" ? "active" : ""} sidebar-l`}>
                                <Link to="/companypool" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/companypool")}>
                                    <img src={PostJob} alt="error" className="sidebar-icon" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>Resume Pool</span>
                                </Link>
                            </li>
                            <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "#" ? "active" : ""} sidebar-l`}>
                                <Link to="#" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("#")}>
                              

                                    <img src={Myjob} alt="error" className="sidebar-icon" />
                              
                                    <span className='ms-2 d-none d-sm-inline text-gray'>My Jobs</span>
                                </Link>
                            </li>
                            <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "/companyprofile" ? "active" : ""} sidebar-l`}>
                                <Link to="/companyprofile" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/companyprofile")}>
                                    <img src={PostJob} alt="error" className="sidebar-icon" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>Company Profile</span>
                                </Link>
                            </li>
                            <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "/companyadminprofile" ? "active" : ""} sidebar-l`}>
                                <Link to="/companyadminprofile" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/companyadminprofile")}>
                                    <img src={PostJob} alt="error" className="sidebar-icon" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>Profile</span>
                                </Link>
                            </li>
                            <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "/companysementicsearch" ? "active" : ""} sidebar-l`}>
                                <Link to="/companysementicsearch" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/companysementicsearch")}>
                                    <img src={PostJob} alt="error" className="sidebar-icon" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>Sementic Search</span>
                                </Link>
                            </li>
                            <li className={"nav-item my-1 py-2 py-sm-0 sidebar-l"}>
                                <Link to="/" className="nav-link text-white text-center text-sm-start" type="button" onClick={handleClient}>
                            
                                    <img src={signout} alt="error" className="sidebar-icon" />
                               
                                    <span className='ms-2 d-none d-sm-inline text-gray'>Logout</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompanySidebar;
