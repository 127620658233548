import React, { useState } from "react";
import Jobsidebar from "./Jobsidebar";
import JobSeekerNavbar from "./JobSeekerNavbar";
import styled from "styled-components";
// import GearSix from "../Image/GearSix.svg";
import "../styles/JobSeekerProfile.css";

const bcolor = styled.div`
  border: 1px solid red;
`;

function JobSekeerProfile() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [invalidFields, setInvalidFields] = useState([]);
  const email = localStorage.getItem("email");

  const [currentEmpty, setCurrentEmpty] = useState(false);
  const [newEmpty, setNewEmpty] = useState(false);
  const [confirmEmpty, setConfirmEmpty] = useState(false);

  const handlePasswordUpdate = (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setInvalidFields(["newPassword", "confirmNewPassword"]);
      setMessage("Passwords do not match");
      return;
    }

    if (currentPassword.trim() === "") {
      setCurrentEmpty(true);
    } else {
      setCurrentEmpty(false);
    }

    if (newPassword.trim() === "") {
      setNewEmpty(true);
    } else {
      setNewEmpty(false);
    }

    if (confirmNewPassword.trim() === "") {
      setConfirmEmpty(true);
    } else {
      setConfirmEmpty(false);
    }

    // If any required field is empty, prevent form submission
    if (
      currentPassword.trim() === "" ||
      newPassword.trim() === "" ||
      confirmNewPassword.trim() === ""
    ) {
      return;
    } else {
      const obj = {
        password: newPassword,
      };

      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/password/update/${email}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      })
        .then((res) => res.json())
        .then((data) => {
          setMessage(`${data.msg}`);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // setCurrentPassword('');
    // setNewPassword('');
    // setConfirmNewPassword('');
  };

  return (
    <>
      <div className="MainContainer">
        <div className="mobile-screen">
          <div style={{ height: "70px" }}>
            <JobSeekerNavbar />
          </div>
          <div
            className="col-1 col-lg-3 col-xl-3 hamburgIcon"
            style={{ marginRight: "60px" }}
          >
            <Jobsidebar />
          </div>
        </div>
        <hr />
        <div className="container second-main">
          <div className="row">
            <div className="col-1 col-lg-3 col-xl-3 sidebarDiv noclor">
              <Jobsidebar />
            </div>
            <div className="col-lg-9 col-xl-9">
              <div style={{ height: "100vh", textAlign: "center" }}>
                <div
                // style={{
                //   display: "flex",
                //   justifyContent: "center",
                //   alignItems: "center",
                // }}
                >
                  <div
                    style={{
                      textAlign: "start",
                      marginLeft: "23px",
                      marginBottom: "20px",
                    }}
                  >
                    <h3>Settings</h3>
                  </div>
                  <h6
                    style={{
                      color: "#0A65CC",
                      textAlign: "start",
                      marginLeft: "23px",
                    }}
                  >
                    {" "}
                    <span>{/* <img src={GearSix} alt="" /> */}</span> Profile
                    settings
                  </h6>

                  <form
                    className="mt-4  p-4"
                    style={{
                      borderTop: "1px solid #E4E5E8",
                      borderBottom: "1px solid #E4E5E8",
                    }}
                    // style={{ width: "400px" }}
                    onSubmit={handlePasswordUpdate}
                  >
                    {message && (
                      <div className="mt-3 alert alert-info text-center">
                        {message}
                      </div>
                    )}
                    <div className="form-group text-start label-txt">
                      <label style={{ fontWeight: "600" }}>
                        Change Password{" "}
                        <span class="form-label-required text-danger">*</span>
                      </label>
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="city">Current Password</label>
                          <input
                            type="text"
                            className={`form-control placeholder-gray ${
                              currentEmpty ? "border-red" : ""
                            }`}
                            id="city"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            placeholder="password"
                            // required
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="state">New Password</label>
                          <input
                            type="text"
                            className={`form-control placeholder-gray ${
                              newEmpty ? "border-red" : ""
                            }`}
                            id="city"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="password"
                            // required
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="zipCode">Confirm New Password</label>
                          <input
                            type="text"
                            className={`form-control placeholder-gray ${
                              confirmEmpty ? "border-red" : ""
                            }`}
                            id="zipCode"
                            value={confirmNewPassword}
                            onChange={(e) =>
                              setConfirmNewPassword(e.target.value)
                            }
                            placeholder="password"
                            // required
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary w-auto"
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        borderRadius: "4px",
                      }}
                    >
                      Save Changes
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2024 Neverhunt All rights reserved.
        </div>
      </div>
    </>
  );
}

export default JobSekeerProfile;
