import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import instagram from "../Image/Employers.png";
import briefcase1 from "../Image/briefcaselogo.svg";
import "../styles/Dashboard.css";
import Sidebar from "./Sidebar";
import icon from "../Image/Icon.svg";
import IdentificationCard from "../Image/personidentity.svg";

// import "../styles/Applicants.css";
import check from "../Image/CheckCircle.svg";
import Dots from "../Image/DotsThreeVertical.svg";
import users from "../Image/Users.png";
import Archive from "../Image/Archive.svg";
import fi_edit from "../Image/fi_edit.svg";
import EmployersProfile from "../Image/UserCircle.svg";
import fi_arrow from "../Image/fi_arrow-right.svg";
import fi_copy from "../Image/fi_copy.svg";
import tick_icons from "../Image/tick-icons8.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";

function ClientReq() {
  const [activeJobs, setActiveJobs] = useState(0);
  const [Jobs, setJobs] = useState([]);
  const [jobsWithNoResponse, setJobsWithNoResponse] = useState(0);
  const [unreadResponses, setUnreadResponses] = useState(0);
  const [openOverlays, setOpenOverlays] = useState([]);
  const [singleJobUnread, setSingleJobUnread] = useState([]);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
  const [copyClick, setCopyClick] = useState([]);
  const [assignedJobs, setAssignedJobs] = useState([]);

  const [fname, setFname] = useState(null);
  const navigate = useNavigate();

  // Function to toggle active dropdown index
  const toggleDropdown = (rowIndex) => {
    setActiveDropdownIndex(activeDropdownIndex === rowIndex ? null : rowIndex);
  };

  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");

  useEffect(() => {
    if (!token) {
      navigate(`/`);
    }
  }, []);

  useEffect(()=>{
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/token-expire?email=${email}`, {
     method: 'GET',
     headers: {
       "Authorization": token
     }
    })
    .then((res)=> res.json())
    .then((data)=>{
      console.log("data token",data.msg);
     if(data.msg === "Invalid token. Please login"){
      //  setIsToeknValid(false)
       localStorage.removeItem("token");
       navigate(`/`);

     }else {
      //  setIsToeknValid(true)
     }
   
    })
    .catch((err)=>{
     console.log(err);
    })
 }, [token])

  //${process.env.REACT_APP_BACKEND_BASE_URL}/clients/activeJobs
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/Company/getreq`, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        
        setActiveJobs(data.msg.length);
        setJobs(data.msg);
        setSingleJobUnread(data.unread);
      })
      .catch((err) => {
          console.log(err.message);
      });
  }, []);
//${process.env.REACT_APP_BACKEND_BASE_URL}/clients/unreadResponses

  useEffect(()=>{
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/fname`, {
      method:"POST",
      headers:{
        "authorization": token
      },
      body:JSON.stringify({
        email: email
      })
    }).then((res)=> res.json())
    .then((data)=>{
      setFname(data.msg.firstName)
    })
  }, [])

  const handleAssignToMe = (jobId, rowData) => {
    console.log("elementid: " + rowData.CuserID)
    // Check if the job is already assigned
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/Company/updateReq/${jobId}`, {
      method: 'POST',
      headers:{
        "content-type": "application/json",
        "Authorization": token
      },
      body: JSON.stringify({
        name:fname
      })
    })
    .then((res)=> res.json())
    .then((data)=>{
        console.log("Received", data.msg);
        // window.location.reload();
    })
    .catch((err)=>{
        console.log(err.message);
    })

    let obj = {
      position: rowData.position,
      jobDescription: rowData.jobDescription,
      companyName: rowData.companyName,
      jobType: rowData.jobType,
      jobLocType: rowData.jobLocType,
      salary: rowData.salary,
      city: rowData.city,
      state : rowData.state,
      zip: rowData.zipCode,
      ClientID: rowData.CuserID,
      CuniqueID: rowData.uniqueID,
      benefits:"Optional",
      status: "Posted",
    };

  // `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/postjob`
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/postjob`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(obj),
      })
        .then((res) => res.json())
        .then((data) => {
            console.log("data", data.msg);
          if (data.msg === "Job posted successfully") {
          //  setIsModalOpen(true);
            const uniqueID = data.uniqueID;
            navigate(`/dashboard`);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };
  

  function formatDate(isoDate) {
    const dateObject = new Date(isoDate);
    const day = dateObject.getUTCDate();
    const month = dateObject.getUTCMonth() + 1; // Months are zero-based
    const year = dateObject.getUTCFullYear();
    return `${month}-${day}-${year}`;
  }

  return (
    <div className="MainContainer">
      <div style={{ height: "60px" }}>
        <Navbar />
      </div>
      <hr />
      <div className="container second-main">
        <div className="row">
          <div className="col-1 col-lg-3 sidebarDiv">
            <Sidebar />
          </div>

          <div className="col-lg-9">
            <div className="dashboard-right">
              <div className="dashboard-right-header">
                <div className="left-text">
                  <h5>Hello, {fname}</h5>
                  <p class="m-0">
                    Here are your daily activities
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-5 col-lg-6 col-md-6">
                  <div className="single-feature-box">
                    <div className="single-feature-data">
                      <h6 class="tw-text-[#18191C] tw-text-2xl tw-font-semibold">
                        {activeJobs}
                      </h6>
                      <p>Open Positions</p>
                    </div>
                    <div className="single-feature-icon">
                      <svg
                        width="64"
                        height="64"
                        viewBox="0 0 64 64"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="64" height="64" rx="5" fill="white" />
                        <g clip-path="url(#clip0_1_1554)">
                          <path
                            opacity="0.2"
                            d="M32 35.0001C27.7872 35.0067 23.6476 33.8992 20.001 31.7899V42.0001C20.001 42.1314 20.0268 42.2614 20.0771 42.3828C20.1273 42.5041 20.201 42.6143 20.2939 42.7072C20.3867 42.8001 20.497 42.8737 20.6183 42.924C20.7396 42.9742 20.8697 43.0001 21.001 43.0001H43.001C43.1323 43.0001 43.2623 42.9742 43.3837 42.924C43.505 42.8737 43.6152 42.8001 43.7081 42.7072C43.8009 42.6143 43.8746 42.5041 43.9249 42.3828C43.9751 42.2614 44.001 42.1314 44.001 42.0001V31.7887C40.3539 33.8988 36.2135 35.0067 32 35.0001Z"
                            fill="#0A65CC"
                          />
                          <path
                            d="M43.001 25H21.001C20.4487 25 20.001 25.4478 20.001 26V42C20.001 42.5523 20.4487 43 21.001 43H43.001C43.5533 43 44.001 42.5523 44.001 42V26C44.001 25.4478 43.5533 25 43.001 25Z"
                            stroke="#0A65CC"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M37 25V23C37 22.4696 36.7893 21.9609 36.4142 21.5858C36.0391 21.2107 35.5304 21 35 21H29C28.4696 21 27.9609 21.2107 27.5858 21.5858C27.2107 21.9609 27 22.4696 27 23V25"
                            stroke="#0A65CC"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M44.001 31.7888C40.3539 33.8988 36.2135 35.0068 32 35.0001C27.7872 35.0068 23.6475 33.8992 20.0008 31.7898"
                            stroke="#0A65CC"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M30.5 31H33.5"
                            stroke="#0A65CC"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_1554">
                            <rect
                              width="32"
                              height="32"
                              fill="white"
                              transform="translate(16 16)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-5 col-lg-6 col-md-6">
                  <div className="single-feature-box">
                    <div className="single-feature-data">
                      <h6 class="tw-text-[#18191C] tw-text-2xl tw-font-semibold">
                        {unreadResponses}
                      </h6>
                      <p>Unread Responses</p>
                    </div>
                    <div className="single-feature-icon">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19 14H24"
                          stroke="#FFA500"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19 18H24"
                          stroke="#FFA500"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.5126 18C13.1694 18 14.5126 16.6569 14.5126 15C14.5126 13.3431 13.1694 12 11.5126 12C9.85572 12 8.51257 13.3431 8.51257 15C8.51257 16.6569 9.85572 18 11.5126 18Z"
                          stroke="#FFA500"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.63861 20.9999C7.86055 20.1413 8.36147 19.3807 9.06266 18.8377C9.76385 18.2947 10.6256 18 11.5124 18C12.3993 18 13.261 18.2946 13.9623 18.8375C14.6635 19.3805 15.1645 20.141 15.3865 20.9997"
                          stroke="#FFA500"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M27 6.00001H5C4.44772 6.00001 4 6.44772 4 7.00001V25C4 25.5523 4.44772 26 5 26H27C27.5523 26 28 25.5523 28 25V7.00001C28 6.44772 27.5523 6.00001 27 6.00001Z"
                          stroke="#FFA500"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div> */}
                <div className="col-xl-4 col-lg-6 col-md-6"></div>
              </div>
              <div class="recently-applied-wrap d-flex justify-content-between align-items-center rt-mb-15">
                <h3 class="f-size-16">Recent Jobs</h3>
                <a
                  class="view-all text-gray-500 f-size-16 d-flex align-items-center"
                  href="/myjobs"
                >
                  View All
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="fi:arrow-right">
                      <path
                        id="Vector"
                        d="M5 12H19"
                        stroke="#767F8C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        id="Vector_2"
                        d="M12 5L19 12L12 19"
                        stroke="#767F8C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                </a>
              </div>
              <div className="db-job-card-table">
                <table>
                  <thead>
                    <tr>
                      <th>Position name</th>
                      <th>Company name</th>
                      <th>Creator</th>
                      <th>Position Status</th>
                      <th>Created on</th>
                      <th>Assign</th>
                      <th>Assigned to</th>
                      <th>Submit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(Jobs) && Jobs.length > 0 && Jobs.map((el, rowIndex) => (
                      <tr key={el.RuserID}>
                        <td>
                          <div class="iconbox-content">
                            <div class="post-info2">
                              <div class="post-main-title">
                                <a
                                  href="#"
                                  class="text-gray-900 f-size-16  ft-wt-5"
                                >
                                  {el.position}
                                </a>
                              </div>
                              <div class="body-font-4 text-gray-600 pt-2">
                                <span class="info-tools rt-mr-8">
                                  {/* {el.positionType[0]} */}
                                </span>
                                <span class="info-tools">{el.city} {el.state}</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="iconbox-content">
                            <div class="post-info2">
                              <div class="post-main-title">
                                <a
                                  href="#"
                                  class="text-gray-900 f-size-16  ft-wt-5"
                                >
                                  {el.companyName}
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="iconbox-content">
                            <div class="post-info2">
                              <div class="post-main-title">
                                <a
                                  href="#"
                                  class="text-gray-900 f-size-16  ft-wt-5"
                                >
                                  {el.creator}
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="text-success-500 ft-wt-5 d-flex align-items-center">
                            <i class="ph-check-circle f-size-18 mt-1 rt-mr-4">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="CheckCircle">
                                  <path
                                    id="Vector"
                                    d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                                    stroke="#0BA02C"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    id="Vector_2"
                                    d="M13.4375 8.125L8.85414 12.5L6.5625 10.3125"
                                    stroke="#0BA02C"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                              </svg>
                            </i>
                            Active
                          </div>
                        </td>
                        <td>
                          <div class="iconbox-content">
                            <div class="post-info2">
                              <div class="post-main-title">
                                <a
                                  href="#"
                                  class="text-gray-900 f-size-16  ft-wt-5"
                                >
                                  {formatDate(el.createdAt)}
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="iconbox-content">
                            <div class="post-info2">
                              <div class="post-main-title">
                                <a
                                   href="#"
                                   className="text-gray-900 f-size-16 ft-wt-5"
                                 >
                                   {el.isAssigned ? (
                                     <button disabled>Assigned</button>
                                   ) : (
                                     <button onClick={() => handleAssignToMe(el.uniqueID, el)}>
                                       Assign to me
                                     </button>
                                   )}
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="iconbox-content">
                            <div class="post-info2">
                              <div class="post-main-title">
                                <a
                                  href="#"
                                  class="text-gray-900 f-size-16  ft-wt-5"
                                >
                                 {
                                    el.isAssigned ? el.AssignedTo : ""
                                 }
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="iconbox-content">
                            <div class="post-info2">
                              <div class="post-main-title">
                                <a
                                  href="#"
                                  class="text-gray-900 f-size-16  ft-wt-5"
                                >
                                  <button>Submit</button>
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dashboard-footer text-center body-font-4 text-gray-500 copyFooter">
      Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
}

export default ClientReq;
