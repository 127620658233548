import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'animate.css';
import ClientNavbar from './ClientNavbar';
import CompanySidebar from './CompanySidebar';
import Navbar from './Navbar';

function Companyusers() {
  const baseUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}`;
  const token = localStorage.getItem('token');

  const [recruitermail, setRecruitermail] = useState("");
  const [message, setMessage] = useState("");
  const [companyid, setCompanyid] = useState("");
  const [recruiters, setRecruiters] = useState([]);
  const [editingRecruiter, setEditingRecruiter] = useState(null);

  const handleClick = () => {
    setRecruitermail("");
    const obj = {
      email: recruitermail,
      password: generatePassword(),
      role: "recruiter",
      companyid: companyid,
      isApproved: true,
    };
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/companyAdd`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(obj)
    })
      .then((res) => res.json())
      .then((data) => {
        setMessage(data.msg);
        console.log(data.msg);
        fetchRecruiters();
      }).catch((error) => {
        setMessage("An error occurred. Please try again.");
        console.error(error);
      });
  };

  const fetchRecruiters = () => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/company/getallrecruiters`, {
      method: 'GET',
      headers: {
        "content-type": "application/json",
        Authorization: token
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setRecruiters(data.msg);
      })
      .catch((err) => {
        console.log("Error fetching recruiters:", err.message);
      });
  };

  useEffect(() => {
    fetchRecruiters();
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/company/getid`, {
      method: 'GET',
      headers: {
        "content-type": "application/json",
        Authorization: token
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCompanyid(data.msg);
      })
      .catch((err) => {
        console.log("comp id check", err.message);
      });
  }, []);

  const handlemail = (e) => {
    setRecruitermail(e.target.value);
  };

  const generatePassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    let newPassword = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      newPassword += charset.charAt(Math.floor(Math.random() * n));
    }
    return newPassword;
  };

  const handleEdit = (recruiter) => {
    setEditingRecruiter(recruiter);
    setRecruitermail(recruiter.email);
  };

  const handleDelete = (recruiterId) => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/company/delete/${recruiterId}`, {
      method: 'DELETE',
      headers: {
        "content-type": "application/json",
        Authorization: token
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setMessage(data.msg);
        fetchRecruiters();
      })
      .catch((err) => {
        setMessage("An error occurred. Please try again.");
        console.error(err.message);
      });
  };

  const handleUpdate = () => {
    const obj = {
      email: recruitermail,
      password: generatePassword(), // Optional: You can keep the old password
      role: "recruiter",
      companyid: companyid,
      isApproved: true,
    };
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/company/update/${editingRecruiter._id}`, {
      method: 'PUT',
      headers: {
        "content-type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(obj)
    })
      .then((res) => res.json())
      .then((data) => {
        setMessage(data.msg);
        fetchRecruiters();
        setEditingRecruiter(null);
        setRecruitermail("");
      })
      .catch((err) => {
        setMessage("An error occurred. Please try again.");
        console.error(err.message);
      });
  };

  return (
    <div className="MainContainer">
      <div style={{ height: "70px" }}>
        <Navbar />
      </div>

      <hr />
      <div className="container second-main">
        <div className="row">
          <div className="col-1 col-lg-3 sidebarDiv">
            <CompanySidebar />
          </div>
          <div className="col-11 col-lg-9 animate__animated animate__fadeIn">
            <h2 className="text-center my-4">Manage Users</h2>
            {message && (
              <div className="alert alert-info" role="alert" style={{ maxWidth: '400px', margin: '0 auto' }}>
                {message}
              </div>
            )}
            <div className="d-flex justify-content-center align-items-center my-4">
              <input
                type="text"
                className="form-control"
                placeholder="Enter recruiter mail"
                aria-label="User name"
                aria-describedby="button-add"
                style={{ maxWidth: '300px', marginRight: '10px' }}
                onChange={handlemail}
                value={recruitermail}
              />
              <button
                className="btn btn-primary"
                style={{ backgroundColor: '#007bff', borderColor: '#007bff', width: '100px' }}
                type="button"
                id={editingRecruiter ? "button-update" : "button-add"}
                onClick={editingRecruiter ? handleUpdate : handleClick}
              >
                {editingRecruiter ? "Update" : "Add"}
              </button>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Is Approved</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {recruiters.map((recruiter, index) => (
                    <tr key={index}>
                      <td>{recruiter.email}</td>
                      <td>{recruiter.role}</td>
                      <td>{recruiter.isApproved ? 'Yes' : 'No'}</td>
                      <td>
                        <button className="btn btn-secondary mr-2" onClick={() => handleEdit(recruiter)}>Edit</button>
                        <button className="btn btn-danger" onClick={() => handleDelete(recruiter._id)}>Delete</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
}

export default Companyusers;
