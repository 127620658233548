import React from "react";
import { Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import Task from "./Task";
// import "../styles/Scroll.css"
import ClientTask from "./ClientTask";

const Container = styled.div`
  background-color: #f4f5f7;
  border-radius: 8px;
  width: 300px;
  min-width: 312px;
  height: 675px;
  
  margin-right: 8px;
  margin-left: 20px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border: 1px solid rgb(216, 215, 215);
`;

const Title = styled.h4`
  padding: 18px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  font-family:sans-serif;
  line-height: 20px;
  color: #18191C;
  margin-bottom: 0;


`;

const TaskList = styled.div`
  padding: 3px;
  transition: background-color 0.2s ease;
  background-color: #f4f5f7;
  flex-grow: 1;
  min-height: 100px;
  overflow-x: auto;

`;

function ClientColumn({title, tasks, id}) {
   console.log("column tasks: " + tasks)
  return (
    <Container className="column">
      <Title
        style={{
          position: "stick",
        }}
      >
        {title} ({tasks.length})
      </Title>
      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <TaskList
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {tasks.map((task, index) => (
              <ClientTask key={index} index={index} task={task}/>
            ))}
            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    </Container>
  );
}

export default ClientColumn;
