import React, { useEffect, useState } from "react";
import styled from "styled-components";
import profileImage from "../Image/ProfileImage.svg";
import fi_download from "../Image/fi_download-cloud.svg";
import playCircle from "../Image/PlayCircle.svg";
import phoneCall from "../Image/phone-call-duotone 1.svg";
import linkedIn from "../Image/linkedin-icon-1 1.svg";
import Envelope from "../Image/Envelope.svg";
import pause_icon from "../Image/pause_icon.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";

// import "../styles/ProfileModal.css";

const ModalWrapper = styled.div`
  /* Styles for the modal background overlay */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  /* Styles for the modal content */
  background-color: #fff;
  padding: 3% 5%;
  border-radius: 5px;
  width: 70vw;
  max-width: 1000px;
  height: 90vh;
  position: relative;

  @media (max-width: 1400px) {
    /* Adjust for smaller screens */
    width: 90%;
    padding: 3% 5%;
  }
`;

const CloseButton = styled.span`
  position: absolute;
  top: -10px;
  right: -45px;
  cursor: pointer;
  font-size: 28px;
  width: 30px;
  height: 30px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  padding: 0px 10px 5px 10px;
`;

const ScrollableContainer = styled.div`
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto;
  margin-top: 10px; /* Add margin for spacing */
`;

const SkillKeywordsContainer = styled.div`
  margin-bottom: 10px;
`;

const SkillKeywordsLabel = styled.span`
  font-weight: bold;
`;

const SkillKeywordsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
`;

const SkillKeywordItem = styled.li`
  background-color: #e7f0fa;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
`;

function ClientProfileModal({ isOpen, closeModal, userData, dragid }) {
  const [activeTab, setActiveTab] = useState("Profile");

  const renderAboutTab = () => (
    <div style={{ maxHeight: "73vh", overflowY: "auto" }}>
      {/* Display formatted resume content here */}
      <h6>Resume Overview</h6>
      {/* Add formatting for the resume content */}
      <pre>
        {userData.resumeData.split("\n").map((line, index) => (
          <p key={index}>
            {line.startsWith("Skills") ? (
              <strong>{line}</strong>
            ) : line.startsWith("Education") ? (
              <strong>{line}</strong>
            ) : line.startsWith("Work Experience") ? (
              <strong>{line}</strong>
            ) : (
              line
            )}
          </p>
        ))}
      </pre>
    </div>
  );

  const extractAnalysisAndScore = (task) => {
    try {
      // Check if 'matching' array is present in the task
      if (!task.matching || task.matching.length === 0) {
        throw new Error("Matching array not found or empty");
      }

      // Extracting the first element from the array
      const matchingItem = task.matching[0];
      const jsonPart = matchingItem.split("{").slice(1).join("{");

      // Parsing the JSON
      const jsonResponse = JSON.parse(`{${jsonPart}`);

      const skillkeywords =
        jsonResponse["Skill Keywords"] || "No Skill Keywords available";

      const reasoning = jsonResponse["Reasoning"] || "No Reasoning available";

      // Extracting the "Match Score"
      const matchScore = jsonResponse["Match Score"] || "N/A";

      return { skillkeywords, matchScore, reasoning };
    } catch (error) {
      console.error("Error extracting analysis and score:", error);
      // Return a default value or handle the error gracefully
      return {
        skillkeywords: "No Skill Keywords available",
        matchScore: "N/A",
      };
    }
  };

  const { skillkeywords, matchScore, reasoning } =
    extractAnalysisAndScore(userData);

  const skillkeywordsArr = Array.isArray(skillkeywords)
    ? skillkeywords
    : skillkeywords.split(",").map((keyword) => keyword.trim());

  console.log("tgtguserData: ", userData);
  const token = localStorage.getItem("token");
  const locemail = JSON.parse(localStorage.getItem("email"));
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [status, setStatus] = useState("");
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [filenamee, setFilenamee] = useState(null);
  const [audioElement, setAudioElement] = useState(null);
  const [userID, setUserId] = useState(null);
  const [email, setEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/user/token-expire?email=${locemail}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("data token", data.msg);
        if (data.msg === "Invalid token. Please login") {
          //  setIsToeknValid(false)
          localStorage.removeItem("token");
          navigate(`/`);
        } else {
          //  setIsToeknValid(true)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  //for questions
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/onejob/${userData.jobUniqueID}/${userData.jobUniqueID}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setQuestions(data.msg.addNewQuestion);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/ClientSeeker/changeViewStatus`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          id: userData._id,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data.msg);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  //for answers
  //${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/onejob/answers/${userData.userID}/${userData.jobUniqueID}
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/onejob/answers/${userData.userID}/${userData.jobUniqueID}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("ansnew", data.msg.AddNewQstn)
        setAnswers(data.msg.AddNewQstn);
        // setAnswers(data.msg.AddNewQstn[0].split(","));
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const downloadResume = () => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/Images/${userData.resume}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = userData.resume; // Set the file name
        document.body.appendChild(a);

        a.click();

        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading resume:", error);
      });
  };

  const handleStatus = (e) => {
    if (e.target.value === "shortlisted") {
      fetch(
        `${
          process.env.REACT_APP_BACKEND_BASE_URL
        }/clients/onejobapplications/${2}/${userData._id}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            candidateStatus: "shortlisted",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(`Item updated to  in the database`, data.msg);
        })
        .catch((error) => {
          console.error("Error updating item status in the database:", error);
        });
    } else if (e.target.value === "interviewed") {
      fetch(
        `${
          process.env.REACT_APP_BACKEND_BASE_URL
        }/clients/onejobapplications/${3}/${userData._id}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            candidateStatus: "interviewed",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(`Item updated to  in the database`, data.msg);
        })
        .catch((error) => {
          console.error("Error updating item status in the database:", error);
        });
    } else if (e.target.value === "hired") {
      fetch(
        `${
          process.env.REACT_APP_BACKEND_BASE_URL
        }/clients/onejobapplications/${4}/${userData._id}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            candidateStatus: "shortlisted",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(`Item updated to  in the database`, data.msg);
        })
        .catch((error) => {
          console.error("Error updating item status in the database:", error);
        });
    } else {
      fetch(
        `${
          process.env.REACT_APP_BACKEND_BASE_URL
        }/clients/onejobapplications/${5}/${userData._id}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            candidateStatus: "shortlisted",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(`Item updated to  in the database`, data.msg);
        })
        .catch((error) => {
          console.error("Error updating item status in the database:", error);
        });
    }
  };

  if (!isOpen) return null;

  return (
    <ModalWrapper>
      <ModalContent>
        <CloseButton onClick={closeModal}>&times;</CloseButton>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <div
            onClick={() => setActiveTab("Profile")}
            style={{
              cursor: "pointer",
              padding: "10px",
              borderBottom: activeTab === "Profile" ? "2px solid #000" : "none",
            }}
          >
            Profile
          </div>
          {/* <div
            onClick={() => setActiveTab("About")}
            style={{
              cursor: "pointer",
              padding: "10px",
              borderBottom: activeTab === "About" ? "2px solid #000" : "none",
            }}
          >
            About
          </div> */}
        </div>
        {activeTab === "Profile" && (
            <div>
           
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <div>
              <img
                src={profileImage}
                alt="profileImage.svg"
                // style={{ marginLeft: "-30px", paddingRight: "12px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "left",
                paddingTop: "15px",
              }}
            >
              <span style={{ fontWeight: "600" }}>{firstName}</span>

              <p style={{ fontSize: "14px" }}>
                {userData.city}, {userData.state}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <select onChange={handleStatus} className="statusSelect">
                <option value="">All Applications</option>
                <option value="shortlisted">Shortlisted</option>
                <option value="interviewed">InterViewed</option>
                <option value="hired">Hired</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
          </div>
        </div>
        <hr style={{ marginTop: "-1px", marginLeft: "-30px" }} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "12px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ul>
              <li style={{ marginBottom: "5px", marginLeft: "-7px" }}>
                <b>Match Score</b> : {matchScore}
              </li>
              <li
                style={{
                  marginBottom: "5px",
                  marginLeft: "-7px",
                  width: "90%",
                }}
              >
                <b>Reasoning</b> : {reasoning}
              </li>
              <li style={{ marginBottom: "5px", marginLeft: "-7px" }}>
                <SkillKeywordsLabel>Skill Keywords: </SkillKeywordsLabel>
                <SkillKeywordsList>
                  {skillkeywordsArr?.map((keyword, index) => (
                    <SkillKeywordItem key={index}>{keyword}</SkillKeywordItem>
                  ))}
                </SkillKeywordsList>
              </li>
              <li style={{ marginBottom: "5px", marginLeft: "-7px" }}>
                <b>Avaliable to start</b> : {userData.Availability}
              </li>
              <li style={{ marginBottom: "5px", marginLeft: "-7px" }}>
                <b>Interview Avaliablity</b> : {userData.interviewAvailability}
              </li>
              <li style={{ marginBottom: "5px", marginLeft: "-7px" }}>
                <b>Sponsorship required</b> : {userData.sponsorship}
              </li>
            </ul>
            <div style={{ paddingLeft: "25px" }}>
              {userData.socialProfiles.length > 0 &&
                userData.socialProfiles.map((urlObj, index) => {
                  try {
                    if (urlObj.url !== "") {
                      const parsedUrl = new URL(urlObj.url);
                      const domain = parsedUrl.hostname.replace("www.", "");

                      const truncatedDomain = domain;
                      const visibleText = `${truncatedDomain}/${firstName}`;
                      return (
                        <div key={index} style={{ marginBottom: "7px" }}>
                          <a href={urlObj.url} target="_blank">
                            {visibleText}
                          </a>
                          <br />
                        </div>
                      );
                    } else {
                      return (
                        <div key={index}>
                          <a href={urlObj.url} target="_blank"></a>
                          <br />
                        </div>
                      );
                    }
                  } catch (error) {
                    console.error(`Error parsing URL: ${urlObj.url}`, error);
                    return null;
                  }
                })}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                }}
                onClick={downloadResume}
              >
                <img src={fi_download} width="25px" alt="" />
                <p style={{ marginTop: "14px" }}>Download Resume</p>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <h6>Screening Response</h6>
        <ScrollableContainer>
          <div
            style={{ backgroundColor: "#F1F2F4", padding: "4px 0px 4px 13px" }}
          >
            {questions && questions.length > 0 ? (
              questions.map((question, index) => (
                <div key={index}>
                  <p style={{ color: "black" }}>{question.title}</p>
                  <p style={{ color: "#5E6670" }}>
                    {typeof answers[index] === "object"
                      ? JSON.stringify(answers[index]) // Convert objects to strings
                      : answers[index]}
                  </p>
                </div>
              ))
            ) : (
              <p>No Questions added for this role!</p>
            )}
          </div>
          
        </ScrollableContainer>
        </div>
          )}
           {activeTab === "About" && renderAboutTab()}
      </ModalContent>
    </ModalWrapper>
  );
}

export default ClientProfileModal;
