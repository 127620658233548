import React, { useEffect, useState } from "react";
import styled from "styled-components";
import profileImage from "../Image/ProfileImage.svg";
import fi_download from "../Image/fi_download-cloud.svg";
import playCircle from "../Image/PlayCircle.svg";
import phoneCall from "../Image/phone-call-duotone 1.svg";
import linkedIn from "../Image/linkedin-icon-1 1.svg";
import Envelope from "../Image/Envelope.svg";
import pause_icon from "../Image/pause_icon.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import url from "./url"
import url from "url";
import { useNavigate } from "react-router-dom";

import "../styles/ProfileModal.css";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  overflow-y: auto; /* Allow vertical scrolling for the entire modal */
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 3% 5%;
  border-radius: 5px;
  width: 70vw;
  max-width: 1000px;
  height: 90vh;
  position: relative;

  @media (max-width: 1400px) {
    width: 90%;
    padding: 3% 5%;
  }
`;

const ScrollableProfileTab = styled.div`
  max-height: calc(90vh - 200px); /* Set a maximum height for the Profile tab */
  overflow-y: auto; /* Allow vertical scrolling if content exceeds the maximum height */
  /* Hide scrollbar for Chrome, Safari, and newer Edge */
  &::-webkit-scrollbar {
    width: 0; /* Set the width to 0 to hide the scrollbar */
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  /* Hide scrollbar for IE 11 and older Edge */
  -ms-overflow-style: none;
`;

const CloseButton = styled.span`
  position: absolute;
  top: -10px;
  right: -45px;
  cursor: pointer;
  font-size: 28px;
  width: 30px;
  height: 30px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  padding: 0px 10px 5px 10px;
`;

const ScrollableContainer = styled.div`
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto;
  margin-top: 10px; /* Add margin for spacing */
`;

const SkillKeywordsContainer = styled.div`
  margin-bottom: 10px;
`;

const SkillKeywordsLabel = styled.span`
  font-weight: bold;
`;

const SkillKeywordsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
`;

const SkillKeywordItem = styled.li`
  background-color: #e7f0fa;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
`;

//rgba(0, 0, 0, 0.5)

function ProfileModal({ isOpen, closeModal, userData, dragid }) {
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [activeTab, setActiveTab] = useState("Profile");
  const [submitting, setSubmitting] = useState(false);

  const renderAboutTab = () => (
    <div style={{ maxHeight: "73vh", overflowY: "auto" }}>
      {/* Display formatted resume content here */}
      <h6>Resume Overview</h6>
      {/* Add formatting for the resume content */}
      <pre>
        {userData.resumeData.split("\n").map((line, index) => (
          <p key={index}>
            {line.startsWith("Skills") ? (
              <strong>{line}</strong>
            ) : line.startsWith("Education") ? (
              <strong>{line}</strong>
            ) : line.startsWith("Work Experience") ? (
              <strong>{line}</strong>
            ) : (
              line
            )}
          </p>
        ))}
      </pre>
    </div>
  );

  const extractAnalysisAndScore = (task) => {
    try {
      console.log("ext", task)
      // Check if 'matching' array is present in the task
      if (!task.matching || task.matching.length === 0) {
        throw new Error("Matching array not found or empty");
      }
      console.log("after")

      // Extracting the first element from the array
      const matchingItem = task.matching[0];
      const startIndex = matchingItem.indexOf('{');
      const endIndex = matchingItem.lastIndexOf('}');
      // Extract the JSON substring
      const jsonPart = matchingItem.substring(startIndex, endIndex + 1);
        console.log("checking json part", jsonPart)
      // Parsing the JSON
      const jsonResponse = JSON.stringify(jsonPart);
      const cleanedJsonString = jsonPart.replace(/\\_/g, "_")
      let parsedJson = JSON.parse(cleanedJsonString);
      console.log("parsedJson", parsedJson);
      console.log("type checking",typeof parsedJson);
      console.log("checking fieldnames", parsedJson["industries"])
      const skillkeywords =
      parsedJson["Skill Keywords"] || parsedJson["skill\_keywords"] || parsedJson["Skills"] || "No Skill Keywords available";

      const reasoning = parsedJson["Reasoning"] || parsedJson["reasoning"] || "No Reasoning available";

      // Extracting the "Match Score"
      // const matchScore = jsonResponse["Match Score"] || "N/A";
      //matchScore
      console.log("match", task.matchScore)
      const matchScore = task.matchScore || parsedJson["score"] || 0;


      return { skillkeywords, matchScore, reasoning };
    } catch (error) {
      console.error("Error extracting analysis and score:", error);
      // Return a default value or handle the error gracefully
      return {
        skillkeywords: "No Skill Keywords available",
        matchScore: "N/A",
      };
    }
  };

  const { skillkeywords, matchScore, reasoning } =
    extractAnalysisAndScore(userData);

    console.log("overall analysis", skillkeywords,"match", matchScore,"reass..", reasoning);

  const skillkeywordsArr = Array.isArray(skillkeywords)
    ? skillkeywords
    : skillkeywords.split(",").map((keyword) => keyword.trim());

  console.log("userData: ", userData);

  const token = localStorage.getItem("token");
  const locemail = JSON.parse(localStorage.getItem("email"));
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [status, setStatus] = useState("");
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [filenamee, setFilenamee] = useState(null);
  const [audioElement, setAudioElement] = useState(null);
  const [userID, setUserId] = useState(null);
  const [email, setEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [message, setMessage] = useState(null);
  const [uri, setUri] = useState(null);
  const [submitstatus, setSubmitStatus] = useState(false);
  const navigate = useNavigate();
  const [viewStatus, setViewstatus]  = useState(true);
  const [pdfurl, setPdfurl] = useState(null);


  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/user/token-expire?email=${locemail}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("data token", data.msg);
        if (data.msg === "Invalid token. Please login") {
          //  setIsToeknValid(false)
          localStorage.removeItem("token");
          navigate(`/`);
        } else {
          //  setIsToeknValid(true)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/clients/emailProfile/${userData.userID}`,
      {
        method: "GET",
        headers: {
          authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setEmail(data.msg.email);
        setFirstName(data.msg.firstName);
      })
      .catch((err) => {
        console.log(err.message);
      });
  });
  //for questions
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/onejob/${userData.jobUniqueID}/${userData.jobUniqueID}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setQuestions(data.msg.addNewQuestion);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  //for answers
  //${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/onejob/answers/${userData.userID}/${userData.jobUniqueID}
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/onejob/answers/${userData.userID}/${userData.jobUniqueID}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("ansnew", data.msg.AddNewQstn)
        setAnswers(data.msg.AddNewQstn);
        // setAnswers(data.msg.AddNewQstn[0].split(","));
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

useEffect(()=>{
     fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/jobpostsource/${userData.jobUniqueID}`)
     .then((res)=> res.json())
     .then((data)=>{
      if(data.msg == "true"){
        setSubmitStatus(true)
      }else{
        setSubmitStatus(false)
      }
     })
}, [])

  //to check status of submit to client
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/clients/updatesubmitstatus/${userData.jobUniqueID}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setSubmitting(data.msg.isSubmittedToClient);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handlePlay = (jobid, userid) => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/getAudioFilename/${userid}/${jobid}`
    )
      .then((res) => res.json())
      .then((data) => {
        setFilenamee(data.filename);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleSubmitClient = (e) => {
    e.preventDefault();

    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/clients/updatesubmitstatus`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          id: userData.jobUniqueID,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data.msg);
      })
      .catch((err) => {
        console.log(err.message);
      });

    const {
      Availability,
      candidateStatus,
      RuserID,
      city,
      date,
      interviewAvailability,
      jobUniqueID,
      matching,
      resume,
      socialProfiles,
      sponsorship,
      state,
      userID,
      zip,
      resumeData,
    } = userData;
    console.log("Availability", jobUniqueID);
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getCid/${jobUniqueID}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("data of cid", data.msg);
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/ClientSeeker/add`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            city,
            state,
            zip,
            sponsorship,
            interviewAvailability,
            Availability,
            socialProfiles,
            matching,
            resume,
            jobUniqueID,
            userID,
            RuserID,
            CuserID: data.msg.CuserID,
            ClientID: data.msg.ClientID,
            CuniqueID: data.msg.CuniqueID,
            resumeData,
            additionalNotes,
            candidateStatus,
            date,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setMessage("Hurray!! 🥳 response submitted to client");
            console.log("clientdata", data.msg);
          })
          .catch((err) => {
            console.log(err.message);
          });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const toggleAudio = () => {
    if (!audioElement) {
      // Create the audio element if it doesn't exist
      const newAudioElement = new Audio(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/gridStorage/${filenamee}`
      );
      newAudioElement
        .play()
        .then(() => {
          setIsAudioPlaying(true);
        })
        .catch((error) => {
          console.error("Error playing audio:", error);
        });
      setAudioElement(newAudioElement);
    } else {
      // Toggle play/pause
      if (audioElement.paused) {
        audioElement
          .play()
          .then(() => {
            setIsAudioPlaying(true);
          })
          .catch((error) => {
            console.error("Error resuming audio playback:", error);
          });
      } else {
        audioElement.pause();
        setIsAudioPlaying(false);
      }
    }
  };

  const downloadResume = () => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getResumeFileName/${userData.userID}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("resume file name new: ", data.msg);
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/Images/${data.msg}`)
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
           console.log("url: ", url);
            const a = document.createElement("a");
            a.href = url;
            a.download = userData.resume; // Set the file name
            document.body.appendChild(a);

            a.click();

            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error("Error downloading resume:", error);
          });
      });
  };

  const handleView = () => {
    setViewstatus(true);
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/resume_view/${userData.userID}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("resume file name new: ", data.msg);
        setViewstatus(false);
        setPdfurl(data);
        
      }).catch((err)=>{
          console.log(("error",err.message))
      })
  };

  if (!isOpen) return null;

  const handleStatus = (e) => {
    if (e.target.value === "shortlisted") {
      fetch(
        `${
          process.env.REACT_APP_BACKEND_BASE_URL
        }/clients/onejobapplications/${2}/${userData._id}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            candidateStatus: "shortlisted",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(`Item updated to  in the database`, data.msg);
        })
        .catch((error) => {
          console.error("Error updating item status in the database:", error);
        });
    } else if (e.target.value === "interviewed") {
      fetch(
        `${
          process.env.REACT_APP_BACKEND_BASE_URL
        }/clients/onejobapplications/${3}/${userData._id}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            candidateStatus: "interviewed",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(`Item updated to  in the database`, data.msg);
        })
        .catch((error) => {
          console.error("Error updating item status in the database:", error);
        });
    } else if (e.target.value === "hired") {
      fetch(
        `${
          process.env.REACT_APP_BACKEND_BASE_URL
        }/clients/onejobapplications/${4}/${userData._id}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            candidateStatus: "shortlisted",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(`Item updated to  in the database`, data.msg);
        })
        .catch((error) => {
          console.error("Error updating item status in the database:", error);
        });
    } else {
      fetch(
        `${
          process.env.REACT_APP_BACKEND_BASE_URL
        }/clients/onejobapplications/${5}/${userData._id}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            candidateStatus: "shortlisted",
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(`Item updated to  in the database`, data.msg);
        })
        .catch((error) => {
          console.error("Error updating item status in the database:", error);
        });
    }
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <CloseButton onClick={closeModal}>&times;</CloseButton>
        {message && (
          <div className="mt-3 alert alert-info text-center">{message}</div>
        )}
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <div
            onClick={() => setActiveTab("Profile")}
            style={{
              cursor: "pointer",
              padding: "10px",
              borderBottom: activeTab === "Profile" ? "2px solid #000" : "none",
            }}
          >
            Profile
          </div>
          {/* <div
              onClick={() => setActiveTab("About")}
              style={{
                cursor: "pointer",
                padding: "10px",
                borderBottom: activeTab === "About" ? "2px solid #000" : "none",
              }}
            >
              About
            </div> */}
        </div>
        {activeTab === "Profile" && (
          <ScrollableProfileTab>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <div>
                    <img src={profileImage} alt="profileImage.svg" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "left",
                      paddingTop: "15px",
                    }}
                  >
                    <span style={{ fontWeight: "600" }}>{firstName}</span>

                    <p style={{ fontSize: "14px" }}>
                      {userData.city}, {userData.state}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <select onChange={handleStatus} className="statusSelect">
                      <option value="">All Applications</option>
                      <option value="shortlisted">Shortlisted</option>
                      <option value="interviewed">InterViewed</option>
                      <option value="hired">Hired</option>
                      <option value="rejected">Rejected</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr style={{ marginTop: "-1px", marginLeft: "-30px" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "12px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <ul>
                    <li style={{ marginBottom: "5px", marginLeft: "-7px" }}>
                      <b>Match Score</b> : {(parseFloat(matchScore).toFixed(1))}/10
                    </li>
                    <li
                      style={{
                        marginBottom: "5px",
                        marginLeft: "-7px",
                        width: "90%",
                      }}
                    >
                      <b>Reasoning</b> : {reasoning}
                    </li>
                    <li style={{ marginBottom: "5px", marginLeft: "-7px" }}>
                      <SkillKeywordsLabel>Skill Keywords: </SkillKeywordsLabel>
                      <SkillKeywordsList>
                        {skillkeywordsArr?.map((keyword, index) => (
                          <SkillKeywordItem key={index}>
                            {keyword}
                          </SkillKeywordItem>
                        ))}
                      </SkillKeywordsList>
                    </li>
                    <li style={{ marginBottom: "5px", marginLeft: "-7px" }}>
                      <b>Avaliable to start</b> : {userData.Availability}
                    </li>
                    <li style={{ marginBottom: "5px", marginLeft: "-7px" }}>
                      <b>Interview Avaliablity</b> :{" "}
                      {userData.interviewAvailability}
                    </li>
                    <li style={{ marginBottom: "5px", marginLeft: "-7px" }}>
                      <b>Sponsorship required</b> : {userData.sponsorship}
                    </li>
                  </ul>
                  <div style={{ paddingLeft: "25px" }}>
                    {userData.socialProfiles.length > 0 &&
                      userData.socialProfiles.map((urlObj, index) => {
                        try {
                          if (urlObj.url !== "") {
                            const parsedUrl = new URL(urlObj.url);
                            const domain = parsedUrl.hostname.replace(
                              "www.",
                              ""
                            );

                            const truncatedDomain = domain;
                            const visibleText = `${truncatedDomain}/${firstName}`;
                            return (
                              <div key={index} style={{ marginBottom: "7px" }}>
                                <a href={urlObj.url} target="_blank">
                                  {visibleText}
                                </a>
                                <br />
                              </div>
                            );
                          } else {
                            return (
                              <div key={index}>
                                <a href={urlObj.url} target="_blank"></a>
                                <br />
                              </div>
                            );
                          }
                        } catch (error) {
                          console.error(
                            `Error parsing URL: ${urlObj.url}`,
                            error
                          );
                          return null;
                        }
                      })}
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "8px",
                        cursor: "pointer",
                      }}
                      onClick={downloadResume}
                    >
                      <img src={fi_download} width="25px" alt="" />
                      <p style={{ marginTop: "14px" }}>Download Resume</p>
                    </div>
                    <p onClick={handleView} style={{ marginTop: "14px" }}>
                      view Resume
                    </p>
                    {!viewStatus ? <iframe src={`https://neverhuntbe.dev.neverhunt.io/Images/file_1707737388885.pdf`} width="100%" height="600px" title="Resume PDF"></iframe> : null}
                    

                    {/* <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                }}
                onClick={handlePlay(userData.jobUniqueID, userData.userID)}
              >
                {!isAudioPlaying ? (
                  <img
                    src={playCircle}
                    alt="PlayCricle.svg"
                    width="30px"
                    style={{ paddingRight: "7px" }}
                    onClick={(e) => {
                      toggleAudio();
                    }}
                  />
                ) : (
                  <img
                    src={pause_icon}
                    alt="PlayCricle.svg"
                    style={{ paddingRight: "7px" }}
                    onClick={(e) => {
                      toggleAudio();
                    }}
                  />
                )}
                <p style={{ marginTop: "14px" }}>Play Voice Intro</p>
              </div> */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img src={phoneCall} width="25px" alt="" />
                      <p style={{ marginTop: "10px" }}>{userData.phone}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img src={Envelope} width="25px" alt="" />
                      <p style={{ marginTop: "10px" }}>{email}</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <h6>Screening Response</h6>
              <ScrollableContainer>
                <div
                  style={{
                    backgroundColor: "#F1F2F4",
                    padding: "4px 0px 4px 13px",
                  }}
                >
                  {questions && questions.length > 0 ? (
                    questions.map((question, index) => (
                      <div key={index}>
                        <p style={{ color: "black" }}>
                          {question.title ? (
                            <p>{question.title}</p>
                          ) : (
                            <p>{question}</p>
                          )}
                        </p>
                        <p style={{ color: "#5E6670" }}>
                          {typeof answers[index] === "object"
                            ? JSON.stringify(answers[index]) // Convert objects to strings
                            : answers[index]}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p>No Questions added for this role!</p>
                  )}
                </div>
              </ScrollableContainer>
              <hr />
              <h6>Additional Notes</h6>
              <ReactQuill
                theme="snow"
                value={additionalNotes}
                onChange={(value) => setAdditionalNotes(value)}
                style={{ height: "100px" }}
              />
              {!submitstatus ? "" : 
               <button
               style={{
                 marginTop: "70px",
                 cursor: submitting ? "not-allowed" : "pointer",
               }}
               onClick={handleSubmitClient}
               disabled={submitting}
             >
               {submitting ? "Submitted" : "Submit to client"}
             </button>
              }
             
            </div>
          </ScrollableProfileTab>
        )}
        {activeTab === "About" && renderAboutTab()}
      </ModalContent>
    </ModalWrapper>
  );
}

export default ProfileModal;
