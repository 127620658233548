import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import instagram from "../Image/Employers.png";
import briefcase1 from "../Image/briefcaselogo.svg";
import "../styles/Dashboard.css";
import Sidebar from "./Sidebar";
import icon from "../Image/Icon.svg";
import IdentificationCard from "../Image/personidentity.svg";

// import "../styles/Applicants.css";
import check from "../Image/CheckCircle.svg";
import Dots from "../Image/DotsThreeVertical.svg";
import users from "../Image/Users.png";
import Archive from "../Image/Archive.svg";
import fi_edit from "../Image/fi_edit.svg";
import EmployersProfile from "../Image/UserCircle.svg";
import fi_arrow from "../Image/fi_arrow-right.svg";
import fi_copy from "../Image/fi_copy.svg";
import tick_icons from "../Image/tick-icons8.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";

function Dashbord() {
  const [activeJobs, setActiveJobs] = useState(0);
  const [Jobs, setJobs] = useState([]);
  const [jobsWithNoResponse, setJobsWithNoResponse] = useState(0);
  const [unreadResponses, setUnreadResponses] = useState(0);
  const [openOverlays, setOpenOverlays] = useState([]);
  const [singleJobUnread, setSingleJobUnread] = useState([]);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
  const [copyClick, setCopyClick] = useState([]);
  const [fname, setFname] = useState(null);
  const navigate = useNavigate();

  // Function to toggle active dropdown index
  const toggleDropdown = (rowIndex) => {
    setActiveDropdownIndex(activeDropdownIndex === rowIndex ? null : rowIndex);
  };

  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");

  useEffect(() => {
    if (!token) {
      navigate(`/`);
    }
  }, []);

  useEffect(()=>{
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/token-expire?email=${email}`, {
     method: 'GET',
     headers: {
       "Authorization": token
     }
    })
    .then((res)=> res.json())
    .then((data)=>{
      console.log("data token",data.msg);
     if(data.msg === "Invalid token. Please login"){
      //  setIsToeknValid(false)
       localStorage.removeItem("token");
       navigate(`/`);

     }else {
      //  setIsToeknValid(true)
     }
   
    })
    .catch((err)=>{
     console.log(err);
    })
 }, [token])

  //${process.env.REACT_APP_BACKEND_BASE_URL}/clients/activeJobs
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/activeJobs`, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setActiveJobs(data.msg.length);
        setJobs(data.msg);
        setSingleJobUnread(data.unread);
      })
      .catch((err) => {
          console.log(err.message);
      });
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/jobsWithNoResponse`, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setJobsWithNoResponse(data.msg.length);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
//${process.env.REACT_APP_BACKEND_BASE_URL}/clients/unreadResponses
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/unreadResponses`, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnreadResponses(data.msg);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(()=>{
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/fname`, {
      method:"POST",
      headers:{
        "authorization": token
      },
      body:JSON.stringify({
        email: email
      })
    }).then((res)=> res.json())
    .then((data)=>{
      setFname(data.msg.firstName)
    })
  }, [])

  const handleCopy = (rowIndex) => {
    const newCopyClick = [...copyClick];
    newCopyClick[rowIndex] = true;

    setCopyClick(newCopyClick);

    setTimeout(() => {
      const resetCopyClick = [...newCopyClick];
      resetCopyClick[rowIndex] = false;
  
      setCopyClick(resetCopyClick);

    }, 2000);

  };

  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const year = dateObj.getFullYear();
    return `${month}-${day}-${year}`;
  }
  

  return (
    <div className="MainContainer">
      <div style={{ height: "60px" }}>
        <Navbar />
      </div>
      <hr />
      <div className="container second-main">
        <div className="row">
          <div className="col-1 col-lg-3 sidebarDiv">
            <Sidebar />
          </div>

          <div className="col-lg-9">
            <div className="dashboard-right">
              <div className="dashboard-right-header">
                <div className="left-text">
                  <h5>Hello, {fname}</h5>
                  <p class="m-0">
                    Here are your daily activities
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-5 col-lg-6 col-md-6">
                  <div className="single-feature-box">
                    <div className="single-feature-data">
                      <h6 class="tw-text-[#18191C] tw-text-2xl tw-font-semibold">
                        {activeJobs}
                      </h6>
                      <p>Open Positions</p>
                    </div>
                    <div className="single-feature-icon">
                      <svg
                        width="64"
                        height="64"
                        viewBox="0 0 64 64"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="64" height="64" rx="5" fill="white" />
                        <g clip-path="url(#clip0_1_1554)">
                          <path
                            opacity="0.2"
                            d="M32 35.0001C27.7872 35.0067 23.6476 33.8992 20.001 31.7899V42.0001C20.001 42.1314 20.0268 42.2614 20.0771 42.3828C20.1273 42.5041 20.201 42.6143 20.2939 42.7072C20.3867 42.8001 20.497 42.8737 20.6183 42.924C20.7396 42.9742 20.8697 43.0001 21.001 43.0001H43.001C43.1323 43.0001 43.2623 42.9742 43.3837 42.924C43.505 42.8737 43.6152 42.8001 43.7081 42.7072C43.8009 42.6143 43.8746 42.5041 43.9249 42.3828C43.9751 42.2614 44.001 42.1314 44.001 42.0001V31.7887C40.3539 33.8988 36.2135 35.0067 32 35.0001Z"
                            fill="#0A65CC"
                          />
                          <path
                            d="M43.001 25H21.001C20.4487 25 20.001 25.4478 20.001 26V42C20.001 42.5523 20.4487 43 21.001 43H43.001C43.5533 43 44.001 42.5523 44.001 42V26C44.001 25.4478 43.5533 25 43.001 25Z"
                            stroke="#0A65CC"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M37 25V23C37 22.4696 36.7893 21.9609 36.4142 21.5858C36.0391 21.2107 35.5304 21 35 21H29C28.4696 21 27.9609 21.2107 27.5858 21.5858C27.2107 21.9609 27 22.4696 27 23V25"
                            stroke="#0A65CC"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M44.001 31.7888C40.3539 33.8988 36.2135 35.0068 32 35.0001C27.7872 35.0068 23.6475 33.8992 20.0008 31.7898"
                            stroke="#0A65CC"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M30.5 31H33.5"
                            stroke="#0A65CC"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_1554">
                            <rect
                              width="32"
                              height="32"
                              fill="white"
                              transform="translate(16 16)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-6">
                  <div className="single-feature-box">
                    <div className="single-feature-data">
                      <h6 class="tw-text-[#18191C] tw-text-2xl tw-font-semibold">
                        {unreadResponses}
                      </h6>
                      <p>Unread Responses</p>
                    </div>
                    <div className="single-feature-icon">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19 14H24"
                          stroke="#FFA500"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19 18H24"
                          stroke="#FFA500"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.5126 18C13.1694 18 14.5126 16.6569 14.5126 15C14.5126 13.3431 13.1694 12 11.5126 12C9.85572 12 8.51257 13.3431 8.51257 15C8.51257 16.6569 9.85572 18 11.5126 18Z"
                          stroke="#FFA500"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.63861 20.9999C7.86055 20.1413 8.36147 19.3807 9.06266 18.8377C9.76385 18.2947 10.6256 18 11.5124 18C12.3993 18 13.261 18.2946 13.9623 18.8375C14.6635 19.3805 15.1645 20.141 15.3865 20.9997"
                          stroke="#FFA500"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M27 6.00001H5C4.44772 6.00001 4 6.44772 4 7.00001V25C4 25.5523 4.44772 26 5 26H27C27.5523 26 28 25.5523 28 25V7.00001C28 6.44772 27.5523 6.00001 27 6.00001Z"
                          stroke="#FFA500"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6"></div>
              </div>
              <div class="recently-applied-wrap d-flex justify-content-between align-items-center rt-mb-15">
                <h3 class="f-size-16">Recent Jobs</h3>
                <a
                  class="view-all text-gray-500 f-size-16 d-flex align-items-center"
                  href="/myjobs"
                >
                  View All
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="fi:arrow-right">
                      <path
                        id="Vector"
                        d="M5 12H19"
                        stroke="#767F8C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        id="Vector_2"
                        d="M12 5L19 12L12 19"
                        stroke="#767F8C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                </a>
              </div>
              <div className="db-job-card-table">
                <table>
                  <thead>
                    <tr>
                      <th>Job</th>
                      <th>Status</th>
                      <th>Unread Responses</th>
                      <th>Date</th>
                      <th>Job Link</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(Jobs) && Jobs.length > 0 && Jobs.slice(0, 5).map((el, rowIndex) => (
                      <tr key={el.RuserID}>
                        <td>
                          <div class="iconbox-content">
                            <div class="post-info2">
                              <div class="post-main-title">
                                <a
                                  href="#"
                                  class="text-gray-900 f-size-16  ft-wt-5"
                                >
                                  {el.position} {el.jobidrec}
                                </a>
                              </div>
                              <div class="body-font-4 text-gray-600 pt-2">
                                <span class="info-tools rt-mr-8">
                                  {el.positionType[0]}
                                </span>
                                <span class="info-tools">{el.city} {el.state}</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="text-success-500 ft-wt-5 d-flex align-items-center">
                            <i class="ph-check-circle f-size-18 mt-1 rt-mr-4">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="CheckCircle">
                                  <path
                                    id="Vector"
                                    d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                                    stroke="#0BA02C"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    id="Vector_2"
                                    d="M13.4375 8.125L8.85414 12.5L6.5625 10.3125"
                                    stroke="#0BA02C"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                              </svg>
                            </i>
                            Active
                          </div>
                        </td>
                        <td>
                          <div class="d-flex  align-items-center mt-3">
                            <i class="ph-users f-size-20 rt-mr-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M8.25006 15C10.9424 15 13.1251 12.8174 13.1251 10.125C13.1251 7.43261 10.9424 5.25 8.25006 5.25C5.55767 5.25 3.37506 7.43261 3.37506 10.125C3.37506 12.8174 5.55767 15 8.25006 15Z"
                                  stroke="#5E6670"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                />
                                <path
                                  d="M14.5699 5.4316C15.2405 5.24268 15.9437 5.19964 16.6322 5.30538C17.3208 5.41113 17.9787 5.66319 18.5616 6.04461C19.1445 6.42602 19.639 6.92793 20.0116 7.51652C20.3842 8.10511 20.6264 8.76672 20.7218 9.45678C20.8172 10.1468 20.7636 10.8493 20.5646 11.5169C20.3657 12.1845 20.026 12.8018 19.5684 13.327C19.1108 13.8523 18.5459 14.2734 17.9119 14.562C17.2779 14.8506 16.5893 14.9999 15.8927 15"
                                  stroke="#5E6670"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1.49963 18.5059C2.26101 17.4229 3.27178 16.539 4.44662 15.9288C5.62145 15.3186 6.92586 15.0001 8.24971 15C9.57356 14.9999 10.878 15.3184 12.0529 15.9285C13.2278 16.5386 14.2386 17.4225 15.0001 18.5054"
                                  stroke="#5E6670"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M15.8927 15C17.2167 14.999 18.5214 15.3171 19.6964 15.9273C20.8713 16.5375 21.882 17.4218 22.6427 18.5054"
                                  stroke="#5E6670"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </i>
                            <span>{singleJobUnread[rowIndex]}</span>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex align-items-center mt-1">
                            <span>{formatDate(el.date)}</span>
                          </div>
                        </td>
                        <td>
                          <CopyToClipboard text={el.jobLink}>
                            {copyClick[rowIndex] ? (
                              <img
                                src={tick_icons}
                                width="35px"
                                alt="error"
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <img
                                src={fi_copy}
                                alt="error"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleCopy(rowIndex)}
                              />
                            )}
                          </CopyToClipboard>
                        </td>
                        <td>
                          <div class="db-job-btn-wrap d-flex justify-content-center">
                            <a
                              href={`/kanban/${el.uniqueID}`}
                              className="btn view-button bg-gray-50 text-primary-500 rt-mr-8"
                            >
                              <span class="button-text">View Applications</span>
                              {
                                localStorage.setItem("positionTitle", el.position)
                              }
                            </a>
                            <button
                              type="button"
                              class="btn btn-icon"
                              id="dropdownMenuButton5"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 13.125C12.6213 13.125 13.125 12.6213 13.125 12C13.125 11.3787 12.6213 10.875 12 10.875C11.3787 10.875 10.875 11.3787 10.875 12C10.875 12.6213 11.3787 13.125 12 13.125Z"
                                  fill="#767F8C"
                                  stroke="#767F8C"
                                ></path>
                                <path
                                  d="M12 6.65039C12.6213 6.65039 13.125 6.14671 13.125 5.52539C13.125 4.90407 12.6213 4.40039 12 4.40039C11.3787 4.40039 10.875 4.90407 10.875 5.52539C10.875 6.14671 11.3787 6.65039 12 6.65039Z"
                                  fill="#767F8C"
                                  stroke="#767F8C"
                                ></path>
                                <path
                                  d="M12 19.6094C12.6213 19.6094 13.125 19.1057 13.125 18.4844C13.125 17.8631 12.6213 17.3594 12 17.3594C11.3787 17.3594 10.875 17.8631 10.875 18.4844C10.875 19.1057 11.3787 19.6094 12 19.6094Z"
                                  fill="#767F8C"
                                  stroke="#767F8C"
                                ></path>
                              </svg>
                            </button>
                            <ul
                              className="dropdown-menu dropdown-menu-end company-dashboard-dropdown"
                              aria-labelledby="dropdownMenuButton5"
                            >
                              <li>
                                <a href={`/editJobPost/${el.uniqueID}`} class="dropdown-item">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g
                                      id="fi:edit"
                                      clip-path="url(#clip0_26_477)"
                                    >
                                      <path
                                        id="Vector"
                                        d="M9.16669 3.33334H3.33335C2.89133 3.33334 2.4674 3.50894 2.15484 3.8215C1.84228 4.13406 1.66669 4.55798 1.66669 5.00001V16.6667C1.66669 17.1087 1.84228 17.5326 2.15484 17.8452C2.4674 18.1577 2.89133 18.3333 3.33335 18.3333H15C15.442 18.3333 15.866 18.1577 16.1785 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6667V10.8333"
                                        stroke="#191F33"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        id="Vector_2"
                                        d="M15.4166 2.08332C15.7481 1.7518 16.1978 1.56555 16.6666 1.56555C17.1355 1.56555 17.5851 1.7518 17.9166 2.08332C18.2481 2.41484 18.4344 2.86448 18.4344 3.33332C18.4344 3.80216 18.2481 4.2518 17.9166 4.58332L9.99996 12.5L6.66663 13.3333L7.49996 9.99999L15.4166 2.08332Z"
                                        stroke="#0A65CC"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_26_477">
                                        <rect
                                          width="20"
                                          height="20"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  Edit Post
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g id="Archive">
                                      <path
                                        id="Vector"
                                        d="M17.5 4.375H2.5C2.15482 4.375 1.875 4.65482 1.875 5V6.875C1.875 7.22018 2.15482 7.5 2.5 7.5H17.5C17.8452 7.5 18.125 7.22018 18.125 6.875V5C18.125 4.65482 17.8452 4.375 17.5 4.375Z"
                                        stroke="#5E6670"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        id="Vector_2"
                                        d="M16.875 7.5V15C16.875 15.1658 16.8092 15.3247 16.6919 15.4419C16.5747 15.5592 16.4158 15.625 16.25 15.625H3.75C3.58424 15.625 3.42527 15.5592 3.30806 15.4419C3.19085 15.3247 3.125 15.1658 3.125 15V7.5"
                                        stroke="#5E6670"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        id="Vector_3"
                                        d="M8.125 10.625H11.875"
                                        stroke="#18191C"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </g>
                                  </svg>
                                  Archive
                                </a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dashboard-footer text-center body-font-4 text-gray-500 copyFooter">
      Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
}

export default Dashbord;
