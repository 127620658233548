import React, { useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/profile.css";

function Profile() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [invalidFields, setInvalidFields] = useState([]);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  
  const email = JSON.parse(localStorage.getItem("email"));

  const handleTooltipClick = () => {
    setTooltipVisible(!tooltipVisible); // Toggle the tooltip visibility on click
  };


  const handlePasswordUpdate = (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setInvalidFields(["newPassword", "confirmNewPassword"]);
      setMessage("Passwords do not match");
      return;
    }
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setInvalidFields([
        "currentPassword",
        "newPassword",
        "confirmNewPassword",
      ]);
      setMessage("Please fill all fields");
      return;
    }
    const obj = {
      password: newPassword,
    };

    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/password/update/${email}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        setMessage(`${data.msg}...`);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
      })
      .catch((err) => {
        console.log(err);
      });

 
  };

  return (
    <>
      <div style={{ display: "flex", width: "100%"}}>
        <div style={{ flex: "10%" }}>
          <Sidebar />
        </div>
        <div
          className="pageContent"
          style={{ flex: "75%", display: "flex", flexDirection: "column" }}
        >
          <Navbar />
          <div>
            <div style={{ height: "auto", textAlign: "center" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <form
                    className="mt-4 border p-4"
                    style={{ width: "400px" }}
                    onSubmit={handlePasswordUpdate}
                  >
                    {message && (
                      <div className="mt-3 alert alert-info text-center">
                        {message}
                      </div>
                    )}
                    <h2>Password Update</h2>
                    <div
                      className={`mb-3 ${
                        invalidFields.includes("currentPassword")
                          ? "has-danger"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor="currentPassword"
                        className="form-label"
                        style={{
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "start",
                        }}
                      >
                        Current Password
                      </label>
                      <input
                        type="password"
                        className={`form-control ${
                          invalidFields.includes("currentPassword")
                            ? "is-invalid"
                            : ""
                        }`}
                        id="currentPassword"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                    </div>
                    <div
                      className={`mb-3 ${
                        invalidFields.includes("newPassword")
                          ? "has-danger"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor="newPassword"
                        className="form-label"
                        style={{
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "start",
                        }}
                      >
                        New Password
                      </label>
                      <input
                        type="password"
                        className={`form-control ${
                          invalidFields.includes("newPassword")
                            ? "is-invalid"
                            : ""
                        }`}
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>
                    <div
                      className={`mb-3 ${
                        invalidFields.includes("confirmNewPassword")
                          ? "has-danger"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor="confirmNewPassword"
                        className="form-label"
                        style={{
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "start",
                        }}
                      >
                        Confirm New Password
                      </label>
                      <input
                        type="password"
                        className={`form-control ${
                          invalidFields.includes("confirmNewPassword")
                            ? "is-invalid"
                            : ""
                        }`}
                        id="confirmNewPassword"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                      />
                    </div>
                    <button className="btn btn-primary w-100">Update</button>
                  </form>
                </div>
                {/* <div class="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2023 Neverhunt All rights reserved.
        </div>
              */}

<div className="tooltip-container" style={{ position: "relative" , marginLeft: "auto" }}>
      <div
        className="help-icon"
        onClick={handleTooltipClick}
      >
        <span className="text-primary cursor-pointer">?</span>
      </div>

      {tooltipVisible && (
        <div className="custom-tooltip"   style={{
          position: "absolute",
          width: "300px", // Set the desired width of the tooltip
        }}>
          <div className="tooltip-content" >
            <h2>Help</h2>
            <p>
              Welcome to the Profile page! This page allows you to update your password. To update your password, follow these steps:
              {/* Add your instructions here */}
            </p>
            {/* <button onClick={handleTooltipMouseLeave} className="close-btn">
              Close
            </button> */}
          </div>
        </div>
      )}
    </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;