import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../styles/Jobseekersidebar.css";
// import "../styles/Sidebar.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import overview from "../Image/Stack.svg";
import EmployersProfile from "../Image/UserCircle.svg";
import PostJob from "../Image/PlusCircle.svg";
import Myjob from "../Image/Briefcase.svg";
import savedCandidates from "../Image/BookmarkSimple.svg";
import PlansAndBilling from "../Image/Notebook.svg";
import AllCompanys from "../Image/User List.svg";
import settings from "../Image/Gear.svg";
import signout from "../Image/SignOut.svg";

function Jobsidebar() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeOption, setActiveOption] = useState(location.pathname);

  const handleClient = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleOptionClick = (path) => {
    setActiveOption(path);
  };

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
    document.body.style.overflow = isModalOpen ? "auto" : "hidden";
  };

  return (
    <div>
      <div className="container-fluid job-sidebar">
        <div className="row">
          <div className="col-auto col-sm-2 d-flex flex-column justify-content-between min-vh-100 w-100">
            <div className="borderFix">
              <ul className="nav nav-pills flex-column mt-sm-0" id="parentM">
                <li
                  className={`nav-item my-1 py-2 py-sm-0 custom-li-class ${
                    activeOption === "/Jobseeker" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/Jobseeker"
                    className="nav-link text-white text-center text-sm-start name-img"
                    onClick={() => handleOptionClick("/Jobseeker")}
                  >
                    <img src={overview} alt="error" />
                    <span className="ms-2 d-none d-sm-inline span-t">
                      Applied Jobs
                    </span>
                  </Link>
                </li>

                <li
                  className={`nav-item my-1 py-2 py-sm-0 ${
                    activeOption === "/myprofile" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/myprofile"
                    className="nav-link text-white text-center text-sm-start"
                    onClick={() => handleOptionClick("/myprofile")}
                  >
                    <i className="ph-user-circle"></i>
                    <img src={EmployersProfile} alt="error" />
                    <span className="ms-2 d-none d-sm-inline text-gray">
                      My Profile
                    </span>
                  </Link>
                </li>

                <li
                  className={`nav-item my-1 py-2 py-sm-0 ${
                    activeOption === "/seekermessages" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/seekermessages"
                    className="nav-link text-white text-center text-sm-start"
                    onClick={() => handleOptionClick("/seekermessages")}
                  >
                    <i className="ph-user-circle"></i>
                    <img src={EmployersProfile} alt="error" />
                    <span className="ms-2 d-none d-sm-inline text-gray">
                      Message
                    </span>
                  </Link>
                </li>


                <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "/suggestions" ? "active" : ""}`}>
                                <Link to="/suggestions" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/suggestions")}>
                                    
                                    <img src={PostJob} alt="error" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>Suggestions</span>
                                </Link>
                            </li>

                {/* <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "/JobPost" ? "active" : ""}`}>
                                <Link to="/JobPost" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/posts")}>
                                    
                                    <img src={PostJob} alt="error" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>Post a Job</span>
                                </Link>
                            </li>
                            <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "/myjobs" ? "active" : ""}`}>
                                <Link to="/myjobs" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/myjobs")}>
                                   
                                    <img src={Myjob} alt="error" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>My Jobs</span>
                                </Link>
                            </li> */}
                {/* <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "#" ? "active" : ""}`}>
                                <Link to="#" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/profile")}>
                                    <img src={savedCandidates} alt="error" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>Saved Candidates</span>
                                </Link>

                            </li>
                            <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "#" ? "active" : ""}`}>
                                <Link to="#" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/profile")}>
                                  
                                    <img src={PlansAndBilling} alt="error" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>Plans & Billing</span>
                                </Link>
                            </li>
                            <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "#" ? "active" : ""}`}>
                                <Link to="#" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/profile")}>
                                   
                                    <img src={AllCompanys} alt="error" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>All Companies</span>
                                </Link>
                            </li> */}
                <li
                  className={`nav-item my-1 py-2 py-sm-0 ${
                    activeOption === "/jobseeker/profile" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/jobseeker/profile"
                    className="nav-link text-white text-center text-sm-start"
                    onClick={() => handleOptionClick("/jobseeker/profile")}
                  >
                    {/* <i className='bi bi-people'></i> */}
                    <img src={settings} alt="error" />
                    <span className="ms-2 d-none d-sm-inline text-gray">
                      Settings
                    </span>
                  </Link>
                </li>
                <li className={"nav-item my-1 py-2 py-sm-0"}>
                  <Link
                    to="/"
                    className="nav-link text-white text-center text-sm-start"
                    type="button"
                    onClick={handleClient}
                  >
                    {/* <i className='bi bi-person f5-4'></i> */}
                    <img src={signout} alt="error" />
                    <span className="ms-2 d-none d-sm-inline text-gray">
                      Logout
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile Hamburger Icon */}
      <div className="col-12 d-lg-none d-xl-none">
        <i
          className="bi bi-list text-primary"
          onClick={handleToggleModal}
          style={{ fontSize: "30px", color: "#0056b3", fontWeight: "bold" }}
        ></i>{" "}
        {/* Hamburger icon */}
      </div>

      {/* Mobile Sidebar Modal */}
      {isModalOpen && (
        <>
          <div className="modal-overlay" onClick={handleToggleModal}></div>
          <div
            className="modal mobile-sidebar-modal show"
            tabIndex="-1"
            style={{ display: "block" }}
            data-backdrop="static"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Menu</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleToggleModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <ul className="nav flex-column" id="parentM">
                    <li className="nav-item">
                      <Link to="/myjobs" className="nav-link">
                        My Jobs
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/appliedjobs" className="nav-link">
                        Applied Jobs
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/" className="nav-link">
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="modal-footer">
                  {/* <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleToggleModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleClient}
                >
                  Logout
                </button> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Jobsidebar;
