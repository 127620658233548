import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import briefcase1 from "../Image/briefcaselogo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { jsonrepair } from "jsonrepair";
import "../styles/Navbar.css";

function Navbar() {
  const [showModal, setShowModal] = useState(false);
  const [description, setDescription] = useState("");
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState(""); // Store the URL of the logo fetched from the server
  const [newLogoUploaded, setNewLogoUploaded] = useState(false); // Track if a new logo is uploaded
  const [jobname, setJobname] = useState("");
  const [formData, setFormData] = useState({
    locationType: "",
    compensation: "",
    jobType: "",
  });
  const [location, setLocation] = useState({
    city: "",
    state: "",
    zipCode: "",
  });
  const [locCheck, setLocCheck] = useState("");
  const [locCheckValue, setLocCheckValue] = useState(false);

  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [cityval, setCityval] = useState(false);
  const locationInputRef = useRef(null);
  const [uid, setUid] = useState("");

  const [emptyFields, setEmptyFields] = useState([]);
  const [additionalFields, setAdditionalFields] = useState(false);
  const [pyData, setPyData] = useState({});
  const [alertMessage, setAlertMessage] = useState("");

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [showProcessingMessage, setShowProcessingMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [alertmsg, setAlertmsg] = useState("Processing, please wait...");
  const [countdown, setCountdown] = useState(3);

  const [positionEmpty, setPositionEmpty] = useState(false);
  const [descriptionEmpty, setDescriptionEmpty] = useState(false);
  const token = localStorage.getItem("token");
  const [positions, setPositions] = useState("");
  const [buttonText, setButtonText] = useState("Gen Jd");
  const [companyID, setCompanyID] = useState("");

  function stripHtml(html) {
    let doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  const handleDescriptionChange = (value) => {
    console.log("value: ", value);
    setDescription(value);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/company/getid`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: token
      }
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("data company", data.msg);
        setCompanyID(data.msg);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleJobName = (e) => {
    setJobname(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (value.trim() !== "") {
      setEmptyFields(emptyFields.filter((field) => field !== name));
      setAlertMessage(""); // Clear alert message when input is valid
    } else {
      if (!emptyFields.includes(name)) {
        setEmptyFields([...emptyFields, name]);
      }
      setAlertMessage("Please fill out all required fields."); // Set alert message for empty input
    }
  };

  useEffect(() => {
    if (showModal) {
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/company/companyinfo`, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("checking logo ", data.msg);
          if (data.msg?.companyLogo) {
            setLogoUrl(
              `${process.env.REACT_APP_BACKEND_BASE_URL}/companyLogoView/${data.msg?.companyLogo}/${data.msg?._id}`
            );
          }
        });
    }
  }, [showModal]);

  const handleFileChange = (e) => {
    setLogo(e.target.files[0]);
    setLogoUrl(""); // Clear the logo URL when a new file is selected
    setNewLogoUploaded(true); // Mark that a new logo is uploaded
  };

  const handleLocationSuggestionClick = (suggestion) => {
    setLocation({
      city: suggestion.city,
      state: suggestion.state,
      zipCode: suggestion.zip,
    });
    setCityval(true);
    setLocationSuggestions([]);
  };

  useEffect(() => {
    const fetchLocationData = async () => {
      if (!locCheckValue) return;

      console.log("into locCheck useEffect called");
      console.log("locCheck", locCheck);

      if (!locCheck) {
        console.log("locCheck is empty, skipping fetch");
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/locations?search=${locCheck}`
      );
      const data = await response.json();
      console.log("data", data);

      setLocation({
        city: data.msg[0]?.city,
        state: data.msg[0]?.state,
        zipCode: data.msg[0]?.zip,
      });
      setCityval(true);
    };

    fetchLocationData();
  }, [locCheckValue, locCheck]);

  const handleCity = (e) => {
    const city = e.target.value;
    setLocation({ ...location, city: e.target.value });
  };

  const searchHandle = () => {
    fetchLocationSuggestions(location.city);
  };

  const fetchLocationSuggestions = async (input) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/locations?search=${input}`
      );
      const data = await response.json();
      setLocationSuggestions(data.msg);
      console.log("location suggestion check", data.msg);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
    }
  };

  const renderLocationSuggestions = () => {
    if (locationSuggestions.length === 0) return null;
    return (
      <div
        className="location-suggestions-overlay"
        style={{
          position: "absolute",
          zIndex: 1000,
          background: "white",
          border: "1px solid #ccc",
          width: "100%",
          marginTop: "2px",
        }}
        ref={locationInputRef}
      >
        {locationSuggestions.length === 0 ? (
          <p>No Matching jobs found</p>
        ) : (
          <ul className="list-group">
            {locationSuggestions?.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleLocationSuggestionClick(suggestion)}
                className="list-group-item"
                style={{ cursor: "pointer" }}
              >
                {suggestion.city} {suggestion.state}, {suggestion.zip}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchHandle();
    }
  };

  const handlePost = (e) => {
    e.preventDefault();
    if (additionalFields) {
      setAlertMessage("Saving the job, please wait....");
      handleSave();
      return;
    }

    setAlertmsg("");
    setAlertmsg("Processing, please wait...");
    if (jobname.trim() === "") {
      setPositionEmpty(true);
    } else {
      setPositionEmpty(false);
    }

    if (description.trim() === "") {
      setDescriptionEmpty(true);
    } else {
      setDescriptionEmpty(false);
    }

    if (jobname.trim() === "" || description.trim() === "") {
      return;
    } else {
      setShowProcessingMessage(true);
      const plainTextDescription = stripHtml(description);
      const formDataToSend = new FormData();
      formDataToSend.append("jd", plainTextDescription);
      fetch(`${process.env.REACT_APP_PYTHON_BASE_URL}/get_summary_of_jd`, {
        method: "POST",
        body: formDataToSend,
      })
        .then((res) => res.json())
        .then((pydata) => {
          console.log("parsed jd", pydata);
          const jsonStringMatch = pydata.match(/{[\s\S]*}/);
          if (jsonStringMatch) {
            const jsonString = jsonStringMatch[0];
            try {
              const parsedData = JSON.parse(jsonString);
              console.log("parsedData", parsedData);
              setPyData(parsedData);
              setFormData({
                locationType:
                  parsedData.locationType || parsedData.location_type || "",
                compensation: parsedData.compensation || "",
                jobType: parsedData.jobType || "",
              });
              if (parsedData.city) {
                setLocCheckValue(true);
                console.log("inside if city");
                setLocCheck(parsedData.city);
              } else if (parsedData.zip) {
                setLocCheckValue(true);
                console.log("inside else if zip");
                setLocCheck(parsedData.zip);
              }
              setTimeout(() => {
                console.log("locCheck", locCheck);
              }, 3000);
              setPositions(parsedData.roles);
              const emptyFields = [
                "locationType",
                "compensation",
                "jobType",
              ].filter((key) => !parsedData[key]);
              console.log("emptyFields", emptyFields);
              setEmptyFields(emptyFields);
              setAdditionalFields(true);
              setAlertmsg("");
            } catch (e) {
              console.error(`Invalid JSON: ${e.message}`);
            }
          } else {
            console.error("No JSON object found in the input string.");
          }
        });
    }
  };

  const handleJd = () => {
    setButtonText("Generating...");
    const jdform = new FormData();
    jdform.append("data", JSON.stringify(jobname));
    fetch(`${process.env.REACT_APP_PYTHON_BASE_URL}/generate_jd`, {
      method: "POST",
      body: jdform,
    })
      .then((res) => res.json())
      .then((data) => {
        const repairedJson = jsonrepair(data.result);
        const parsedgenjd = JSON.parse(repairedJson);
        console.log("parsed data:", parsedgenjd.job_des);
        setDescription(parsedgenjd.job_des);
        setButtonText("Gen Jd");
      })
      .catch((err) => {
        console.log("Err while generating jd", err.message);
        setButtonText("Gen Jd");
      });
  };

  const handleSave = () => {
    setShowMessage(true);
    setAlertMessage("Saving the job post, please wait...");
    const emptyFields = Object.keys(formData).filter(
      (key) => formData[key].trim() === ""
    );
    setEmptyFields(emptyFields);

    if (
      emptyFields.length === 0 &&
      location.city.trim() !== "" &&
      location.state.trim() !== "" &&
      location.zipCode.trim() !== ""
    ) {
      const updatedPyData = {
        ...pyData,
        ...formData,
        city: location.city,
        state: location.state,
        zipCode: location.zipCode,
      };

      const formDataToSend = new FormData();
      formDataToSend.append("position", jobname);
      formDataToSend.append("jobDescription", description);
      formDataToSend.append("parsedjd", JSON.stringify(updatedPyData));

      if (newLogoUploaded) {
        formDataToSend.append("logo", logo);
      } else {
        console.log("logoUrl", logoUrl);
        console.log("splitted", logoUrl.split("/").pop());
        formDataToSend.append("logo", logoUrl.split("/").pop());
      }

      formDataToSend.forEach((value, key) => {
        console.log("fdatatosend",key, value);
    });
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/Temppostjob`, {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: formDataToSend,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("temppost", data);
          const uid = data.id;
          localStorage.setItem("uid", JSON.stringify(uid));
          setUid(uid);
          const formjd = new FormData();
          formjd.append("message", JSON.stringify(pyData));
          formjd.append("jobDescription", description);
          formjd.append("jobid", uid);
          formjd.append("position", jobname);
          formjd.append("companyid", companyID);

          fetch(`${process.env.REACT_APP_PYTHON_BASE_URL}/store_jd`, {
            method: "POST",
            body: formjd,
          })
            .then((res) => res.json())
            .then((data) => {
              console.log("temppost", data);
            });
          let countdown = 3;
          setAlertMessage(
            `Job Saved Successfully redirecting in ${countdown} seconds...`
          );
          setTimeout(() => {
            setShowModal(false);
            window.location.reload();
          }, 4000);
        })
        .catch((err) => {
          console.error("error", err.message);
        });
    } else {
      setAlertMessage("Please fill out all required fields.");
    }
  };

  return (
    <div className="container pt-3 ">
      <div className="row">
        <div className="col-6 col-sm-7 col-md-8 col-lg-10 col-xl-9 pl-4">
          <a className="navbar-brand logo" href="#">
            <img
              src={briefcase1}
              alt="Logo"
              width="40"
              height="40"
              className="d-inline-block align-text-top mr-2"
            />
            <span className="logo-name">Neverhunt</span>
            <span className="beta">Beta</span>
          </a>
        </div>
        <div
          className="col-6 col-sm-5 col-md-4 col-lg-2  col-xl-3 d-flex gap-4 align-items-center "
          style={{ paddingLeft: "100px" }}
        >
          <a href="#">
            <button className="postjobbtn" onClick={handleShowModal}>
              Post Job
            </button>
          </a>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create Job Post</Modal.Title>
        </Modal.Header>
        {showProcessingMessage ? (
          <p style={{ color: "#0B61D3" }} className="text-center mt-2 mb-0">
            {alertmsg}
          </p>
        ) : null}
        <Modal.Body>
          <Form>
            {!additionalFields && (
              <>
                <Form.Group controlId="jobName">
                  <Form.Label>Job Name</Form.Label>
                  <span className="form-label-required text-danger">*</span>
                  <Form.Control
                    type="text"
                    className={`form-control placeholder-gray ${
                      positionEmpty ? "border-red" : ""
                    }`}
                    value={jobname}
                    onChange={handleJobName}
                    placeholder="Enter job name"
                  />
                </Form.Group>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Form.Group controlId="jobDescription" style={{ flex: 1 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Form.Label>Job Description</Form.Label>
                      <Button variant="primary" onClick={handleJd}>
                        {buttonText}
                      </Button>
                    </div>
                    <span className="form-label-required text-danger">*</span>
                    <ReactQuill
                      theme="snow"
                      id="description"
                      className={`${descriptionEmpty ? "border-red" : ""}`}
                      value={description}
                      // defaultValue={description}
                      onChange={handleDescriptionChange}
                      style={{ height: "300px", width: "100%" }}
                    />
                  </Form.Group>
                </div>
                <Form.Group
                  controlId="companyLogo"
                  style={{ marginTop: "50px" }}
                >
                  <Form.Label>Company Logo</Form.Label>
                  {logoUrl && (
                    <div>
                      <img
                        src={logoUrl}
                        alt="Company Logo"
                        style={{ maxWidth: "100px", marginBottom: "10px" }}
                      />
                    </div>
                  )}
                  <Form.Control
                    type="file"
                    name="companyLogo"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </>
            )}
            {additionalFields && (
              <>
                {showMessage && (
                  <p
                    style={{ color: "#0B61D3" }}
                    className="text-center mt-2 mb-0"
                  >
                    {alertMessage}
                  </p>
                )}
                <Form.Group controlId="location" style={{ marginTop: "10px" }}>
                  <Form.Label>Location</Form.Label>
                  <span className="form-label-required text-danger">*</span>
                  <div className="row">
                    <div className="col-md-4" style={{ position: "relative" }}>
                      <label htmlFor="city">City/Zip</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control placeholder-gray ${
                            emptyFields.includes("location") ? "border-red" : ""
                          }`}
                          ref={locationInputRef}
                          id="city"
                          value={location.city}
                          onChange={handleCity}
                          onKeyPress={handleKeyPress}
                          placeholder="City or Zip"
                          required
                        />
                        <div
                          className="input-group-append"
                          style={{ cursor: "pointer" }}
                          onClick={searchHandle}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Find"
                        >
                          {(location.city || location.zipCode) && (
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="fa fa-search"></i>
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      {renderLocationSuggestions()}
                    </div>
                    {cityval ? (
                      <>
                        <div className="col-md-4">
                          <label htmlFor="state">State</label>
                          <input
                            type="text"
                            className={`form-control placeholder-gray cursor-none ${
                              emptyFields.includes("location")
                                ? "border-red"
                                : ""
                            }`}
                            id="state"
                            value={location.state}
                            placeholder="State"
                            readOnly
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="zipCode">Zip</label>
                          <input
                            type="text"
                            className={`form-control placeholder-gray cursor-none ${
                              emptyFields.includes("location")
                                ? "border-red"
                                : ""
                            }`}
                            id="zipCode"
                            value={location.zipCode}
                            placeholder="Zip Code"
                            readOnly
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </Form.Group>
                {["locationType", "compensation", "jobType"].map((field) => (
                  <Form.Group
                    controlId={field}
                    key={field}
                    style={{ marginTop: "10px" }}
                  >
                    <Form.Label>
                      {field.charAt(0).toUpperCase() + field.slice(1)}
                    </Form.Label>
                    <span className="form-label-required text-danger">*</span>
                    <Form.Control
                      type="text"
                      name={field}
                      className={`form-control placeholder-gray ${
                        emptyFields.includes(field) ? "border-red" : ""
                      }`}
                      value={formData[field]}
                      onChange={handleInputChange}
                      placeholder={
                        field === "locationType"
                          ? "Remote, Hybrid, On-site"
                          : field === "jobType"
                          ? "Full time, Contract, Internship"
                          : `Enter ${field}`
                      }
                    />
                  </Form.Group>
                ))}
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <span
            variant="secondary"
            style={{
              marginTop: "25px",
              marginRight: "10px",
              cursor: "pointer",
            }}
            onClick={handleCloseModal}
          >
            Close
          </span>

          <Button
            variant="primary"
            style={{ marginTop: "25px" }}
            onClick={handlePost}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Navbar;
