import React, { useState } from "react";
import briefcase1 from "../Image/briefcaselogo.svg";
import Image from "../Image/Image.svg";
// import calendar from "../Image/calendar-blank 1.svg";
// import chatcircle from "../Image/chat-circle-dots 1.svg";
// import rightArrow from "../Image/fi_arrow-right.svg";
import Vector from "../Image/Vector.svg";
import man from "../Image/manSvg.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Home.css"; 
import { UserPlus, CloudArrowUp, MagnifyingGlassPlus } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import { CircleWavyCheck ,Handshake ,Check ,UserList} from "phosphor-react";
// import styles from './home.module.css';

function Home() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const containerStyle = {
    opacity: 1,
    transform: "translateZ(0)",
  };

  const headingStyle = {
    color: "#333", // You can define your own color here
    marginBottom: "24px",
  };

  const subheadingStyle = {
    fontSize: "18px",
    color: "#666", // You can define your own color here
    marginBottom: "30px",
  };

  const navigate = useNavigate()

  const handleClickSignup = ()=>{
    navigate("/Signup")
  }

  const handleClickLogin = ()=>{
    navigate("/login")
  }


  return (
    <>
      <header>
        <div className="container pt-3">
          <div className="row ">
            <div className="col-9 col-sm-9 col-md-8 col-lg-9 col-xl-9 pl-5 d-flex justify-content-between align-items-center">
              <a className="navbar-brand logo" href="/">
                <img
                  src={briefcase1}
                  alt="Logo"
                  width="40"
                  height="40"
                  className="d-inline-block align-text-top mr-2"
                />
                <span className="logo-name">Neverhunt</span>
                <span className="beta">Beta</span>
              </a>
            </div>
            <div className="col-3 col-sm-3 col-md-4 col-lg-3 col-xl-3 d-flex justify-content-between align-items-center ">
              <div className="d-grid gap-2 d-md-flex justify-content-md-start align-items-end ">
              <button className="btn btn-outline-primary d-none d-md-block" onClick={handleClickLogin}>
                  Sign In
                </button>
                <button className="btn btn-outline-primary d-none d-md-block up" onClick={handleClickSignup}>
                  Sign Up
                </button>
                {/* <button className="btn btn-primary d-none d-md-block" onClick={handleClickSignup}>
                  Sign Up
                </button> */}
                <button
                  className="btn btn-outline-primary d-md-none up"
                  onClick={toggleMenu}
                >
                  ☰
                </button>
              </div>
            </div>
          </div>
          {isMenuOpen && (
        <div className="overlay" onClick={toggleMenu}>
          <div className="mobile-menu">
            <div className="cross-logo">
              <button className="close-button" onClick={toggleMenu}>
                &#215;
              </button>

              <a className="navbar-brand logo" href="#">
                <img
                  src={briefcase1}
                  alt="Logo"
                  width="40"
                  height="40"
                  className="d-inline-block align-text-top mr-2"
                />
                <span className="logo-name">Neverhunt</span>
              </a>
            </div>
            <ul className="menu-options">
              <li onClick={handleClickLogin}> Login</li>
              <li onClick={handleClickSignup}>Sign Up</li>
              <li>Profile</li>
            </ul>
          </div>
        </div>
      )}
        </div>
      
      </header>

      <section className="banner-section">
        <div className="rt-single-banner5">
          <div className="container position-parent">
            <div className="row">
              <div className="col-xl-6">
                <div className="banner-content5">
                  <div
                    className="mx-727"
                    style={containerStyle}
                    data-aos="fadeindown"
                    data-aos-duration="1000"
                  >
                    <h1 style={headingStyle}>
                    Simple. Smart. Hired
                    </h1>
                    <div style={subheadingStyle}>
                    AI-powered job and talent match platform for IT staffing and consulting world
                    </div>
                  </div>
                  <div>
                    <button className="btn btn-outline-primary up" onClick={handleClickSignup}>Sign Up</button>
                    {/* <button className="btn btn-outline-primary d-none d-md-block" onClick={handleClickSignup}>
                  Signup
                </button> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 d-flex align-items-center">
                <div className="banner-mockup d-none d-xl-block w-100 text-right">
                  <div className="addimg-1 position-parent video-btn-center">
                    <img src={man} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="working-process tw-bg-[#F1F2F4]">
        <div class="rt-spacer-100 rt-spacer-md-50"></div>
        <div className="container">
          <div className="row">
            <div class="col-12 text-center text-h4 ft-wt-5">
              <label for="">Why  Neverhunt</label>
            </div>
          </div>
          <div class="rt-spacer-50"></div>
          <div className="row">
            <div className="col-lg-4 col-sm-6 rt-mb-24 hoverEffect">
              <div class="rt-single-icon-box working-progress icon-center">
                <div class="icon-thumb rt-mb-24">
                  <div class="icon-72">
                  <Handshake size={40} color="#186ECF" />
                  </div>
                </div>
                <div class="iconbox-content">
                  <div class="body-font-2 rt-mb-12">Simple</div>
                  <div class="body-font-4 text-gray-400">
                  We've transformed the complexity of finding the right fit talent into a few clicks, No lengthy forms or Boolean searches. With our platform, you can focus on what matters most - finding the right talent fast
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 rt-mb-24 hoverEffect">
              <div class="rt-single-icon-box working-progress icon-center">
                <div class="icon-thumb rt-mb-24">
                  <div class="icon-72">
                  <Check size={40} color="#186ECF" />
                  </div>
                </div>
                <div class="iconbox-content">
                  <div class="body-font-2 rt-mb-12">Flexible</div>
                  <div class="body-font-4 text-gray-400">
                  You can source resumes from anywhere, whether it's an applicant through Neverhunt, job board, a career website, or even social media. Neverhunt's AI-powered engine can process all those resumes giving you unparalleled flexibility and access to a broader range of candidates.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 rt-mb-24 hoverEffect">
              <div class="rt-single-icon-box working-progress icon-center">
                <div class="icon-thumb rt-mb-24">
                  <div class="icon-72">
                  <UserList size={40} color="#186ECF" />
                  </div>
                </div>
                <div class="iconbox-content">
                  <div class="body-font-2 rt-mb-12">Fast</div>
                  <div class="body-font-4 text-gray-400">
                  With our AI-driven matching engine, you can get top talent matches in just a few seconds - no technical or domain expertise required. Our platform does the heavy lifting for you, analyzing resumes and job requirements to produce the most relevant matches
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="col-12 rt-mb-24 tw-text-center">
              <p class="body-font-3">Have a question ?</p>
              <a href="" className="contact-us">
                Contact us
              </a>
            </div> */}
          </div>
        </div>
        <div class="rt-spacer-100 rt-spacer-md-50"></div>
      </div>

      {/* <div className="working-process tw-bg-[#F1F2F4] how-bg">
        <div className="rt-spacer-50 rt-spacer-md-50"></div>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center text-h4 ft-wt-5 ">
              <label htmlFor="" className="pr-3">
                How
              </label>
              <span className="text-primary-500 has-title-shape">
                Neverhunt
                
              </span>

              <label htmlFor="" className="pl-3">
                Works
              </label>
            </div>
          </div>
          <div className="rt-spacer-50"></div>
          <div className="row">
            <div className="col-lg-3 col-sm-6 rt-mb-24 position-relative hoverEffect">
              <div className="d-none d-sm-flex has-arrow first">
                <img
                  src="https://jobpilot.templatecookie.com/frontend/assets/images/all-img/arrow-1.png"
                  alt=""
                  draggable="false"
                />
              </div>
              <div className="rt-single-icon-box working-progress icon-center">
                <div className="icon-thumb rt-mb-24">
                  <div className="icon-72">
                    <UserPlus size={40} color="#186ECF" />
                  </div>
                </div>
                <div className="iconbox-content">
                  <div className="body-font-2 rt-mb-12">Create job post</div>
                  <div className="body-font-4 text-gray-400">
                  Let us know your requirements for tech talent through neverhunt.io
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 rt-mb-24 col-sm-6 position-relative hoverEffect">
              <div className="d-none d-sm-flex has-arrow middle">
                <img
                  src="https://jobpilot.templatecookie.com/frontend/assets/images/all-img/arrow-2.png"
                  alt=""
                  draggable="false"
                />
              </div>
              <div className="rt-single-icon-box working-progress icon-center">
                <div className="icon-thumb rt-mb-24">
                  <div className="icon-72">
                    <CloudArrowUp size={40} color="#186ECF" />
                  </div>
                </div>
                <div className="iconbox-content">
                  <div className="body-font-2 rt-mb-12">Share</div>
                  <div className="body-font-4 text-gray-400">
                  Receive 5 highly relevant talent profiles based on our AI+Human matching.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 rt-mb-24 col-sm-6 position-relative hoverEffect">
              <div className="d-none d-sm-flex has-arrow last">
                <img
                  src="https://jobpilot.templatecookie.com/frontend/assets/images/all-img/arrow-1.png"
                  alt=""
                  draggable="false"
                />
              </div>
              <div className="rt-single-icon-box working-progress icon-center">
                <div className="icon-thumb rt-mb-24">
                  <div className="icon-72">
                    <MagnifyingGlassPlus size={40} color="#186ECF" />
                  </div>
                </div>
                <div className="iconbox-content">
                  <div className="body-font-2 rt-mb-12">Interview</div>
                  <div className="body-font-4 text-gray-400">
                  Neverhunt will facilitate the interviewing process between the shortlisted profiles and your team.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 rt-mb-24 col-sm-6 hoverEffect">
              <div className="rt-single-icon-box working-progress icon-center">
                <div className="icon-thumb rt-mb-24">
                  <div className="icon-72">
                    <CircleWavyCheck size={40} color="#186ECF" />
                  </div>
                </div>
                <div className="iconbox-content">
                  <div className="body-font-2 rt-mb-12">Present to client</div>
                  <div className="body-font-4 text-gray-400">
                  We will take care of the paperwork required to hire the talent.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rt-spacer-50 rt-spacer-md-50"></div>
      </div> */}

      <footer>
        <div className=" d-flex justify-content-between align-items-center">
          <a className="navbar-brand logo" href="#">
            {/* <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_41_120)">
                <path
                  d="M33.7512 11.25H6.25121C5.56086 11.25 5.00121 11.8096 5.00121 12.5V32.5C5.00121 33.1904 5.56086 33.75 6.25121 33.75H33.7512C34.4416 33.75 35.0012 33.1904 35.0012 32.5V12.5C35.0012 11.8096 34.4416 11.25 33.7512 11.25Z"
                  stroke="white"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M26.25 11.25V8.75C26.25 8.08696 25.9866 7.45107 25.5178 6.98223C25.0489 6.51339 24.413 6.25 23.75 6.25H16.25C15.587 6.25 14.9511 6.51339 14.4822 6.98223C14.0134 7.45107 13.75 8.08696 13.75 8.75V11.25"
                  stroke="white"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M35.0013 19.7358C30.4423 22.3734 25.2669 23.7583 20 23.75C14.734 23.7583 9.5594 22.3739 5.00102 19.7371"
                  stroke="white"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.125 18.75H21.875"
                  stroke="white"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_41_120">
                  <rect width="40" height="40" fill="white" />
                </clipPath>
              </defs>
            </svg> */}

            {/* <span className="name">Neverhunt</span> */}
          </a>
        </div>
        <div>
        Copyright © 2024 Neverhunt All rights reserved.
        </div>
        <div>
          Privacy Policy
        </div>
        <div>
          Terms & Conditions
        </div>
        <div>
         {/* <img src={Vector} alt="" /> */}
        </div>
      </footer>
    </>
  );
}

export default Home;