import React, { useEffect, useState } from 'react';
import AdminSidebar from './AdminSidebar';
import Navbar from './Navbar';

function LlmKpi() {
    const [kpiData, setKpiData] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/token/getkpi`, {
            method: "GET",
        })
        .then((res) => res.json())
        .then((data) => {
            console.log("data of kpi", data.msg);
            setKpiData(data.msg);  // assuming data.msg contains the array of KPI data
        })
        .catch((error) => {
            console.error("Error fetching KPI data:", error);
        });
    }, []);

    const calculateAverageQdrantScore = (kpiData) => {
        let totalScore = 0;
        let totalIndices = 0;

        kpiData.forEach((entry) => {
            entry.kpiStats.forEach((kpi) => {
                const ratio = kpi.resumes_processed === 0 ? 0 : kpi.good_matches / kpi.resumes_processed;
                totalScore += ratio;
                totalIndices += 1;
            });
        });

        return totalIndices === 0 ? 0 : totalScore / totalIndices;
    };

    const totalEntries = kpiData.reduce(
        (sum, entry) => sum + entry.kpiStats.length,
        0
    );

    const additionalCalculation = kpiData.reduce(
        (sum, entry) =>
            sum +
            entry.kpiStats.reduce(
                (innerSum, kpi) => innerSum + kpi.good_matches + kpi.resumes_processed,
                0
            ),
        0
    ) / totalEntries;

    const toggleRow = (index) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const averageQdrantScore = calculateAverageQdrantScore(kpiData);
    const numberOfEntries = totalEntries;

    return (
        <div className="MainContainer">
            <div style={{ height: "70px" }}>
                <Navbar />
            </div>
            <hr />
            <div className="container second-main">
                <div className="row">
                    <div className="col-1 col-lg-3 sidebarDiv">
                        <AdminSidebar />
                    </div>
                    <div className="col-lg-9">
                        <div className="content">
                            <h2>Token Statistics</h2>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Good Matches</th>
                                        <th>Resumes Processed</th>
                                        <th>Expand</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {kpiData.map((entry, index) => (
                                        <React.Fragment key={entry._id}>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {entry.kpiStats.reduce(
                                                        (sum, kpi) => sum + kpi.good_matches,
                                                        0
                                                    )}
                                                </td>
                                                <td>
                                                    {entry.kpiStats.reduce(
                                                        (sum, kpi) => sum + kpi.resumes_processed,
                                                        0
                                                    )}
                                                </td>
                                                <td>
                                                    <button
                                                        onClick={() => toggleRow(index)}
                                                        style={{ border: 'none', background: 'none' }}
                                                    >
                                                      {expandedRows[index] ?  '👉' : '👇' }
                                                    </button>
                                                </td>
                                            </tr>
                                            {expandedRows[index] && (
                                                entry.kpiStats.map((kpi, subIndex) => (
                                                    <tr key={`${entry._id}-${subIndex}`}>
                                                        <td colSpan="4">
                                                            <div style={{ paddingLeft: '20px' }}>
                                                                <strong>Index {subIndex + 1}:</strong> 
                                                                Good Matches: {kpi.good_matches}, 
                                                                Resumes Processed: {kpi.resumes_processed}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                            <div>
                                <p>Average Qdrant Score: {averageQdrantScore.toFixed(2)}</p>
                                {/* <p>Additional Calculation: {additionalCalculation.toFixed(2)}</p> */}
                                <p>Number of Entries: {numberOfEntries}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dashboard-footer text-center body-font-4 text-gray-500">
                Copyright © 2024 Neverhunt All rights reserved.
            </div>
        </div>
    );
}

export default LlmKpi;
