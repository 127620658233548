import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import "../styles/SearchResumes.css";
import { saveAs } from "file-saver";
import fi_upload from "../Image/fi_upload.svg";
import downloadIcon from "../Image/Frame 31375.svg";
import eyeIcon from "../Image/Button.svg";
import "react-circular-progressbar/dist/styles.css";
import cross_icon from "../Image/coss_icon.png";
import { jsonrepair } from "jsonrepair";
import parseJson, { JSONError } from "parse-json";
import HalfCircleGauge from "./HalfCircleGauge";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DOMPurify from "dompurify";

function SearchResumes() {
  
  const [searchMessage, setSearchMessage] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [disableUpload, setDisableUpload] = useState(false);
  const [selectedResume, setSelectedResume] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resumes, setResumes] = useState([]);
  const [Jobs, setJobs] = useState([]);
  const [selectedJobId, setSelectedJobId] = useState("");
  const [JobDescription, setJobDescription] = useState("");
  const [renderDocx, setRenderDocx] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenjd, setIsModalOpenjd] = useState(false);
  const [temp, setTemp] = useState(false);
  const [dbmatch, setDbmatch] = useState(false);
  const [responsemsg, setResponsemsg] = useState(
    "Please select a job from the dropdown above or post a job"
  );
  const [uploadCount, setUploadCount] = useState("");

  const [resumeURL, setResumeURL] = useState("");
  const [resumeload, setResumeload] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { hostname, protocol } = window.location;
  const baseUrl = `${protocol}//${hostname}`;
  const [isCopied, setIsCopied] = useState(false);

  const token = localStorage.getItem("token");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingText, setLoadingText] = useState("");
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [searchDisabled, setSearchDisabled] = useState(true);
  const [gotData, setGotData] = useState([]);
  const [viewedItems, setViewedItems] = useState({});
  const [avgTime, setAvgTime] = useState(0);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [loadmsg, setLoadmsg] = useState(false);
  const [dbMatchexe, setDbMatchexe] = useState(false);
  
  const [processedResults, setProcessedResults] = useState([]);
  const [selectedJobDetails, setSelectedJobDetails] = useState({
    position: "",
    description: "",
  });
  const [expandedCard, setExpandedCard] = useState(null);
  const [showAllResults, setShowAllResults] = useState(false);

  const [dbMatchExecuted, setDbMatchExecuted] = useState(false); // New state variable

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  useEffect(() => {
    setTemp(true);
  
  }, []);

  //to set the avg Time
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getTime`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("check Time: ", data.msg);
        setAvgTime(data.msg.avgTime);
        setTimeout(() => {
          console.log("check Time: ", avgTime);
        }, 3000);
      });
  }, []);

  useEffect(() => {
    async function processResults() {
      try {
        const results = await Promise.all(
          searchResults.map(async (result) => {
            try {
              const summaryData = await extractAnalysisAndScore(
                result[0].summary
              );
              console.log("fileRes", result[0].filename);
              return {
                ...summaryData,
                filename: result[0]?.filename,
                id: result?.id,
                best_match: result?.best_match,
                status: result?.status || "new",
              };
            } catch (error) {
              console.error("Error processing result:", error);
              fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/log/logError`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  message: `error at extractAnalysis function ${error.message}`,
                }),
              })
                .then((res) => res.json())
                .then((data) => {
                  console.log("data: " + data.msg);
                });
              return null;
            }
          })
        );

        const filteredResults = results.filter((result) => result !== null);
        console.log("All and filtered results", filteredResults);

        setTimeout(() => {
          const resumeData = new FormData();
          resumeData.append("job_des", JobDescription);
          resumeData.append("resumes", JSON.stringify(filteredResults));

          if (resumes.length > 0) {
            fetch(
              `${process.env.REACT_APP_PYTHON_BASE_URL}/all_resumes_filter`,
              {
                method: "POST",
                body: resumeData,
              }
            )
              .then((res) => res.json())
              .then((data) => {
                console.log("got data", data);
                const jsonRegex = /{[\s\S]*}/;
                const match = data.match(jsonRegex);
                const jsonString = match[0];
                console.log("jsonString", jsonString);
                const repairedJson = jsonrepair(jsonString);
                const parsedData = parseJson(repairedJson);
                console.log("checking access of ids", parsedData.ids);
                setGotData(parsedData.ids);
                const updatedResults = filteredResults?.map((item) => {
                  console.log("id checked", item.id);
                  console.log("gottid checked", gotData);
                  if (parsedData?.ids) {
                    console.log("came inside of best match");
                    const bestMatch =
                      parsedData.ids?.includes(item.id) ||
                      parsedData.ids?.includes(item.filename);
                    console.log("best match", bestMatch);
                    return { ...item, best_match: bestMatch };
                  }
                });
                fetch(
                  `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/secondsave`,
                  {
                    method: "POST",
                    headers: {
                      "content-type": "application/json",
                    },
                    body: JSON.stringify({
                      processedData: updatedResults,
                      jobid: selectedJobId,
                    }),
                  }
                )
                  .then((res) => res.json())
                  .then((data) => {
                    console.log("data.msg");
                  })
                  .catch((err) => {
                    fetch(
                      `${process.env.REACT_APP_BACKEND_BASE_URL}/log/logError`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          message: `error while saving results second time ${err.message}`,
                        }),
                      }
                    )
                      .then((res) => res.json())
                      .then((data) => {
                        console.log("data: " + data.msg);
                      });
                  });
                setProcessedResults(updatedResults);
                setLoading(false);
                console.log("All and results", updatedResults);
              });
          } else {
            setProcessedResults(filteredResults);
          }
        }, 500);
      } catch (error) {
        console.error("Error processing all results:", error);
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/log/logError`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: `error setprocessed function ${error.message}`,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log("data: " + data.msg);
          });
      }
    }

    if (searchResults.length > 0) {
      processResults();
    }
  }, [searchResults]);

  // Effect to handle the timer
  useEffect(() => {
    console.log("Component mounted");
    setTimeElapsed(1); // Ensure it starts from 1

    const timer = setInterval(() => {
      setTimeElapsed((prev) => {
        return prev + 1;
      });
    }, 1000);

    return () => {
      console.log("Clearing interval");
      clearInterval(timer);
    }; // Clear the timer on unmount
  }, [loading]);

  // Effect to update loading text

  useEffect(() => {
    // Convert avgTime from string to number
    const timeInSeconds = parseFloat(avgTime);

    // Check if the conversion was successful
    if (!isNaN(timeInSeconds)) {
      const totalTimeInSeconds = timeInSeconds * resumes.length * 60;
      const totalTimeInMinutes = (totalTimeInSeconds / 60).toFixed(2);

      let message;
      if (totalTimeInSeconds < 60) {
        message = "less than a minute";
      } else {
        message = `${totalTimeInMinutes} minutes`;
      }
      setLoadingText(
        `Time elapsed: ${timeElapsed} seconds. Results will be available in approx. ${message}`
      );
    } else {
      setLoadingText(
        `Time elapsed: ${timeElapsed} seconds. Invalid avgTime value.`
      );
    }
  }, [timeElapsed, avgTime, resumes.length]);
  const handleSearch = async (e) => {
    e.preventDefault();

    setLoading(true);
  };

  function debounce(func, wait) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }

  const handleViewJob = () => {
    setIsModalOpenjd(true);
  };

  const handleDownload = (filename, resumeText) => {
    const fileurl = `${process.env.REACT_APP_BACKEND_BASE_URL}/ResumeUploads/${filename}`;
    // Implement download logic here
    fetch(fileurl)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, filename);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  async function convertDocxToPdf(docxUrl) {
    try {
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/viewDocx`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          filename: docxUrl,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("pdfBlob: ", data.msg);
          setRenderDocx(true);
        })
        .catch((err) => {
          console.error("Error converting DOCX to PDF:", err);
        });
    } catch (error) {
      console.error("Error converting DOCX to PDF:", error);
    }
  }

  const handleViewResume = (resumeText) => {
    setSelectedResume(resumeText);
    console.log("resume filename", resumeText);
    setIsModalOpen(true);
    const fileExtension = resumeText.split(".").pop();
    if (fileExtension === "pdf") {
      setResumeURL(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/ResumeUploads/${resumeText}`
      );
      return (
        <iframe
          title="PDF Viewer"
          src={`${process.env.REACT_APP_BACKEND_BASE_URL}/ResumeUploads/${resumeText}`}
          width="800"
          height="600"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      );
    } else {
      const pdfFileName = resumeText.replace(".docx", ".pdf");

      setResumeURL(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/Resumes/${pdfFileName}`
      );
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleCloseModaljd = () => {
    setIsModalOpenjd(false);
  };

  function renderViewer(selectedResume) {
    const fileExtension = selectedResume.split(".").pop();
    if (fileExtension === "pdf") {
      return (
        <iframe
          title="PDF Viewer"
          src={`${process.env.REACT_APP_BACKEND_BASE_URL}/ResumeUploads/${selectedResume}`}
          width="1200" // Set iframe width to 100%
          height="920"
          frameBorder="0"
          allowFullScreen
          style={{ maxWidth: 1200 }} // Set maximum width using inline style
        ></iframe>
      );
    } else if (fileExtension === "docx") {
      console.log("docx extension");
      convertDocxToPdf(selectedResume);
      const pdfFileName = selectedResume.replace(".docx", ".pdf");
      return renderDocx ? (
        <iframe
          title="PDF Viewer"
          src={`${process.env.REACT_APP_BACKEND_BASE_URL}/Resumes/${pdfFileName}`}
          width="1200"
          height="920"
          frameBorder="0"
          marginwidth="0"
          allowFullScreen
        ></iframe>
      ) : null;
    } else {
      return <p>Unsupported file format</p>;
    }
  }

  useEffect(() => {
    const handleClickOutsideModal = (e) => {
      console.log("clicked outside modal");
      // Check if the clicked element is outside the modal
      if (e.target.className === "overlay") {
        setSelectedResume(null);
        setIsModalOpen(false);
        // Close the modal
      }
    };

    // Add event listener when the modal is open
    if (isModalOpen) {
      document.addEventListener("click", handleClickOutsideModal);
    }

    // Cleanup: remove event listener when the modal is closed
    return () => {
      document.removeEventListener("click", handleClickOutsideModal);
    };
  }, [isModalOpen]);

  const extractAnalysisAndScore = async (summary, attempt = 0) => {
    try {
      const repairedJson = jsonrepair(summary);
      console.log("repaired json: ", repairedJson);
      const summaryObject = parseJson(repairedJson);
      console.log("summaryobject", summaryObject);

      let reasoning = summaryObject.Reasoning || "No Reasoning available!";
      const secondJson = jsonrepair(reasoning);

      // Function to ensure that the reasoning string has matching opening and closing braces
      reasoning = ensureValidJsonStructure(reasoning);

      const reasoningObject = parseJson(secondJson);
      console.log("reasoningobject", reasoningObject);

      return {
        reasoning: reasoningObject?.reasoning,
        score:
          reasoningObject?.overall_match_score ||
          reasoningObject?.overall_match_score,
        fit: reasoningObject?.fit,
        experience: reasoningObject?.total_experience,
        skills: reasoningObject?.skills,
        industries:
          reasoningObject?.industries || reasoningObject?.domain_experience,
        first_name: reasoningObject?.first_name,
        location: reasoningObject?.location,
        work_auth: reasoningObject?.work_authorization,
        emp_type: reasoningObject?.employment_type,
        res_summary: reasoningObject?.responsibilities_summary,
      };
    } catch (error) {
      console.error("Error extracting analysis and score:", error);
      if (attempt < 5) {
        return retryCorrection(summary, attempt++);
      } else {
        return fallbackReturn();
      }
    }
  };

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  function ensureValidJsonStructure(jsonStr) {
    let openBraces = (jsonStr.match(/{/g) || []).length;
    let closeBraces = (jsonStr.match(/}/g) || []).length;

    // If opening braces are more than closing, add the necessary closing braces
    while (openBraces > closeBraces) {
      jsonStr += "}";
      closeBraces++;
    }

    // Optionally, handle cases where there are too many closing braces
    while (closeBraces > openBraces) {
      jsonStr = "{" + jsonStr;
      openBraces++;
    }
    return jsonStr;
  }

  async function retryCorrection(summary, attempt) {
    const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
    try {
      const response = await fetch(
        "https://api.deepinfra.com/v1/openai/chat/completions",
        {
          method: "POST",
          body: JSON.stringify({
            model: "meta-llama/Meta-Llama-3-70B-Instruct",
            messages: [
              {
                role: "user",
                content: `Please correct this data format error into JSON: ${summary}`,
              },
            ],
          }),
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${API_KEY}`,
          },
        }
      );
      const data = await response.json();
      const correctedData = data.choices[0].message.content;
      return await extractAnalysisAndScore(correctedData, attempt + 1);
    } catch (networkError) {
      console.error("Network or parsing failed:", networkError);
      return null;
    }
  }

  function fallbackReturn() {
    return {
      reasoning: "No Reasoning available!!",
      experience: 0,
      companies_worked: [],
      mandatory_requirements: [],
      name: "",
      position_interested: [],
      social_media: [],
      skillKeywords: [],
      industries: [],
    };
  }

  const handleMinimizeResume = () => {
    // Implement minimize resume logic here
    setSelectedResume(null);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/gettempjobs`, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setJobs(data.msg);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleResumeUpload = async (e) => {
    setDisableUpload(true);

    const acceptedFileTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ]; //mime types  of pdf and docx files
    console.log("Upload");
    let valid = true;
    const files = Array.from(e.target.files);
    setResumes([]);

    for (let i = 0; i < files.length; i++) {
      if (!acceptedFileTypes.includes(files[i].type)) {
        valid = false;
        break;
      }
    }

    if (!valid) {
      setUploadCount("Only PDF and DOCX files are accepted.");
      return;
    }
    setResumeload(true);

    setResumes((prevResumes) => [...prevResumes, ...files]);

    setTimeout(() => {
      console.log("Upload", resumes);
    }, 3000);
  };

  useEffect(() => {
    console.log("into upload resumes");
    const uploadResumes = async () => {
      const forminfo = new FormData();
      resumes.forEach((resume, index) => {
        forminfo.append(`resumes`, resume);
      });
      forminfo.append("jobid", selectedJobId);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/uploadResumes`,
          {
            method: "POST",
            headers: {
              Authorization: token,
            },
            body: forminfo,
          }
        );

        // Handle response
        if (response.ok) {
          setResumeload(false);
          console.log("Files uploaded successfully");
          setUploadCount(`${resumes.length} file(s) uploaded successfully!`);
          setTimeout(() => {
            setUploadCount("");
          }, 4000);

          setTimeout(async () => {
            setSearchResults([]);
            setLoading(true);
            const formData = new FormData();
            formData.append("jobdesc", JobDescription);
            console.log("jd");

            // Check if resumes array is defined and not empty
            if (resumes && resumes.length > 0) {
              resumes.forEach((resume, index) => {
                formData.append(`resumes`, resume);
              });
            } else {
              setLoading(false);
              return;
            }
            // https://neverhunt-python.apps.neverhunt.io/get_resume
            try {
              // Store total file count
              const totalFilesUploaded = resumes.length;
              let startTime = Date.now();
              console.log("startTime: ", startTime);
              const response = await fetch(
                `${process.env.REACT_APP_PYTHON_BASE_URL}/get_resume`,
                {
                  method: "POST",
                  body: formData,
                }
              );
              const data = await response.json();
              if (data.answer && data.answer.length > 0) {
                let endTime = Date.now();
                let totalTime = (endTime - startTime) / 1000;
                console.log("tt", totalTime);

                let avgTimeInMinutes = totalTime / totalFilesUploaded / 60;
                console.log(
                  `Average time per resume: ${avgTimeInMinutes.toFixed(
                    2
                  )} minutes (${(avgTimeInMinutes * 60).toFixed(2)} seconds)`
                );
                // Store the average time
                fetch(
                  `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/avgTime`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      avgTime: avgTimeInMinutes,
                      totalFiles: totalFilesUploaded,
                    }),
                  }
                )
                  .then((res) => res.json())
                  .then((data) => {
                    console.log("Average time stored:", data.msg);
                  });
                console.log("dataAnswer: ", data.answer);
                const lastArray = data.answer[data.answer.length - 1];

                // fetch request to save the results
                fetch(
                  `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/ResumeResults`,
                  {
                    method: "POST",
                    headers: {
                      "content-type": "application/json",
                    },
                    body: JSON.stringify({
                      uniqueJobID: selectedJobId,
                      matchingData: data.answer,
                    }),
                  }
                )
                  .then((res) => res.json())
                  .then((data) => {
                    setDisableUpload(true);

                    console.log("data saved", data.msg);
                    //${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getallsavedresumes/${selectedJobId}
                    fetch(
                      `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getallsavedresumes/${selectedJobId}`,
                      {
                        method: "GET",
                      }
                    )
                      .then((res) => res.json())
                      .then((datao) => {
                        console.log("getting all data", datao.msg);
                        if (datao.msg) {
                          const outputData = datao.msg;
                          setSearchResults((prevResults) =>
                            prevResults.concat(outputData.matchingData)
                          );
                        }
                      })
                      .catch((err) => {
                        console.log("error getting", err.message);
                        fetch(
                          `${process.env.REACT_APP_BACKEND_BASE_URL}/log/logError`,
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              message: `error while getting saved resumes ${err.message}`,
                            }),
                          }
                        )
                          .then((res) => res.json())
                          .then((data) => {
                            console.log("data: " + data.msg);
                          });
                      });
                  })
                  .catch((err) => {
                    console.log(err.message);
                    fetch(
                      `${process.env.REACT_APP_BACKEND_BASE_URL}/log/logError`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          message: `error while storing results ${err.message}`,
                        }),
                      }
                    )
                      .then((res) => res.json())
                      .then((data) => {
                        console.log("data: " + data.msg);
                      });
                  });
              } else {
                console.log("No data received from the server.");
              }
            } catch (err) {
              console.log("Error:", err.message);
              fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/log/logError`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  message: `error from python backend ${err.message}`,
                }),
              })
                .then((res) => res.json())
                .then((data) => {
                  console.log("data: " + data.msg);
                });
            }
          }, 4000);
        } else {
          console.error("Failed to upload files");
        }
      } catch (err) {
        console.error("Error:", err);
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/log/logError`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: `error from main resume useState ${err.message}`,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log("data: " + data.msg);
          });
      }
    };

    uploadResumes();
  }, [resumes]);

  function stripHtml(html) {
    let doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }
  //${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getallsavedresumes/${selectedJobId}
  useEffect(() => {
    setSearchResults([]);
    setProcessedResults([]);
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getallsavedresumes/${selectedJobId}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("getting all data", data.msg.matchingData);
        if (data.msg.matchingData) {
          const outputData = data.msg;
          // setProcessedResults(data.msg.matchingData)
          setSearchResults((prevResults) =>
            prevResults.concat(outputData.matchingData)
          );
        } else {
          setResponsemsg("Please upload resume(s) to see results");
        }
      })
      .catch((err) => {
        console.log("error getting", err.message);
      });
  }, [searchMessage]);

  const handleSearchMessage = (e) => {
    setSearchMessage(e.target.value);

    // Check if the selected value is not empty, which means a job is selected
    if (e.target.value) {
      setUploadDisabled(false);
      setSearchDisabled(false);
      setDbMatchExecuted(false); // Reset dbMatchExecuted when a new job is selected
      const selectedJob = Jobs.find((job) => job?.position === e.target.value);

      if (selectedJob) {
        setSelectedJobId(selectedJob._id);
        const plainTextDescription = stripHtml(selectedJob.parsedjd);
        setJobDescription(plainTextDescription);
        setSelectedJobDetails({
          position: selectedJob.position,
          description: selectedJob.jobDescription,
        });
      }
    } else {
      // Handle the "Select a Job" option: clear the job details and results, disable upload and search
      setSelectedJobId("");
      setJobDescription("");
      setSelectedJobDetails({
        position: "",
        description: "",
      });
      setSearchResults([]);
      setUploadDisabled(true);
      setSearchDisabled(true);
      setResponsemsg(
        "Please select a job from the dropdown above or post a job"
      );
    }

    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/checkdbmatch/${selectedJobId}`, {
      method: "GET",
      
    }).then((res)=> res.json())
    .then((data)=>{
      console.log("dbmatch status", data.msg);
      setDbMatchexe(data.msg)
    })

    setTimeout(() => {
      console.log("viewing db staste", dbMatchexe)
    }, 3000);

    e.target.blur();
  };

  const handleSearchreload = (e) => {
    e.preventDefault();
  };

  const handleToggleCard = async (index, id) => {
    setTemp(false);

    const updatedResults = processedResults.map((result, i) =>
      i === index ? { ...result, status: "viewed" } : result
    );
    setProcessedResults(updatedResults);

    setExpandedCard((prevIndex) => (prevIndex === index ? null : index));

    setViewedItems((prevState) => ({ ...prevState, [index]: true }));
    setTimeout(() => {
      console.log("setViewedItems", viewedItems);
    }, 3000);
    //${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/updateViewStatus/${id}/${selectedJobId}
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/updateViewStatus/${id}/${selectedJobId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Status updated successfully:", data.msg);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handledbMatch = () => {
    let formPy = new FormData();
    setDbmatch(true);

    const parsedJobDescription = JSON.parse(JobDescription);

    // Access the zipCode property
    const zipCode = parsedJobDescription.zipCode;

    //${process.env.REACT_APP_BACKEND_BASE_URL}/applyjob/getzipcodes
    console.log("got zipCode", zipCode);
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/applyjob/getzipcodes`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        centralZip: zipCode,
        radiusInMiles: 50,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("list of zip codes", data.msg);
        formPy.append("locations", JSON.stringify(data.msg));
        formPy.append("sum_jd", JSON.stringify(JobDescription));
        fetch(`${process.env.REACT_APP_PYTHON_BASE_URL}/agent_jd`, {
          method: "POST",
          body: formPy,
        })
          .then((res) => res.json())
          .then((data) => {
            //`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/ResumeResults`
            let backobj = {
              uniqueJobID: selectedJobId,
              matchingData: data.answer,
            };
            console.log("backobj", backobj);
            fetch(
              `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/ResumeResults`,
              {
                method: "POST",
                headers: {
                  "content-type": "application/json",
                },
                body: JSON.stringify(backobj),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                console.log("saved successfully nav", data.msg);
                fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/sendMail`, {
                  method: "GET",
                  headers: {
                    "content-type": "application/json",
                    Authorization: token
                  },
                })
                .then((res)=> res.json())
                .then((data)=>{
                  console.log(data.msg)
                  fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/checkdbmatch`, {
                    method: "POST",
                    headers: {
                      "content-type": "application/json"
                    },
                    body: JSON.stringify({
                      jobid: selectedJobId,
                      isClicked: true,
                    })
                  })
                  .then((res)=> res.json())
                  .then((data)=>{
                    console.log(data.msg)
                  })
                })
                //  window.location.reload();
                setDbmatch(false);
                setDbMatchExecuted(true); // Set dbMatchExecuted to true after DB match
              });
          });
      });
  };

  const handleToggleShowAllResults = () => {
    setShowAllResults((prevShowAllResults) => !prevShowAllResults);
  };

  const filteredResults = showAllResults
    ? processedResults.filter((result) => result.score > 4)
    : processedResults;

  return (
    <div className="MainContainer">
      <div style={{ height: "70px" }}>
        <Navbar />
      </div>

      <hr />
      <div className="container second-main">
        <div className="row">
          <div className="col-1 col-lg-3 sidebarDiv">
            <Sidebar />
          </div>
          <div className="col-lg-9">
            <div className="container  overBox">
              <div>
                <form
                  onSubmit={handleSearchreload}
                  style={{ display: "flex", gap: "2px" }}
                >
                  <label
                    style={{
                      height: "35px",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "20px",
                    }}
                  >
                    {/* Search */}
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "350px",
                    }}
                  >
                    <div className="job-select-container">
                      <select
                        value={searchMessage}
                        onChange={handleSearchMessage}
                        onFocus={(e) => (e.target.size = 6)}
                        onBlur={(e) => (e.target.size = 0)}
                        className="form-select job-select"
                      >
                        <option className="job-option" value="">
                          Select a Job{" "}
                        </option>
                        {Jobs?.map((job, index) => (
                          <option
                            className="job-option"
                            key={index}
                            value={job.position}
                          >
                            {job.position}
                          </option>
                        ))}
                      </select>
                    </div>

                    {searchDisabled ? (
                      ""
                    ) : (
                      <span
                        style={{
                          padding: "5px",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          //  color: "white",
                          height: "36px",
                          width: "80px",
                        }}
                        onClick={handleViewJob}
                      >
                        View Job
                      </span>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "270px",
                    }}
                  >
                    {searchDisabled ? (
                      ""
                    ) : (
                      <span
                        style={{
                          marginLeft: "-60px",
                          cursor: dbmatch ? "not-allowed" : "pointer",
                          visibility: dbMatchExecuted ? "hidden" : "visible" // Hide the DB Match button if executed
                        }}
                        onClick={handledbMatch}
                      >
                       {!dbMatchexe ? (dbmatch ? "Processing..." : "DB Match") : ""}
 
                      </span>
                    )}
                    <button
                      className="upload-button"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "22px",
                        paddingTop: "8px",
                        paddingRight: "13px",
                        paddingBottom: "8px",
                        paddingLeft: "12px",
                        borderRadius: "4px",
                        backgroundColor: "white",
                        color: "rgba(10, 101, 204, 1)",
                        display: searchDisabled ? "none" : "",
                        border: "2px solid rgba(10, 101, 204, 1)",
                        cursor: disableUpload ? "not-allowed" : "pointer",
                      }}
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                      disabled={uploadDisabled}
                    >
                      <span style={{ marginRight: "12px", fontWeight: "500" }}>
                        {resumeload ? "Upload" : "Upload"}
                      </span>
                      <img src={fi_upload} alt="" />
                    </button>
                  </div>
                  {errorMessage && (
                    <div style={{ color: "red", marginLeft: "10px" }}>
                      {errorMessage}
                    </div>
                  )}
                  <input
                    type="file"
                    id="fileInput"
                    multiple
                    onChange={(e) => handleResumeUpload(e)}
                    style={{ display: "none" }}
                  />
                  <img
                    style={{
                      marginRight: "-85px",
                      padding: "0px 25px",
                      borderRadius: "5px",
                      display: searchDisabled ? "none" : "",
                      cursor: loading ? "not-allowed" : "pointer",
                    }}
                    onClick={searchDisabled ? null : handleSearch}
                    // src={search_btn}
                    alt=""
                  />
                </form>
                {selectedJobId && searchResults.length > 0 && (
                  <div
                    className="toggle-container"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "4px",
                      // justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        marginBottom: "5px",
                      }}
                    >
                      {processedResults.length} search results
                    </span>
                    <label className="switch" style={{ margin: "0 10px" }}>
                      <input
                        type="checkbox"
                        checked={showAllResults}
                        onChange={handleToggleShowAllResults}
                      />
                      <span className="slider round"></span>
                    </label>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        marginBottom: "5px",
                      }}
                    >
                      Greater than 40% score
                    </span>
                  </div>
                )}
              </div>

              <div className="search-results">
                {(loading || loadmsg) && (
                  <div
                    className="loading-spinner"
                    style={{
                      backgroundColor: "#EEF5FC",
                      padding: "10px",
                      borderRadius: "5px",
                      marginBottom: "10px",
                      marginLeft: "30px",
                    }}
                  >
                    {loadingText}
                  </div>
                )}

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {searchResults &&
                    searchResults.length === 0 &&
                    processedResults.length === 0 &&
                    loadingText == "" && (
                      <div className="no-results-message">{responsemsg}</div>
                    )}
                  {uploadCount && (
                    <div
                      style={{ marginBottom: "5px" }}
                    >{`${uploadCount}`}</div>
                  )}
                </div>
                {/* {message && <div className="message">{message}</div>} */}
                <ul
                  className="search-results-list"
                  style={{ listStyleType: "none", padding: 0 }}
                >
                  {filteredResults &&
                    filteredResults
                      .sort((a, b) => parseInt(b?.score) - parseInt(a?.score))
                      .map((result, index) => (
                        <li
                          key={index}
                          style={{
                            marginRight: "-40px",
                            marginBottom: "20px",
                            boxShadow:
                              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                            padding: "10px 10px 1px 10px",
                            borderRadius: "5px",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "12px",
                            }}
                          >
                            <span
                              style={{
                                position: "absolute",
                                top: "10px",
                                left: "10px",
                                background:
                                  result?.status === "new"
                                    ? "white"
                                    : "transparent",
                                color:
                                  result?.status === "new" ? "#007bff" : "grey",
                                padding:
                                  result?.status === "new" ? "2px 6px" : "0",
                                border:
                                  result?.status === "new"
                                    ? "1px solid lightgrey"
                                    : "none",
                                borderRadius:
                                  result?.status === "new" ? "3px" : "0",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                            >
                              {result?.status}
                            </span>

                            {result?.best_match ? (
                              <span
                                style={{
                                  background: "rgba(255, 204, 0, 0.9)",
                                  color: "black",
                                  padding: "2px 6px",
                                  borderRadius: "3px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  marginLeft: "auto",
                                }}
                              >
                                Good Match
                              </span>
                            ) : (
                              <span
                                style={{
                                  marginLeft: "auto",
                                  visibility: "hidden",
                                }}
                              >
                                Placeholder
                              </span> // Invisible placeholder
                            )}
                          </div>
                          <div
                            className="summary"
                            style={{ marginBottom: "5px" }}
                          >
                            <span
                              style={{
                                marginLeft: "3px",

                                color: "rgba(24, 25, 28, 1)",
                                fontSize: "16px",
                                fontWeight: "600",
                                lineHeight: "20px",
                              }}
                            >
                              Name
                            </span>
                            <span style={{ fontSize: "16px" }}>
                              {" "}
                              {result?.first_name}
                            </span>
                          </div>
                          <div
                            className="score"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  marginLeft: "-25px",
                                  color: "rgba(24, 25, 28, 1)",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                }}
                              >
                                Match Score
                              </span>
                              <div
                                className="progress-bar-container"
                                style={{
                                  marginTop: "-20px",
                                  marginLeft: "-30px",
                                  marginBottom: "-18px",
                                }}
                              >
                                <HalfCircleGauge score={result?.score} />
                              </div>
                            </div>
                          </div>

                          <div
                            className="summary"
                            style={{ marginBottom: "3px" }}
                          >
                            <span
                              style={{
                                // marginLeft: "1px",
                                color: "rgba(24, 25, 28, 1)",
                                fontSize: "16px",
                                fontWeight: "600",
                                lineHeight: "20px",
                              }}
                            >
                              Analysis:
                            </span>{" "}
                            <span style={{ fontSize: "16px" }}>
                              {result?.fit} for this role
                            </span>
                          </div>
                          <div
                            className={`card-details ${
                              expandedCard === index
                                ? "card-details-expanded"
                                : ""
                            }`}
                          >
                            <div
                              className="summary"
                              style={{ marginBottom: "12px" }}
                            >
                              <span
                                style={{
                                  color: "rgba(24, 25, 28, 1)",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                }}
                              >
                                Summary:
                              </span>{" "}
                              <span style={{ fontSize: "16px" }}>
                                {result?.reasoning}
                              </span>
                            </div>

                            <div
                              className="summary"
                              style={{ marginBottom: "12px" }}
                            >
                              <span
                                style={{
                                  color: "rgba(24, 25, 28, 1)",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                }}
                              >
                                Relevant Skills:
                              </span>
                              <span
                                style={{
                                  fontSize: "16px",
                                  marginLeft: "7px",
                                  marginTop: "1px",
                                }}
                              >
                                {!result?.skills || result?.skills?.length === 0
                                  ? "none"
                                  : result?.skills.join(", ")}
                              </span>
                            </div>

                            <div
                              className="summary"
                              style={{ marginBottom: "12px" }}
                            >
                              <span
                                style={{
                                  color: "rgba(24, 25, 28, 1)",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                }}
                              >
                                Industry Experience:
                              </span>
                              <span
                                style={{
                                  fontSize: "16px",
                                  marginLeft: "7px",
                                  marginTop: "1px",
                                }}
                              >
                                {!result?.industries ||
                                result?.industries?.length === 0
                                  ? "none"
                                  : result?.industries.join(", ")}
                              </span>
                            </div>

                            <div
                              className="summary"
                              style={{ marginBottom: "12px" }}
                            >
                              <span
                                style={{
                                  color: "rgba(24, 25, 28, 1)",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                }}
                              >
                                Years of Experience:
                              </span>{" "}
                              <span style={{ fontSize: "16px" }}>
                                {result?.experience}
                              </span>
                            </div>

                            {result?.location && (
                              <div
                                className="summary"
                                style={{ marginBottom: "12px" }}
                              >
                                <span
                                  style={{
                                    color: "rgba(24, 25, 28, 1)",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                  }}
                                >
                                  Location:
                                </span>{" "}
                                <span style={{ fontSize: "16px" }}>
                                  {result?.location}
                                </span>
                              </div>
                            )}

                            {result?.work_auth && (
                              <div
                                className="summary"
                                style={{ marginBottom: "12px" }}
                              >
                                <span
                                  style={{
                                    color: "rgba(24, 25, 28, 1)",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                  }}
                                >
                                  Work Authorization:
                                </span>{" "}
                                <span style={{ fontSize: "16px" }}>
                                  {result?.work_auth}
                                </span>
                              </div>
                            )}

                            {result?.emp_type && (
                              <div
                                className="summary"
                                style={{ marginBottom: "12px" }}
                              >
                                <span
                                  style={{
                                    color: "rgba(24, 25, 28, 1)",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                  }}
                                >
                                  Employment Type:
                                </span>{" "}
                                <span style={{ fontSize: "16px" }}>
                                  {result?.emp_type}
                                </span>
                              </div>
                            )}

                            {result?.res_summary &&
                              result.res_summary.length > 0 && (
                                <div
                                  className="summary"
                                  style={{ marginBottom: "12px" }}
                                >
                                  <span
                                    style={{
                                      color: "rgba(24, 25, 28, 1)",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      lineHeight: "20px",
                                    }}
                                  >
                                    Relevant Experience:
                                  </span>
                                  <div style={{ fontSize: "16px" }}>
                                    {result.res_summary.map((item, index) => (
                                      <div
                                        key={index}
                                        style={{ marginTop: "8px" }}
                                      >
                                        <div>
                                          <span
                                            style={{
                                              color: "rgba(75, 75, 78, 1)",
                                              fontSize: "16px",
                                              fontWeight: "600",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            Company:{" "}
                                          </span>
                                          {item.company ||
                                            item.company_name ||
                                            "Not provided"}
                                        </div>
                                        <div>
                                          <span
                                            style={{
                                              color: "rgba(75, 75, 78, 1)",
                                              fontSize: "16px",
                                              fontWeight: "600",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            Position:{" "}
                                          </span>
                                          {item.position ||
                                            item.position_held ||
                                            "Not provided"}
                                        </div>
                                        <div>
                                          <span
                                            style={{
                                              color: "rgba(75, 75, 78, 1)",
                                              fontSize: "16px",
                                              fontWeight: "600",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            Dates Worked:{" "}
                                          </span>
                                          {item.dates_worked ||
                                            item.dates ||
                                            "Not provided"}
                                        </div>
                                        <div>
                                          <span
                                            style={{
                                              color: "rgba(75, 75, 78, 1)",
                                              fontSize: "16px",
                                              fontWeight: "600",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            Summary:{" "}
                                          </span>
                                          {item.summary || "Not provided"}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}

                            <div style={{ marginBottom: "12px" }}>
                              <p
                                style={{
                                  fontSize: "13px",
                                  marginBottom: "4px",
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: "15px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Download Resume
                                </span>{" "}
                                <span style={{ fontWeight: "600" }}>
                                  View Resume
                                </span>
                              </p>
                              <img
                                src={downloadIcon}
                                alt=""
                                height={"38px"}
                                style={{
                                  marginLeft: "35px",
                                  marginRight: "84px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleDownload(
                                    result?.filename,
                                    result?.resume
                                  )
                                }
                              />
                              <img
                                src={eyeIcon}
                                style={{ cursor: "pointer" }}
                                alt=""
                                height={"38px"}
                                onClick={() =>
                                  handleViewResume(result?.filename)
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="view-more-less"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderTop: "1px solid #ccc",
                              margin: "0 -10px",
                              padding: "0",
                              height: "100%", // Ensure the div takes full height of its container
                            }}
                          >
                            <button
                              onClick={() => handleToggleCard(index, result.id)}
                              style={{
                                background: "#f9f9f9",
                                border: "none",
                                color: "#0A65CC",
                                cursor: "pointer",
                                fontSize: "14px",
                                textDecoration: "none",
                                width: "100%",
                                height: "100%",
                                margin: "0",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  padding: "2px 5px",
                                }}
                              >
                                {expandedCard === index
                                  ? "View Less"
                                  : "View More"}
                              </span>
                            </button>
                          </div>
                        </li>
                      ))}
                </ul>
              </div>

              {selectedResume && (
                <div
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                    margin: "10px",
                    borderRadius: "5px",
                    width: "70%",
                  }}
                >
                  <div className="overlay">
                    <button
                      onClick={handleMinimizeResume}
                      style={{
                        backgroundColor: "#fff",
                        color: "white",
                        border: "none",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        position: "absolute",
                        top: "120px",
                        right: "580px",
                        zIndex: "1000",
                      }}
                    >
                      <img src={cross_icon} alt="Close" />
                    </button>
                    <div className="resume-viewer-container">
                      <pre>{renderViewer(selectedResume)}</pre>
                    </div>
                  </div>
                </div>
              )}
              {isModalOpenjd && (
                <div className="modaljd">
                  <div className="modal-contentjd">
                    <span className="close" onClick={handleCloseModaljd}>
                      &times;
                    </span>
                    <div className="copy-button-container">
                      <div className=" tw-px-8 tw-pt-6 tw-pb-8 ">
                        <ul className="tw-list-none tw-flex tw-items-center tw-gap-2 tw-p-0 tw-m-0 tw-mb-6">
                          <li className="tw-text-[#0A65CC] hover:tw-bg-[#0A65CC] tw-cursor-pointer hover:tw-text-white tw-flex tw-gap-1.5 tw-items-center tw-text-base tw-font-medium tw-bg-[#E7F0FA] tw-px-4 tw-py-2 tw-rounded-[4px]">
                            <span>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.81787 15.1813L15.1818 8.81738"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M13.5904 16.7731L10.9388 19.4248C10.0948 20.2685 8.95028 20.7424 7.75694 20.7423C6.5636 20.7422 5.41916 20.2681 4.57534 19.4242C3.73152 18.5804 3.25743 17.436 3.25732 16.2426C3.25722 15.0493 3.73112 13.9048 4.5748 13.0608L7.22645 10.4092"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M16.7727 13.5899L19.4243 10.9383C20.268 10.0943 20.7419 8.94979 20.7418 7.75645C20.7417 6.56311 20.2676 5.41867 19.4238 4.57486C18.5799 3.73104 17.4355 3.25694 16.2422 3.25684C15.0488 3.25673 13.9043 3.73064 13.0603 4.57431L10.4087 7.22596"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </span>
                            <CopyToClipboard
                              text={`https://stg.neverhunt.ai/SingleJobPost/${selectedJobId}/a`}
                              onCopy={handleCopy}
                            >
                              {isCopied ? (
                                <span>Copied</span>
                              ) : (
                                <span>Copy Link</span>
                              )}
                            </CopyToClipboard>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="job-details-container">
                      <h2>{selectedJobDetails.position}</h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            selectedJobDetails.description.replace(
                              /(<br\s*\/?>){2,}/g,
                              "<br>"
                            )
                          ),
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
}

export default SearchResumes;
