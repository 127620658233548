import React from 'react';
import { Link } from 'react-router-dom';
// import "../styles/Navbar.css"

function Pagenavbar() {
  

  return (
    <div className='Navbar' style={{marginBottom:"20px"}}>
   <Link to={"/dashboard"} style={{  color:"rgba(25, 9, 147, 0.805)", fontSize:"25px", fontWeight:"600", textDecoration:"none"}}>
       IntaQt
   </Link>
    </div>
  );
}

export default Pagenavbar;
