import React, { useState } from 'react';
import '../styles/EditPopup.css';
import ReactQuill from 'react-quill';

const EditPopup = ({ item, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    position: item.position,
    jobDescription: item.jobDescription,
    positionType: item.positionType,
    benefits: item.benefits,
    addNewQuestion: item.addNewQuestion,
  });

  const handleValues = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleChange = (content) => {
    // For ReactQuill, the content parameter directly contains the updated value
    setFormData((prevFormData) => ({
      ...prevFormData,
      jobDescription: content, // Update the 'jobDescription' directly with the content
    }));
  };

  const handleChangeQuestion = (index, e) => {
    const value = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      addNewQuestion: prevFormData.addNewQuestion.map((question, i) => (i === index ? value : question)),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(item.uniqueID, formData);
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/update/${item.uniqueID}`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <h2>Edit Post</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label style={{ marginRight: '8px' }}>Position</label>
            <input type="text" name="position" value={formData.position} onChange={handleValues} />
          </div>
          <div className="form-group">
            <label style={{ marginRight: '8px' }}>Job Description</label>
            <div className="job-description">
              <ReactQuill
                theme="snow"
                id="description"
                defaultValue={formData.jobDescription}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label style={{ marginRight: '8px' }}>Position Type</label>
            <input
              type="text"
              name="positionType"
              value={formData.positionType}
              onChange={handleValues}
            />
          </div>
          <div className="form-group">
            <label style={{ marginRight: '8px' }}>Benefits</label>
            <input type="text" name="benefits" value={formData.benefits} onChange={handleValues} />
          </div>
          {/* Example with an array of strings: */}
          <div className="form-group d-flex flex-column">
            <label style={{ marginRight: '8px', marginBottom:"8px" }}>Questions</label>
            <div className="questions-container">
              {formData.addNewQuestion.map((question, index) => (
                <input
                  key={index}
                  type="text"
                  value={question}
                  onChange={(e) => handleChangeQuestion(index, e)}
                  className="question-input"
                />
              ))}
            </div>
          </div>
          <div className="form-buttons mt-3 d-flex justify-content-start gap-3">
            <button type="submit" className="btn btn-primary ml-20">
              Save
            </button>
            <button type="button" className="btn btn-secondary" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default React.memo(EditPopup);
