import React, { useState } from 'react';

function ForgetEmail() {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (e) => {
        //${process.env.REACT_APP_BACKEND_BASE_URL}/password/forget
        e.preventDefault();
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/password/forget`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                email: email
            })

        })
        .then((res) => res.json())
        .then((data) => {
            setMessage(data.msg);
            localStorage.setItem("email",email)
        })
        .catch((err) => {
            console.log(err.message);
        });
    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100" style={{ marginTop: '-30px' }}>
            <form className="p-4 border border-dark rounded" style={{ width: '320px' }}>
                <div className="form-group">
                {message && (
                    <div className="mt-3 alert alert-info text-center">{message}</div>
                )}
                    <label htmlFor="email">Enter Email</label>
                    <input type="email" className="form-control" id="email" placeholder="abc@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="text-center">
                    <button type="submit" className="btn btn-primary" style={{width:"50%"}} onClick={handleSubmit}>Submit</button>
                </div>
                
            </form>
        </div>
    );
}

export default ForgetEmail;