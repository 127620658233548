import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/ChatModal.css";
import io from "socket.io-client";

const socket = io(`${process.env.REACT_APP_BACKEND_BASE_URL}`);

const ChatModal = ({ isOpen, onClose, seekerId, currentUser = "recruiter" }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    if (isOpen) {
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/Chatmodal/${seekerId}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          socket.on("receive-message", (message) => {
            console.log("message received", message);
            setMessages((prevMessages) => [...prevMessages, message]);
          });
          socket.emit("joinRoom", seekerId);
          
          if (Array.isArray(data)) {
            setMessages(data);
          } else {
            setMessages([]);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [isOpen, seekerId]);

  useEffect(() => {
    // socket.on("receive-message", (message) => {
    //   console.log("message received", message);
    //   setMessages((prevMessages) => [...prevMessages, message]);
    // });
  }, []);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      const message = { seekerId, sender: currentUser, content: newMessage };
      socket.emit("newMessagetoseeker", message, seekerId);

      
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/Chatmodal/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(message),
      })
        .then((res) => res.json())
        .then((data) => {
          setMessages((prevMessages) => [...prevMessages, message]);

        })
        .catch((err) => console.error(err));

      setNewMessage("");
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="chat-modal-overlay">
      <div className="chat-modal-content">
        <button className="close-button" onClick={onClose}>
          &times; {/* Unicode character for cross icon */}
        </button>
        <div className="chat-header">Chat with {seekerId}</div>
        <div className="chat-messages">
          {messages.map((message, index) => (
            <div key={index} className={`chat-message ${message.sender}`}>
              {message.content}
            </div>
          ))}
        </div>
        <div className="chat-input-container">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type a message..."
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default ChatModal;
