import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Pagenavbar from './Pagenavbar';
import Sidebar from './Sidebar';

function ResponseDetails(props) {
  const { userID, jobUniqueID } = useParams();
  const token = localStorage.getItem('token');
  const [response, setResponse] = useState(null);
  const navigate = useNavigate()
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [filenamee, setFilenamee] = useState(null);

  const handleBack = () => {
    navigate("/responses")
  }

  //for getting questions
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/onejob/${jobUniqueID}/${userID}`, {
      method: "GET",
      headers: {
        "Authorization": token
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setQuestions(data.msg.addNewQuestion);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  //for getting answers
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/getResponse/${jobUniqueID}/${userID}`)
      .then((res) => res.json())
      .then((data) => {
        setResponse(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [jobUniqueID]);

  //for getting filename from gridfs
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/getAudioFilename/${jobUniqueID}/${userID}`)
      .then((res) => res.json())
      .then((data) => {
        setFilenamee(data.filename);
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [])


  // Show a loading icon while waiting for the API response.
  if (!response) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  const { ResumeData, FullData } = response ?? {};

  // Function to handle opening the resume in a new tab
  const handleViewResume = () => {
    const resumeImage = ResumeData?.image;
    if (resumeImage) {
      const resumeURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/Images/${resumeImage}`;
      window.open(resumeURL, "_blank");
    }
  };

  //handling auido
  const handleAudio = () => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/gridStorage/${filenamee}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.blob();
      })
      .then((blob) => {
        // Create a temporary <a> element to trigger the download
        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);

        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = filenamee; // Set the filename for the downloaded file
        a.click();

        // Clean up the temporary <a> element
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }



  return (
    <div style={{ display: "flex", width: "100%" }}>
     <div style={{ width: "230px", position: "fixed", top: 0, bottom: 0, left: 0 }}>
  <Sidebar />
</div>

      <div className='d-flex flex-column' style={{ flex: "75%", display: "flex", flexDirection: "column", marginLeft: "17%" }}>

        <Pagenavbar />
        <div className="container mt-2 d-flex justify-content-center border-none">
          <div className="card p-4" style={{ maxWidth: '600px' }}>
            {/* <h2 className="text-center mb-4">Response Details of: {ResumeData.firstName}</h2> */}
            {ResumeData && (
              <div>
                <p className="mb-2"><strong>Email:</strong> {ResumeData.email}</p>
                <p className="mb-2"><strong>First Name:</strong> {ResumeData.firstName}</p>
                <p className="mb-2"><strong>Last Name:</strong> {ResumeData.lastName}</p>
                <p className="mb-2"><strong>Phone Number:</strong> {ResumeData.phoneNumber}</p>
                <p className="mb-2"><strong>Sponsorship:</strong> {FullData.sponsorship}</p>
                {/* <p className="mb-2">
                  <strong>Month & Date:</strong>{" "}
                  {`${new Date(FullData.dob).toLocaleString("en-GB", {
                    month: "long",
                  })} ${new Date(FullData.dob).getDate()}`}
                </p> */}

                <p className="mb-2"><strong>Available to Start:</strong> {FullData.avaliableToStart}</p>
                <p className="mb-2"><strong>City:</strong> {FullData.city}</p>
                <p className="mb-2"><strong>State:</strong> {FullData.state}</p>
                <p className="mb-2"><strong>Zip:</strong> {FullData.zip}</p>
                <p className="mb-2"><strong>Client Availability:</strong> {FullData.clientAvailability}</p>
                <h3 style={{ marginTop: "15px" }}>Q & A</h3>
                {questions.map((question, index) => {
                  const formattedQuestion = question.replace(/^\s*\d+\.\s*/, ""); // Remove numbering and dot
                  return (
                    <div key={index}>
                      <p className="mb-2"><strong>Question {index + 1}:</strong> {formattedQuestion}</p>
                      <p className="mb-2"><strong>Answer:</strong> {answers[index]}</p>
                    </div>
                  );

                })}
                <h3 style={{ marginTop: "15px" }}>Audio</h3>
                {filenamee && (
                  <audio controls>
                    <source src={`${process.env.REACT_APP_BACKEND_BASE_URL}/gridStorage/${filenamee}`} type="audio/mpeg" />
                  </audio>
                )}

              </div>
            )}

            {/* Add the "View Resume" button */}

            {ResumeData?.image && (
              <div className="d-flex justify-content-center mt-4 gap-3">
                <button className="btn btn-primary" onClick={handleBack} style={{ padding: "0 40px 0 40px" }}>
                  Back
                </button>
                <button className="btn btn-primary" onClick={handleViewResume}>
                  View Resume
                </button>

              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResponseDetails;
