import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FrameCross from "../Image/FrameCross.svg";
import CaretDown from "../Image/CaretDown.svg";
import PlusCircle from "../Image/PlusCircleslot.svg";
import fi_plus_circle from "../Image/fi_plus-circle.svg";
import fi_file_text from "../Image/fi_file-text.svg";
import fi_arrow_save from "../Image/fi_arrow-right_save.svg";
import JobSidebar from "../components/Jobsidebar";
import JobSeekerNavbar from "../components/JobSeekerNavbar";
import "./socialLinks.css";
import Jobsidebar from "../components/Jobsidebar";

const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

const usStates = [
  "Select One State",
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawali",
  "Idaho",
  "Illinois",
  "Indiana",
  "lowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermout",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const BookSlot = () => {
  const [Sponsorship, setSponsorship] = useState("No");
  const [Availability, setAvailability] = useState("Immediately");
  const [interviewAvailability, setInterviewAvailability] = useState("24hrs");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [bufferResume, setBufferResume] = useState([]);
  const [matching, setMatching] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isResumeRetrived, setIsResumeRetrived] = useState(false);
  const [userid, setUserid] = useState("")

  const [location, setLocation] = useState({
    city: "",
    state: usStates[0],
    zipCode: "",
  });
  //gg

  const [phoneEmpty, setPhoneEmpty] = useState(false);
  const [socialEmpty, setSocialEmpty] = useState(false);
  const [cityEmpty, setCityEmpty] = useState(false);
  const [stateEmpty, setStateEmpty] = useState(false);
  const [zipEmpty, setZipEmpty] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [jd, setJd] = useState(null);
  const [came, setCame] = useState(false);
  const [resumeText, setResumeText] = useState(null);
  const [resumeRetrivalName, setResumeRetrivalName] = useState("");
  const [matchscore, setMatchscore] = useState(0);

  const [socialLinks, setSocialLinks] = useState([
    { media: "LinkedIn", url: "" },
    { media: "GitHub", url: "" },
    { media: "Others", url: "" },
  ]); 

  const navigate = useNavigate();
  const { uniqueID } = useParams();

  const token = localStorage.getItem("token");
  const email = JSON.parse(localStorage.getItem("email"));

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/user/token-expire?email=${email}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("data token", data.msg);
        if (data.msg === "Invalid token. Please login") {
          //  setIsToeknValid(false)
          localStorage.removeItem("token");
          navigate(`/`);
        } else {
          //  setIsToeknValid(true)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  useEffect(() => {
    if (!token) {
      navigate(`/SingleJobPost/${uniqueID}/a`);
    }
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/get-jd/${uniqueID}`,{
      method: 'GET',
      headers: {
        "content-type":"application/json",
        "Authorization":token
      }
    })
      .then((res) => res.json())
      .then((dataa) => {
        setJd(dataa.msg);
        setUserid(dataa.id);
        console.log("jdd", dataa.msg)
         fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/get-resume/${uniqueID}`, {
              method: 'GET',
              headers: {
                  'content-type': 'application/json',
                  'Authorization': token
              },
          })
          .then((res)=> res.json())
          .then((data)=>{
            console.log("parsed resume: ", data.msg);
  
            setResumeText(data.msg);

            if(data.msg){
              const pysFormData = new FormData();
              pysFormData.append("jd", dataa.msg);
              pysFormData.append("userid", userid);
              pysFormData.append("resume_text", data.msg)
              pysFormData.append("resume_name", resumeRetrivalName)
              console.log("resume_name",  resumeRetrivalName)
              // Send data to the server after a delay
              //https://resume-matching.dev.neverhunt.io/match-score
              setTimeout(async () => {
                  try {
                      const pythonResponse = await fetch("https://resume-matching.dev.neverhunt.io/match-score", {
                          method: "POST",
                          body: pysFormData,
                      });
            
                      if (!pythonResponse.ok) {
                          throw new Error(`HTTP error! Status: ${pythonResponse.status}`);
                      }
            
                      const pythonData = await pythonResponse.json();
                      console.log("python", pythonData.answer);
            
                      // Update state with Python response
                      setMatching([...matching, pythonData.answer]);
                      setMatchscore(pythonData.score)
                      console.log("match score from python: ", pythonData.score);
                      setCame(true);
                  } catch (error) {
                      console.log("python", error);
                  } finally {
                      // Set loading state to false
                      setIsLoading(false);
                  }
              }, 2000);
            }
          })
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  //resume buffer data
  function divideStringIntoTenths(data) {
    const length = data.length;
    const segmentLength = Math.floor(length / 10);

    // Extract the 10 segments of the string
    const segments = [];
    for (let i = 0; i < 10; i++) {
      const start = i * segmentLength;
      const end = (i + 1) * segmentLength;
      segments.push(data.substring(start, end));
    }

    return segments;
  }
//${process.env.REACT_APP_BACKEND_BASE_URL}/upload
const ResumeRetrial = async () => {

};

  //${process.env.REACT_APP_BACKEND_BASE_URL}
  //for retrivng details
  useEffect(()=>{
    setIsLoading(true);
    setIsResumeRetrived(true)
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/getDetails`, {
     method: "GET",
     headers: {
         "content-type": "application/json",
         "Authorization": token
     }
    })
    .then((res)=> res.json())
    .then((data)=>{
     console.log("Success", data.msg)
     setSponsorship(data.msg.sponsorship)
     setAvailability(data.msg.Availability)
     setInterviewAvailability(data.msg.interviewAvailability)
     setPhoneNumber(data.msg.phone)
     setLocation({
         city: data.msg.city,
         state: data.msg.state,
         zipCode: String(data.msg.zip),
     })
     setSocialLinks([
         { media: "LinkedIn", url: data.msg.socialProfiles[0].url},
         { media: "GitHub", url: data.msg.socialProfiles[1].url },
         { media: "Others", url: data.msg.socialProfiles[2].url },
       ])
       // Assuming 'retrievedResumeName' contains the retrieved resume name from the database
      setSelectedFile({ ...selectedFile, name: data.msg.resumeFilename });
      setResumeRetrivalName(data.msg.resume)
      console.log(" name of the resume", data.msg.resume)
    
      // ResumeRetrial(data.msg.resume);
      setTimeout(() => {
        ResumeRetrial();
        
      }, 3000);
    }).catch((err)=>{
     console.log("Error", err.message)
    })
   }, [])


  const handleFileChange = async (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    console.log("file", file);
    const base64 = await convertToBase64(file);
    const segments = divideStringIntoTenths(base64);
    setSelectedFile(file);

    const pyFormData = new FormData();
    pyFormData.append("jd", jd);
    pyFormData.append("userid", userid)
     pyFormData.append("resume", file);
    pyFormData.append("resume_text", "resume")
    //https://resume-matching.dev.neverhunt.io/match-score
    
    setTimeout(() => {
      fetch(`https://resume-matching.dev.neverhunt.io/match-score`, {
        method: "POST",
        body: pyFormData,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("python", data.answer);
          console.log("resume", data.resume);
          setMatchscore(data.score)
          console.log("match score from python: ", data.score);
            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/addResume`, {
              method:"POST",
              headers:{
                "content-type":"application/json",
                "Authorization": token
              },
              body: JSON.stringify({
                resume: data.resume
              })
            })
            .then((res)=> res.json())
            .then((data)=>{
              console.log(data.msg)
            })
            .catch((err)=>{
              console.log(err.message)
            })
     
          setMatching([...matching, data.answer]);
          
          setCame(true);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("python", err);
        })
        .finally(() => {
          // setIsLoading(false);
        });
    }, 3000);
     const formdatares = new FormData();
     formdatares.append("file", file)
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/update`, {
      method: "PATCH",
      headers: {
        Authorization: token,
      },
      body: formdatares,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("data: " + data.msg);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (came) {
    console.log("mat", matching);
  }

  const handleSocialMediaChange = (e, index) => {
    const newSocialLinks = [...socialLinks];
    newSocialLinks[index].media = e.target.value;
    setSocialLinks(newSocialLinks);
  };

  const handleSocialUrlChange = (e, index) => {
    const newSocialLinks = [...socialLinks];
    newSocialLinks[index].url = e.target.value;
    setSocialLinks(newSocialLinks);
  };

  const handleAddSocialLink = () => {
    setSocialLinks([...socialLinks, { media: "", url: "" }]);
  };

  const handleRemoveSocialLink = (index) => {
    const newSocialLinks = [...socialLinks];
    newSocialLinks.splice(index, 1);
    setSocialLinks(newSocialLinks);
  };

  const handleSponsorshipChange = (e) => {
    setSponsorship(e.target.value);
  };

  const handleAvailabilityChange = (e) => {
    setAvailability(e.target.value);
  };

  const handleinterviewAvailabilityChange = (e) => {
    setInterviewAvailability(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleFormSubmit = (e) => {
    if (!selectedFile || !selectedFile.name) {
      setMessage("Please upload your resume to continue");
      return;
    }

    if (isLoading) {
      return;
    }

    const socialProfiles = socialLinks.map((link) => ({
      media: link.media,
      url: link.url,
    }));

    e.preventDefault();

    if (phoneNumber.trim() === "") {
      setPhoneEmpty(true);
    } else {
      setPhoneEmpty(false);
    }
    if (location.city.trim() === "") {
      setCityEmpty(true);
    } else {
      setCityEmpty(false);
    }

    if (location.state.trim() === "") {
      setStateEmpty(true);
    } else {
      setStateEmpty(false);
    }

    if (location.zipCode.trim() === "") {
      setZipEmpty(true);
    } else {
      setZipEmpty(false);
    }

    // If any required field is empty, prevent form submission
    if (
      phoneNumber.trim() === "" ||
      location.city.trim() === "" ||
      location.state.trim() === "" ||
      location.zipCode.trim() === ""
    ) {
      return;
    } else {
      const formdata = new FormData();
      formdata.append("file", selectedFile);
      formdata.append("sponsorship", Sponsorship);
      formdata.append("Availability", Availability);
      formdata.append("interviewAvailability", interviewAvailability);
      formdata.append("city", location.city);
      formdata.append("state", location.state);
      formdata.append("zip", location.zipCode);
      formdata.append("phone", phoneNumber);
      formdata.append("socialProfiles", JSON.stringify(socialProfiles));
      formdata.append("jobUniqueID", uniqueID);
      formdata.append("matching", matching);
      formdata.append("resumeData", resumeText)
      formdata.append("matchScore", matchscore)
      // formdata.append("bufferResume", bufferResume);

      for (const pair of formdata.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      //`${process.env.REACT_APP_BACKEND_BASE_URL}/upload`
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/upload`, {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: formdata,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("data: " + data);
          if (data.msg === "JobForm saved successfully") {
            navigate(`/SingleJob2/${uniqueID}`);
          } else {
            setMessage(data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });

        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/update`, {
          method: "PATCH",
          headers: {
            Authorization: token,
          },
          body: formdata,
        })
          .then((res) => res.json())
          .then((data) => {
            console.log("data: " + data.msg);
          })
          .catch((error) => {
            console.log(error);
          });
    }
  };

  return (
    <div className="MainContainer">
      <div className="mobile-screen">
        <div style={{ height: "70px" }}>
          <JobSeekerNavbar />
        </div>
        <div
          className="col-1 col-lg-3 col-xl-3 hamburgIcon"
          style={{ marginRight: "60px" }}
        >
          <Jobsidebar />
        </div>
      </div>
      <hr />
      <div className="container second-main">
        <div className="row">
          <div
            className="col-1 col-lg-3 sidebarDiv noclor"
            style={{ borderRight: "1px solid #ccc" }}
          >
            <JobSidebar />
          </div>
          <div className="col-lg-9">
            <div className="container w-100">
              {message && (
                <div className="mt-3 alert alert-info text-center">
                  {message}
                </div>
              )}
              <form className="text-left">
                <div className="form-group text-start label-txt">
                  <label htmlFor="resume" style={{ marginBottom: "14px" }}>
                    Upload your Resume{" "}
                    <span className="form-label-required text-danger">*</span>
                  </label>
                  <div className="resume-upload-container">
                    {selectedFile ? (
                      <>
                        <div className="uploaded-file">
                          <span className="file-icon">
                            <img
                              src={fi_file_text}
                              alt=""
                              style={{ marginRight: "5px" }}
                            />
                          </span>
                          <span className="file-name">{selectedFile.name}</span>
                        </div>
                        <label
                          htmlFor="resume-file"
                          className="resume-upload-label"
                        >
                          <div
                            className="upload-box"
                            style={{ marginLeft: "25px", width: "293px" }}
                          >
                            <span className="add-resume-text">
                              <img
                                src={fi_plus_circle}
                                alt=""
                                style={{ marginRight: "10px" }}
                              />
                              Change Resume
                            </span>
                          </div>
                          <div className="loading-spinner">
                            {isLoading && (
                              <div className="loader-container">
                                <div className="loader"></div>
                              </div>
                            )}
                          </div>
                        </label>
                      </>
                    ) : (
                      <>
                        <label
                          htmlFor="resume-file"
                          className="resume-upload-label"
                        >
                          <div
                            className="upload-box"
                            style={{ width: "313px" }}
                          >
                            <span className="add-resume-text">
                              <img
                                src={fi_plus_circle}
                                alt=""
                                style={{ marginRight: "10px" }}
                              />
                              Add/change Resume
                              <span>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    marginLeft: "33px",
                                    marginTop: "5px",
                                  }}
                                >
                                  Supported file formats PDF and Word doc
                                </p>
                              </span>
                            </span>
                          </div>
                        </label>
                      </>
                    )}
                    <input
                      type="file"
                      id="resume-file"
                      accept=".pdf, .doc, .docx"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                </div>

                <div className="form-group text-start label-txt ">
                  <label>
                    Need Sponsorship{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="d-flex flex-wrap justify-content-start ">
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="jobTypeFulltime"
                        value="No"
                        checked={Sponsorship === "No"}
                        onChange={handleSponsorshipChange}
                        required
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeFulltime"
                      >
                        No
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="jobTypeContract"
                        value="Yes"
                        checked={Sponsorship === "Yes"}
                        onChange={handleSponsorshipChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeContract"
                      >
                        Yes
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt ">
                  <label>
                    Availability{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="d-flex flex-wrap justify-content-start ">
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="jobTypeFulltime"
                        value="Immediately"
                        checked={Availability === "Immediately"}
                        onChange={handleAvailabilityChange}
                        required
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeFulltime"
                      >
                        Immediately
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="jobTypeContract"
                        value="2weeksNotice"
                        checked={Availability === "2weeksNotice"}
                        onChange={handleAvailabilityChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeContract"
                      >
                        In 2 weeks Notice
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt ">
                  <label>
                    Interview Availability{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="d-flex flex-wrap justify-content-start ">
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="jobTypeFulltime"
                        value="24hrs"
                        checked={interviewAvailability === "24hrs"}
                        onChange={handleinterviewAvailabilityChange}
                        required
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeFulltime"
                      >
                        Within 24hrs
                      </label>
                    </div>
                    <div className="form-check gap-2">
                      <input
                        type="radio"
                        className="form-check-input radio-input"
                        id="jobTypeContract"
                        value="48hrs"
                        checked={interviewAvailability === "48hrs"}
                        onChange={handleinterviewAvailabilityChange}
                      />
                      <label
                        className="form-check-label label-checks"
                        htmlFor="jobTypeContract"
                      >
                        48hrs
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt">
                  <label htmlFor="position">
                    Phone Number{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-4">
                      <input
                        type="number"
                        className={`form-control placeholder-gray ${
                          phoneEmpty ? "border-red" : ""
                        }`}
                        id="salary"
                        value={phoneNumber}
                        onChange={handlePhoneChange}
                        placeholder="1234567890"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group text-start label-txt">
                  <label>
                    Location{" "}
                    <span class="form-label-required text-danger">*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="city">City</label>
                      <input
                        type="text"
                        className={`form-control placeholder-gray ${
                          cityEmpty ? "border-red" : ""
                        }`}
                        id="city"
                        value={location.city}
                        onChange={(e) =>
                          setLocation({ ...location, city: e.target.value })
                        }
                        placeholder="City"
                        required
                      />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="state">State</label>
                      <select
                        className={`form-control placeholder-gray ${
                          stateEmpty ? "border-red" : ""
                        }`}
                        id="state"
                        value={location.state}
                        onChange={(e) =>
                          setLocation({ ...location, state: e.target.value })
                        }
                        required
                      >
                        {usStates.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="zipCode">Zip</label>
                      <input
                        type="text"
                        className={`form-control placeholder-gray ${
                          zipEmpty ? "border-red" : ""
                        }`}
                        id="zipCode"
                        value={location.zipCode}
                        onChange={(e) =>
                          setLocation({ ...location, zipCode: e.target.value })
                        }
                        placeholder="Zip Code"
                        required
                      />
                    </div>
                  </div>
                </div>

                {/* Social Links Section */}
                <div className="form-group text-start label-txt social-links-section">
                  <label>Social Links</label>
                  {socialLinks.map((link, index) => (
                    <div key={index} className="social-link-row">
                      <select
                        className={`form-control social-media-select`}
                        value={link.media}
                        onChange={(e) => handleSocialMediaChange(e, index)}
                      >
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="GitHub">GitHub</option>
                        <option value="Others">Others</option>
                        {/* Add more social media options here */}
                      </select>
                      <input
                        type="text"
                        className={`form-control placeholder-gray social-media-input custom-width`}
                        style={{ marginRight: "-40px" }}
                        value={link.url}
                        onChange={(e) => handleSocialUrlChange(e, index)}
                        placeholder="Profile link/url..."
                      />
                      <button
                        className="btn cross-btn"
                        onClick={() => handleRemoveSocialLink(index)}
                      ></button>
                    </div>
                  ))}
                </div>
              </form>
              <button
                className={`btns ${isLoading ? "disabled" : ""}`}
                onClick={handleFormSubmit}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Save & Next "}
                <span>
                  <img src={fi_arrow_save} alt="" />
                </span>
              </button>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
      <div class="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
};

export default BookSlot;