import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import "../styles/ResPopup.css";
import { Link } from 'react-router-dom';

function Responses() {
  const token = localStorage.getItem('token');
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [fulldata, setFullData] = useState({});

  const handleViewResume = () => {
    if (selectedRow.re && selectedRow.re.image) {
      const resumeURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/Images/${selectedRow.re.image}`;
      window.open(resumeURL, "_blank");
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/applicants`, {
      method: 'GET',
      headers: {
        "Authorization": token
      }
    })
    .then((res) => res.json())
    .then((data) => {
      // console.log("data.msg", data.msg);
      setData(data.msg);
    });
  }, [])

  useEffect(()=>{
    
  }, [])

  // const handleRowClick = (item, jobID) => {
    
  //   fetch(`https://client-be.onrender.com/getResponse/${jobID}`)
  //   .then((res)=> res.json())
  //   .then((data)=>{
  //     console.log(data);
  //     // console.log("Fewfe", item)
  //     // setFullData(data)
  //     setSelectedRow({re:data.ResumeData,full:data.FullData});
  //     console.log("hello",selectedRow)
   
  //   })
  //   .catch((err)=>{
  //     console.log(err.message)
      
  //   })
    
  // };

  const formatDate = (dateTimeString) => {
    const options = { 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit',
      hour12: true // Display time in 12-hour format with AM/PM
    };
    const date = new Date(dateTimeString);
    return date.toLocaleString('en-US', options);
  };


  const handleClosePopup = () => {
    setSelectedRow(null);
  };

  return (
    <>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ flex: "10%" }}>
          <Sidebar />
        </div>
        <div className="pageContent" style={{ flex: "75%", display: "flex", flexDirection: "column" }}>
          <Navbar />
      <div className="container">
        {/* <h1 className="text-center">Responses</h1> */}
        {data.length === 0 ? (
          <p className="text-center mt-4">No data available.</p>
        ) : (
          <table className="table table-bordered table-striped text-center mt-4">
            <thead className="thead-dark">
              <tr>
                <th>Position</th>
                <th>Job ID</th>
                <th>Date</th>
                <th>View Response</th> {/* New column */}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} >
                  <td>{item.position}</td>
                  <td>{item.jobUniqueID.slice(0, 4)}</td>
                  <td>{new Date(item.date).toLocaleDateString('en-GB')}</td>
                  <td>
                    <Link to={`/response/${item.userID}/${item.jobUniqueID}`}>View</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      </div>
      </div>
    </>
  );
}

export default Responses;
