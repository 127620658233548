import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ForgotPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [invalidFields, setInvalidFields] = useState([]);
  const [message, setMessage] = useState('');
  
  const { token } = useParams();
  console.log("parsms id", token)
  
  const email=localStorage.getItem('email');
  const navigate = useNavigate()

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') {
      setPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }

    if (invalidFields.includes(name)) {
      setInvalidFields(invalidFields.filter((field) => field !== name));
    }
  };

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/user/token-expire?email=${email}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("data token", data.msg);
        if (data.msg === "Invalid token. Please login") {
          //  setIsToeknValid(false)
          localStorage.removeItem("token");
          navigate(`/`);
        } else {
          //  setIsToeknValid(true)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setInvalidFields(['password', 'confirmPassword']);
      setMessage('Passwords do not match');
      return;
    }
    //${process.env.REACT_APP_BACKEND_BASE_URL}/password/update/${email}
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/password/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
         Authorization: token
      },
      body: JSON.stringify({
        password: password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setMessage(`${data.msg} Redirecting to login...`)

        setTimeout(()=>{
          if(data.msg==="Password updated successfully"){
            navigate("/Login")
           }
        },2000)

        setPassword('');
        setConfirmPassword('');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isInvalidField = (fieldName) => invalidFields.includes(fieldName);

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
       
      <div className="card p-4" style={{ width: '400px' }}>
      {message && (
              <div className="mt-3 alert alert-info text-center">{message}</div>
            )}
        <center>
          <h3 className="mb-4">Forgot Password</h3>
        </center>
        <form>
      
          <div className="form-group" style={{ marginTop: '10px' }}>
            
            <label>Password</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={handleInputChange}
              className={`form-control ${
                isInvalidField('password') ? 'border border-danger' : ''
              }`}
            />
          </div>
          <div className="form-group" style={{ marginTop: '5px' }}>
            <label>Confirm Password</label>
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleInputChange}
              className={`form-control ${
                isInvalidField('confirmPassword') ? 'border border-danger' : ''
              }`}
            />
          </div>
          <center>
            <button
              type="button"
              className="btn btn-success text-white mt-3"
              style={{ paddingLeft: '50px', paddingRight: '50px' }}
              onClick={handleSubmit}
            >
              Confirm
            </button>
          </center>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
