import React, { useEffect, useState } from "react";
import styled from "styled-components";
import fi_copy from "../Image/fi_copy.svg";
import tick_icons from "../Image/tick-icons8.svg";

import { CopyToClipboard } from "react-copy-to-clipboard";
import "../styles/JobThankYou.css";

import "../styles/ProfileModal.css";

const ModalWrapper = styled.div`
  /* Styles for the modal background overlay */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  /* Styles for the modal content */
  background-color: #fff;
  padding: 5% 8%;
  border-radius: 5px;
  width: 40%;
  height: auto;
  position: relative; 

  
`;

const CloseButton = styled.span`
  position: absolute;
  top: -10px;
  right: -45px;
  cursor: pointer;
  font-size: 28px;
  width: 30px;
  height: 30px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  padding: 0px 10px 5px 10px;
`;

//rgba(0, 0, 0, 0.5)

function JobThankyou({ isOpen, closeModal, uniqueID }) {
  const [copyClick, setCopyClick] = useState(false);
  const token = localStorage.getItem("token");

  const handleCopy = () => {
    setCopyClick(true)

    setTimeout(() => {
   setCopyClick(false)
    }, 2000);
  };


  if (!isOpen) return null;
  const url = `https://neverhunt.ai/SingleJobPost/${uniqueID}/a`;
  return (
    <ModalWrapper>
      <ModalContent>
        <CloseButton onClick={closeModal}>&times;</CloseButton>
        <h3 style={{ textAlign: "center" }}>Job Posted Succesfully</h3>
        <p>Link</p>
        <input
          className="form-control"
          type="text"
          placeholder="https://"
          value={url}
        />
        <br />
        <div className="copylink">
          <CopyToClipboard text={url}>
            {copyClick ? (
              <img
                src={tick_icons}
                width="40px"
                alt="error"
                style={{ cursor: "pointer" }}
              />
            ) : (
              <img
                src={fi_copy}
                alt="error"
                style={{ cursor: "pointer" }}
                onClick={ handleCopy}
              />
            )}
          </CopyToClipboard>
            <p style={{fontSize:"16px"}}>Copy link</p>
        
        </div>
        <div className="textclose">
          <p style={{fontSize:"16px"}}>
            Here is the link for the job post. Share this link with your
            potential applicants.
          </p>
          <button className=" closebtnpopup " onClick={closeModal}>
            close
          </button>
        </div>
      </ModalContent>
    </ModalWrapper>
  );
}

export default JobThankyou;
