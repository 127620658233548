import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import JobSeekerNavbar from './JobSeekerNavbar';
import Jobsidebar from './Jobsidebar';
import { saveAs } from "file-saver";
import io from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const socket = io(`${process.env.REACT_APP_BACKEND_BASE_URL}`);

function SeekerMessages({ currentUser="seeker" }) {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [seekerid, setSeekerid] = useState("")
  const [notification, setNotification] = useState([])

   const token = localStorage.getItem("token");

  useEffect(() => {

    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/getseekerid`, {
        method: "GET",
        headers:{
          Authorization: token
        }
       })
       .then((res)=> res.json())
       .then((data)=>{
       const id = `"${data.msg._id}"`
       setSeekerid(`"${data.msg._id}"`)
       socket.emit("joinRoom", id);

    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/Chatmodal/${id}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((res) => res.json())
        .then((dataa) => {
          console.log("data.msg", dataa)
          

          // socket.on("newMessage", (message) => {
          //   setMessages((prevMessages) => [...prevMessages, message]);
          // });
          if (Array.isArray(dataa)) {
              setMessages(dataa);
            } else {
              setMessages([]);
            }
        })
        .catch((err) => console.error(err));
       })
  }, []);

 useEffect(()=>{
  socket.on("receive-message-seeker", (message) => {
    console.log("message received", message);
    setMessages((prevMessages) => [...prevMessages, message]);
    const notif = `New message from ${message.sender}: ${message.content}`
    setNotification((prevNotifications) => [...prevNotifications, notif]);
    toast.info(`New message from ${message.sender}: ${message.content}`);
  });
 }, [])

  const handleSendMessage = () => {
    if (inputValue.trim() !== "") {
      const newMessage = {seekerId: seekerid, sender: currentUser, content: inputValue.trim() };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInputValue("");
      setLoading(true);
      socket.emit("newMessage", newMessage, seekerid);

      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/Chatmodal/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newMessage)
      })
        .then((res) => res.json())
        .then((data) => {
          setMessages((prevMessages) => [...prevMessages, data]);
        })
        .catch((err) => {
          console.error("Error sending message:", err.message);
          const botResponse = {
            sender: "bot",
            content: "An error occurred: " + err.message,
          };
          setMessages((prevMessages) => [...prevMessages, botResponse]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const formatBotResponse = (data) => {
    if (!data.result || !data.result.answer) return <p>Invalid response format</p>;

    return data.result.answer.map((item, index) => (
      <div key={index} className="response-item">
        <p><strong>City:</strong> {item.city}</p>
        <p><strong>Experience:</strong> {item.experience}</p>
        <p><strong>Filename:</strong> {item.filename}</p>
        <p><strong>Candidate Name:</strong> {item.parsed_resume.candidate_name}</p>
        <p><strong>Potential Roles:</strong> {item.potential_roles.join(", ")}</p>
        <p><strong>Full Score:</strong> {item.full_score}</p>
        <p><strong>State:</strong> {item.state}</p>
        <p><strong>Work Authorization:</strong> {item.work_auth}</p>
        <button onClick={() => handleDownload(item.filename)} className="download-button">Download Resume</button>
        {index < data.result.answer.length - 1 && <hr />}
      </div>
    ));
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDownload = (filename) => {
    const fileurl = `${process.env.REACT_APP_BACKEND_BASE_URL}/ResumeUploads/${filename}`;
    fetch(fileurl)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, filename);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <>
      <div className="MainContainer">
        <div className="mobile-screen">
          <div style={{ height: "70px" }}>
            <JobSeekerNavbar notifications={notification}/>
          </div>
          <div className="col-1 col-lg-3 col-xl-3 hamburgIcon" style={{ marginRight: "60px" }}>
            <Jobsidebar />
          </div>
        </div>
        <hr />
        <div className="container second-main">
          <div className="row">
            <div className="col-1 col-lg-3 col-xl-3 sidebarDiv noclor">
              <Jobsidebar />
            </div>
            <div className="col-11 col-lg-9 chatContainer">
              <div className="chatBox">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`message ${message.sender === currentUser ? "userMessage" : "botMessage"}`}
                  >
                    {message.sender === currentUser ? (
                      message.content
                    ) : (
                      message.content
                    )}
                  </div>
                ))}
                {loading && (
                  <div className="message botMessage">
                    Loading...
                  </div>
                )}
              </div>
              <div className="inputBox">
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  placeholder="Type your message here..."
                />
                <button onClick={handleSendMessage}>Send</button>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-footer text-center body-font-4 text-gray-500">
          Copyright © 2024 Neverhunt All rights reserved.
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default SeekerMessages;
