import React, { createContext, useContext, useState, useEffect } from 'react';

const MessageContext = createContext();

export const useMessage = () => useContext(MessageContext);

export const MessageProvider = ({ children }) => {
  const [successMessage, setSuccessMessage] = useState(() => {
    return localStorage.getItem('successMessage') || '';
  });
  const [processingMessage, setProcessingMessage] = useState(() => {
    return localStorage.getItem('processingMessage') || '';
  });

  useEffect(() => {
    localStorage.setItem('successMessage', successMessage);
    localStorage.setItem('processingMessage', processingMessage);
  }, [successMessage, processingMessage]);

  const clearMessages = () => {
    setSuccessMessage('');
    setProcessingMessage('');
    localStorage.removeItem('successMessage');
    localStorage.removeItem('processingMessage');
  };

  return (
    <MessageContext.Provider value={{ successMessage, setSuccessMessage, processingMessage, setProcessingMessage, clearMessages }}>
      {children}
    </MessageContext.Provider>
  );
};
