import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

const Verify = () => {
  const { email } = useParams();
  const [display, setDisplay] = useState();

  const handleClick = ()=>{
fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/verify`, {
    method: 'POST',
    headers: {
        "content-type": "application/json",
    },
    body: JSON.stringify({
        email
    })
})
.then((res)=> res.json())
.then((data)=>{
    setDisplay(true)
    console.log(data.msg);
})
.catch((err)=>{
    console.log(err.message)
})
  }

  return (
    <div className="container-fluid d-flex flex-column align-items-center justify-content-center vh-100">
     <span style={{marginBottom:"40px"}}>{display ? "Congratulations your account has been activated and now you can close this tab" : ""}</span> 
      <div className="verify-container border p-4" style={{ width: '400px', textAlign: 'center' }}>
        <h1 className="text-center text-gray">Verify Your Account</h1>
        <p>Please click the button below to activate your account associated with <strong>{email}</strong>.</p>
        <button
          onClick={handleClick}
          style={{
            background: "#0A65CC",
            color: "white",
            fontWeight: "600",
            padding: "12px 24px 13px",
            border: "none",
            borderRadius: "4px",
            fontSize: "16px",
            fontFamily: "sans-serif",
            cursor: "pointer",
          }}
        >
          Click here to activate your account
          
        </button>
      </div>
    </div>
  );
};

export default Verify;
