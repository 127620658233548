import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap-icons/font/bootstrap-icons.css';
import "../styles/Sidebar.css";
import overview from "../Image/Stack.svg"
import EmployersProfile from "../Image/UserCircle.svg"
import PostJob from "../Image/PlusCircle.svg"
import Myjob from "../Image/Briefcase.svg"
import savedCandidates from "../Image/BookmarkSimple.svg"
import PlansAndBilling from "../Image/Notebook.svg"
import AllCompanys from "../Image/User List.svg"
import settings from "../Image/Gear.svg"
import signout from "../Image/SignOut.svg"


function Sidebar() {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeOption, setActiveOption] = useState(location.pathname);

    const handleClient = () => {
        localStorage.clear();
        navigate("/");
    };

    const handleOptionClick = (path) => {
        setActiveOption(path);
    };

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-auto col-sm-2  d-flex flex-column justify-content-between min-vh-100 w-100' >
                    <div>
                        <ul className="nav nav-pills flex-column mt-sm-0" id='parentM'>
                            {/* <li className={`nav-item my-1 py-2 py-sm-0 custom-li-class ${activeOption === "/dashboard" ? "active" : ""}`}>
                                <Link to="/dashboard" className="nav-link text-white text-center text-sm-start name-img" onClick={() => handleOptionClick("/dashboard")}>
                                    <img src={overview} alt="error" />
                                    <span className='ms-2 d-none d-sm-inline span-t'>Overview</span>
                                </Link>
                            </li> */}
{/* 
                            <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "/responses" ? "active" : ""}`}>
                                <Link to="/responses" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/responses")}>
                                   <i className="ph-user-circle"></i>
                                    <img src={EmployersProfile} alt="error" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>Employers Profile</span>
                                </Link>
                            </li> */}

                            {/* <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "/JobPost" ? "active" : ""}`}>
                                <Link to="/JobPost" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/JobPost")}>
                                    <img src={PostJob} alt="error" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>Post a Job</span>
                                </Link>
                            </li> */}
                            {/* <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "/myjobs" ? "active" : ""}`}>
                                <Link to="/myjobs" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/myjobs")}>
                                    <img src={Myjob} alt="error" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>My Jobs</span>
                                </Link>
                            </li> */}
                            {/* <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "/ClientReq" ? "active" : ""}`}>
                                <Link to="/ClientReq" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/ClientReq")}>
                                    <img src={Myjob} alt="error" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>Client Jobs</span>
                                </Link>
                            </li> */}
                            <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "/search-resumes" ? "active" : ""} sidebar-l`}>
                                <Link to="/search-resumes" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/search-resumes")}>
                                    {/* <i className='bi bi-people'></i> */}
                                    <img src={Myjob} alt="error" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>Home</span>
                                </Link>
                            </li>
                            <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "/resumepool" ? "active" : ""} sidebar-l`}>
                                <Link to="/resumepool" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/resumepool")}>
                                    <img src={PlansAndBilling} alt="error" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>Resume Pool</span>
                                </Link>
                            </li> 
                            {/* <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "#" ? "active" : ""}`}>
                                <Link to="#" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/profile")}>
                                    <img src={AllCompanys} alt="error" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>All Companies</span>
                                </Link>
                            </li> */}
                            {/* <li className={`nav-item my-1 py-2 py-sm-0 ${activeOption === "#" ? "active" : ""}`}>
                                <Link to="#" className="nav-link text-white text-center text-sm-start" onClick={() => handleOptionClick("/profile")}>
                                    <img src={settings} alt="error" />
                                    <span className='ms-2 d-none d-sm-inline text-gray'>Settings</span>
                                </Link>
                            </li> */}
                            <li className={"nav-item my-1 py-2 py-sm-0 sidebar-l" }>
                            <Link to="/" className="nav-link text-white text-center text-sm-start" type="button" onClick={handleClient}>
                            {/* <i className='bi bi-person f5-4'></i> */}
                            <img src={signout} alt="error" />
                            <span className='ms-2 d-none d-sm-inline text-gray'>Logout</span>
                        </Link>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default Sidebar;