import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import "../styles/AdminSearchResumes.css";
import "react-circular-progressbar/dist/styles.css";
import AdminSidebar from "./AdminSidebar";

function AdminPrompts() {
  // Separate useState for each text area
  const [textArea1, setTextArea1] = useState("");
  const [textArea2, setTextArea2] = useState("");
  const [textArea3, setTextArea3] = useState("");
  const [textArea4, setTextArea4] = useState(""); 
  const [textArea5, setTextArea5] = useState(""); 
  const [textArea6, setTextArea6] = useState(""); 
  const [textArea7, setTextArea7] = useState(""); 
  const [flashMessage, setFlashMessage] = useState(""); 
  const [showFlash, setShowFlash] = useState(false); 

  // Function to save data
  const saveData = (text, areaNumber) => {
    let obj = {};
    if (areaNumber === 1) {
        obj["prompt1"] = text; 
    } else if (areaNumber === 2) {
        obj["prompt2"] = text; 
    } else if (areaNumber === 3) {
        obj["prompt3"] = text; 
    } else if (areaNumber === 4) {
        obj["prompt4"] = text; 
    } else if (areaNumber === 5) {
        obj["prompt5"] = text; 
    } else if (areaNumber === 6) {
        obj["prompt6"] = text; 
    }else if (areaNumber === 7) {
      obj["prompt7"] = text; 
  }
  
    console.log("obj: ", obj);
    console.log(`Data saved for text area ${areaNumber}: ${text}`);

    // ${process.env.REACT_APP_BACKEND_BASE_URL}/prompt/update
    // Sending the PATCH request to the server with the update
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/prompt/update`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj)
    })
    .then(response => response.json())
    .then(data => {
        setFlashMessage(`${data.msg}, prompt: ${areaNumber}`);  
        setShowFlash(true);  
        setTimeout(() => setShowFlash(false), 5000); 
        console.log("Update successful:", data);
    })
    .catch(err => {
        setFlashMessage("Update error: " + err.message);
        setShowFlash(true);
        setTimeout(() => setShowFlash(false), 3000);
        console.error("Update error:", err.message);
    });
};

// state to fetch prompts by default
  useEffect(()=>{
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/prompt/get`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    })
    .then((res)=> res.json())
    .then((data)=>{
        setTextArea1(data.msg[0].prompt1)
        setTextArea2(data.msg[0].prompt2)
        setTextArea3(data.msg[0].prompt3)
        setTextArea4(data.msg[0].prompt4) // Fetching Resume Prompt 2 data
        setTextArea5(data.msg[0].prompt5) // Fetching Prompt 5 data
        setTextArea6(data.msg[0].prompt6) 
        setTextArea7(data.msg[0].prompt7) 
        console.log(data.msg)
    })
    .catch((err)=>{
        console.log(err.message)
    })
  }, [])

  return (
    <div className="MainContainer">
      <div style={{ height: "70px" }}>
        <Navbar />
      </div>
      <hr />
      {showFlash && <div  className="alert alert-success flash-message">{flashMessage}</div>}

      <div className="container second-main">
        <div className="row">
          <div className="col-1 col-lg-3 sidebarDiv">
            <AdminSidebar />
          </div>
          <div className="col-lg-9">
            <div className="container overBox">
              <div className="mb-1">
                <label className="form-label mb-1" style={{fontWeight:"500"}}>Jd Prompt</label>
                <textarea
                  value={textArea1}
                  onChange={(e) => setTextArea1(e.target.value)}
                  className="form-control"
                  style={{ minHeight: "250px" }}
                />
                <div className="text-end mt-2">
                  <button onClick={() => saveData(textArea1, 1)} className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
              <div className="mb-1">
                <label className="form-label mb-1" style={{fontWeight:"500"}}>Resume Prompt</label>
                <textarea
                  value={textArea2}
                  onChange={(e) => setTextArea2(e.target.value)}
                  className="form-control"
                  style={{ minHeight: "250px" }}
                />
                <div className="text-end mt-2">
                  <button onClick={() => saveData(textArea2, 2)} className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
              <div className="mb-1">
                <label className="form-label mb-1" style={{fontWeight:"500"}}>Resume Prompt 2</label>
                <textarea
                  value={textArea4}
                  onChange={(e) => setTextArea4(e.target.value)}
                  className="form-control"
                  style={{ minHeight: "250px" }}
                />
                <div className="text-end mt-2">
                  <button onClick={() => saveData(textArea4, 4)} className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
              <div className="mb-4">
                <label className="form-label mb-1" style={{fontWeight:"500"}}>Final Prompt</label>
                <textarea
                  value={textArea3}
                  onChange={(e) => setTextArea3(e.target.value)}
                  className="form-control"
                  style={{ minHeight: "250px" }}
                />
                <div className="text-end mt-2">
                  <button onClick={() => saveData(textArea3, 3)} className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
              <div className="mb-1">
                <label className="form-label mb-1" style={{fontWeight:"500"}}>Prompt 5 (symentic search agent system message)</label>
                <textarea
                  value={textArea5}
                  onChange={(e) => setTextArea5(e.target.value)}
                  className="form-control"
                  style={{ minHeight: "250px" }}
                />
                <div className="text-end mt-2">
                  <button onClick={() => saveData(textArea5, 5)} className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
              <div className="mb-4">
                <label className="form-label mb-1" style={{fontWeight:"500"}}>Prompt 6 (generate jd prompt)</label>
                <textarea
                  value={textArea6}
                  onChange={(e) => setTextArea6(e.target.value)}
                  className="form-control"
                  style={{ minHeight: "250px" }}
                />
                <div className="text-end mt-2">
                  <button onClick={() => saveData(textArea6, 6)} className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
              <div className="mb-4">
                <label className="form-label mb-1" style={{fontWeight:"500"}}>Prompt 7 (after symentic search results prompt)</label>
                <textarea
                  value={textArea7}
                  onChange={(e) => setTextArea7(e.target.value)}
                  className="form-control"
                  style={{ minHeight: "250px" }}
                />
                <div className="text-end mt-2">
                  <button onClick={() => saveData(textArea7, 7)} className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
}

export default AdminPrompts;
