import React, { useEffect, useState } from 'react';
import AdminSidebar from './AdminSidebar';
import Navbar from './Navbar';
import '../styles/AdminToken.css'; 

function AdminToken() {
    const [highestInputToken, setHighestInputToken] = useState(0);
    const [sumInputToken, setSumInputToken] = useState(0);
    const [sumOutputToken, setSumOutputToken] = useState(0);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/token/getToken`, {
            method: 'GET',
        })
        .then((res) => res.json())
        .then((data) => {
            const tokens = data.msg;
            console.log("Fetched tokens:", tokens);

            const parsedTokens = tokens.map(token => ({
                inputToken: parseFloat(token.inputToken) || 0,
                outputToken: parseFloat(token.outputToken) || 0,
            }));
            
            const highestInput = Math.max(...parsedTokens.map(token => token.inputToken));
            const totalInput = parsedTokens.reduce((acc, token) => acc + token.inputToken, 0);
            const totalOutput = parsedTokens.reduce((acc, token) => acc + token.outputToken, 0);

            setHighestInputToken(highestInput);
            setSumInputToken(totalInput);
            setSumOutputToken(totalOutput);
        })
        .catch((err) => {
            console.error("Error fetching tokens:", err.message);
        });
    }, []);

    return (
        <div className="MainContainer">
            <div style={{ height: "70px" }}>
                <Navbar />
            </div>
            <hr />
            <div className="container second-main">
                <div className="row">
                    <div className="col-1 col-lg-3 sidebarDiv">
                        <AdminSidebar />
                    </div>
                    <div className="col-lg-9">
                        <div className="content">
                            <h2>Token Statistics</h2>
                            <p>Highest Input Token: {highestInputToken}</p>
                            <p>Total Input Tokens: {sumInputToken}</p>
                            <p>Total Output Tokens: {sumOutputToken}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dashboard-footer text-center body-font-4 text-gray-500">
                Copyright © 2024 Neverhunt All rights reserved.
            </div>
        </div>
    );
}

export default AdminToken;
