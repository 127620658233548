import React from 'react';
import ClientNavbar from '../components/ClientNavbar';
import CompanySidebar from '../components/CompanySidebar';
import { Helmet } from 'react-helmet';
import 'animate.css';
import Navbar from '../components/Navbar';

function CompanyDashboard() {
  return (
    <div className="MainContainer">
      <Helmet>
        <title>Company Dashboard</title>
      </Helmet>
      <div style={{ height: "70px" }}>
        <Navbar />
      </div>

      <hr />
      <div className="container second-main">
        <div className="row">
          <div className="col-1 col-lg-3 sidebarDiv">
            <CompanySidebar />
          </div>
          <div className="col-11 col-lg-9 animate__animated animate__fadeIn">
            <h2 className="text-center my-4">Company Dashboard</h2>
            {/* Add your content here */}
          </div>
        </div>
      </div>
      <div className="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
}

export default CompanyDashboard;
