import React from 'react';
import AllRoutes from './components/AllRoutes';
import ErrorBoundary from './components/ErrorBoundary';

// import Navbar from './components/Navbar';
// import Sidebar from './components/Sidebar';
// import Routes2 from './components/Routes2';

function App() {
  return (
    <>
    
      {/* <div style={{ display: "flex", width: "100%" }}>
        <div style={{ flex: "10%" }}>
         
          <Sidebar />
        </div>
        <div className="pageContent" style={{ flex: "75%", display: "flex", flexDirection: "column" }}>
          
          <Navbar />
          <AllRoutes />
        </div>
      </div> */}

      
<ErrorBoundary>

      <AllRoutes />
      </ErrorBoundary>
      
      
    </>
  );
}

export default App;