import React, { useEffect, useState, useRef } from "react";
import { ReactMediaRecorder } from "react-media-recorder-2";
import { FaTrash } from "react-icons/fa";
import "./signleJob2.css";
import { useNavigate, useParams } from "react-router-dom";
import JobSeekerNavbar from "../components/JobSeekerNavbar";
import JobSidebar from "../components/Jobsidebar";
import stop_record from "../Image/stopred.svg";
import start_record from "../Image/record.svg";

function Singlejob2() {
  const [addQstn, setAddQstn] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [message, setMessage] = useState("");
  // const [audioUrl, setAudioUrl] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [deleteAudio, setDeleteAudio] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [audioData, setAudioData] = useState("");
  const [blobType, setBlobType] = useState("");
  const [audioFilename, setAudioFilename] = useState(""); // Add this line
  const fileInputRef = useRef(null);

  const token = localStorage.getItem("token");
  const email = JSON.parse(localStorage.getItem("email"));
  const { id } = useParams();
  const navigate = useNavigate();

  const uniqueID = localStorage.getItem("uniqueID");

  useEffect(()=>{
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/token-expire?email=${email}`, {
     method: 'GET',
     headers: {
       "Authorization": token
     }
    })
    .then((res)=> res.json())
    .then((data)=>{
      console.log("data token",data.msg);
     if(data.msg === "Invalid token. Please login"){
      //  setIsToeknValid(false)
       localStorage.removeItem("token");
       navigate(`/`);

     }else {
      //  setIsToeknValid(true)
     }
   
    })
    .catch((err)=>{
     console.log(err);
    })
 }, [token])

  useEffect(() => {
    if (!token) {
      navigate(`/SingleJobPost/${id}/a`);
    }
  }, []);

  localStorage.setItem(`${id}`, JSON.stringify("true"));

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/onejob/${id}`)
      .then((res) => res.json())
      .then((data) => {
        console.log("qssnChecking", data.msg.addNewQuestion)
        setAddQstn(data.msg.addNewQuestion);
        setAnswer(new Array(data.msg.addNewQuestion.length).fill(""));
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [uniqueID]);

  const handleAnswerChange = (index, event) => {
    const updatedAnswer = [...answer];
    updatedAnswer[index] = event.target.value;
    setAnswer(updatedAnswer);
  };

  const handleRecordingStart = () => {
    setIsRecording(true);
  };

  // const downloadAndSetAudio = (blobUrl) => {
  //   fetch(blobUrl)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.style.display = 'none';
  //       a.href = url;
  //       a.download = 'recorded_audio.wav';
  //       document.body.appendChild(a);
  //       a.click();
  //      window.URL.revokeObjectURL(url);
  //     });
  // };

  const handleRecordingStop = (blobUrl) => {
    setIsRecording(false);
    if (deleteAudio) {
      setAudioBlob(null);
      setDeleteAudio(false);
    } else {
      setAudioBlob(blobUrl);
      const userId = id; 
      const timestamp = new Date().toISOString().replace(/:/g, "-");
      const fileName = `user_${userId}_audio_${timestamp}.wav`;
      const fileType = "audio/wav"; 
      const fileSize = blobUrl.size;
      console.log("fileType: " + fileType,"size", fileSize,"filename", fileName);
      setAudioFilename(fileName)
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const audioData = event.target.result;
        console.log("Audio data: " + audioData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isFormValid = true;

    const updatedAnswers = answer.map((ans, index) => {
      if (ans.trim() === "") {
        isFormValid = false;
      }
      return ans;
    });

    setAnswer(updatedAnswers);
    setFormSubmitted(true);

    if (!isFormValid) {
      setMessage("Please answer all the questions before submitting.");
      return;
    }

    // Create form data
    // const formData = new FormData();
    // formData.append("answers", answer);
    // formData.append("jobUniqueID", id);
    // formData.append("audioData", audioBlob);
    // const formData = new FormData();
    // formData.append("answers", JSON.stringify(answer));
    // formData.append("jobUniqueID", id);
    // formData.append("file", audioBlob);
    const obj = {
      answers: answer,
      jobUniqueID: id,
      audioData: "No Auido",
    };
    console.log("obj", obj);
    // for (const pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }
    // Add audioBlob to formData if available
    // formData.append("file", audioBlob);
    //`${process.env.REACT_APP_BACKEND_BASE_URL}/api/upload/${id}`
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/upload/${id}`, {
      method: "POST",
      headers: {
        Authorization: token,
        "content-type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle response data
        console.log("response", data.msg);
        if (data.msg === "Your response has been submitted") {
          setMessage(`${data.msg}. Redirecting you to Dashboard...`);
        } else {
          setMessage(`${data.msg}`);
        }
        setTimeout(() => {
          if (data.msg === "Your response has been submitted") {
            navigate("/Jobseeker");
          }
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="MainContainer">
      <div style={{ height: "70px" }}>
        <JobSeekerNavbar />
      </div>

      <hr />
      <div className="container second-main">
        <div className="row">
          <div className="col-1 col-lg-3 sidebarDiv noclor">
            <JobSidebar />
          </div>
          <div className="col-lg-9">
            <div className="container w-100">
              {message && (
                <div className="mt-3 alert alert-info text-center">
                  {message}
                </div>
              )}
  
              <div className="row justify-content-center border">
                {/* <div className="text-center mt-4">
                  <span className="record-text" style={{ gap: "200px" }}>
                    Personalize your application with a voice note
                  </span>
                  <div style={{ height: "20px" }}></div>
                  <ReactMediaRecorder
                    audio
                    render={({
                      status,
                      startRecording,
                      stopRecording,
                      mediaBlobUrl,
                    }) => (
                      <>
                        {isRecording ? (
                          <div className="wave-animation">
                            <div className="bar bar1"></div>
                            <div className="bar bar2"></div>
                            <div className="bar bar3"></div>
                            <div className="bar bar4"></div>
                            <div className="bar bar5"></div>
                          </div>
                        ) : null}
                        {mediaBlobUrl && !deleteAudio && (
                          <div
                            className="mb-3"
                            style={{ maxWidth: "300px", margin: "0 auto" }}
                          >
                            <audio
                              src={mediaBlobUrl}
                              controls
                              style={{ width: "100%" }}
                            />
                            <FaTrash
                              className="delete-icon"
                              onClick={() => setDeleteAudio(true)}
                            />
                          </div>
                        )}
                        <p>{status}</p>
                        <button
                          className="start_rec"
                          onClick={startRecording}
                          disabled={isRecording}
                        >
                          <img src={start_record} width="50px" alt="" />
                        </button>
                        <button
                          className="stop_rec"
                          onClick={stopRecording}
                          disabled={!isRecording}
                        >
                          <img src={stop_record} width="50px" alt="" />
                        </button>
                      </>
                    )}
                    onStart={handleRecordingStart}
                    onStop={handleRecordingStop}
                  />
                  <div style={{ display: "none" }}>
                    <input
                      type="file"
                      accept="audio/*"
                      ref={fileInputRef}
                      onChange={handleFileUpload}
                    />
                  </div>
                </div> */}
                {addQstn.length === 0 ? (
                  <p style={{ marginLeft: "25px" }}></p>
                ) : (
                  <p style={{ marginLeft: "25px" }}>
                    Please answer the questions
                  </p>
                )}

                {addQstn.map((qstn, index) => (
                  <div
                    key={index}
                    className="mb-3"
                    style={{ marginLeft: "25px" }}
                  >
                   {qstn.title ? <p>{qstn.title}</p> : <p>{qstn}</p>}
                    <input
                      type="text"
                      value={answer[index]}
                      onChange={(event) => handleAnswerChange(index, event)}
                      className={`form-control ${
                        formSubmitted && answer[index].trim() === ""
                          ? "red-border"
                          : ""
                      }`}
                      required
                    />
                  </div>
                ))}
                <div className="text-center">
                  <button
                    className="btn btn-primary mt-3 mb-5  rec-submmit"
                    type="submit"
                    onClick={handleFormSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
              {/* </form> */}
            </div>
            {/* <ToastContainer /> */}
          </div>
        </div>
      </div>
      <div className="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
}

export default Singlejob2;
