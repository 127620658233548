import React, { useEffect, useState } from 'react';
import CompanySidebar from './CompanySidebar';
import Navbar from './Navbar';

function CompanyProfile() {
    const [companyName, setCompanyName] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [companyLogo, setCompanyLogo] = useState(null);
    const [companyAddress, setCompanyAddress] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    const token = localStorage.getItem('token');
    // ${process.env.REACT_APP_BACKEND_BASE_URL}/company/companyinfo
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/company/companyinfo`, {
          method: 'GET',
          headers: {
            "content-type": "application/json",
            Authorization: token
          },
        })
          .then((res) => res.json())
          .then((data) => {
           setCompanyName(data.msg.companyName)
           setCompanyWebsite(data.msg.companyWebsite)
           setCompanyAddress(data.msg.companyAddress)
           console.log("Company Name: " , data.msg)
           if (data.msg?.companyLogo) {
          
                setLogoUrl(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/companyLogoView/${data.msg?.companyLogo}/${data.msg?._id}`
                  );
          }
          })
          .catch((err) => {
            console.log("comp id check", err.message);
          });
      }, []);

    const handleUpdate = (e) => {
        e.preventDefault();
        // Handle the update logic here, such as sending the data to a server or updating the state.
        const formData = new FormData();
        formData.append('companyName', companyName);
        formData.append('companyWebsite', companyWebsite);
        formData.append('logo', companyLogo);
        formData.append('companyAddress', companyAddress);

        console.log('Company Info Updated:', { companyName, companyWebsite, companyLogo, companyAddress });
        // You can now send formData to your server
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/updateCompanyInfo`, {
            method: 'PUT',
            headers:{
                Authorization: token
            },
            body: formData
        })
        .then((res)=> res.json())
        .then((data)=>{
            setMessage("Company info updated successfully!");
            setMessageType("success");
            console.log("companyInfo Updated:", data.msg);
        })
        .catch((err)=>{
            setMessage("Error updating company info.");
            setMessageType("error");
            console.log("companyInfo Error:", err.message);
        });
    };

    return (
        <div className="MainContainer">
            <div style={{ height: "70px" }}>
                <Navbar />
            </div>

            <hr />
            <div className="container second-main">
                <div className="row">
                    <div className="col-1 col-lg-3 sidebarDiv">
                        <CompanySidebar />
                    </div>
                    <div className="col-11 col-lg-9 animate__animated animate__fadeIn">
                        <h2 className="text-center my-4">Company Profile</h2>
                        {message && (
                            <div className={`alert ${messageType === 'success' ? 'alert-success' : 'alert-danger'}`} role="alert">
                                {message}
                            </div>
                        )}
                        <form onSubmit={handleUpdate}>
                            <div className="form-group">
                                <label htmlFor="companyName">Company Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="companyName"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="companyWebsite">Company Website</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="companyWebsite"
                                    value={companyWebsite}
                                    onChange={(e) => setCompanyWebsite(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="companyLogo">Company Logo</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="companyLogo"
                                    onChange={(e) => setCompanyLogo(e.target.files[0])}
                                />
                            </div>
                            {logoUrl && (
                                <div>
                                    <img
                                        src={logoUrl}
                                        alt="Company Logo"
                                        style={{ maxWidth: "100px", marginBottom: "10px" }}
                                    />
                                </div>
                            )}
                            <div className="form-group">
                                <label htmlFor="companyAddress">Company Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="companyAddress"
                                    value={companyAddress}
                                    onChange={(e) => setCompanyAddress(e.target.value)}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary mt-3">Update</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="dashboard-footer text-center body-font-4 text-gray-500">
                Copyright © 2024 Neverhunt All rights reserved.
            </div>
        </div>
    );
}

export default CompanyProfile;
