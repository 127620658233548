import React, { useEffect, useState } from "react";
import "../styles/jobRecommandation.css";
import ReactHtmlParser from 'html-react-parser';
import search from "../Image/search.svg";
import location from "../Image/location.svg";
import caretdown from "../Image/CaretDownblack.svg";


function JobRecommandations() {
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [filteredjobs, setFilteredjobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [displayedJobs, setDisplayedJobs] = useState(5);
  const [searchLocation, setSearchLocation] = useState("");
  const [ziploc, setZiploc] = useState("");
  const [dateFilter, setDateFilter] = useState(null);
  const [jobTypeFilter, setJobTypeFilter] = useState(null);
  const [jobtype, setJobType] = useState(null);
  const [taxterm, setTaxterm] = useState(null);
  const [datefil, setDatefil] = useState(false);
  const [milesFilter, setMilesFilter] = useState(null)
  const [searchResults, setSearchResults] = useState([]);
  const [locationSuggestions, setLocationSuggestions] = useState([]);

  const fetchJobs = async () => {
    try {
      const queryParams = new URLSearchParams();
      queryParams.append('search', searchTerm);
      queryParams.append('location', ziploc);
      queryParams.append('dateFilter', dateFilter);
      queryParams.append('jobTypeFilter', jobTypeFilter);
      queryParams.append('jobtype', jobtype);
      queryParams.append('taxterm', taxterm);
      queryParams.append('milesFilter', milesFilter);
  //${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getalljobs?${queryParams.toString()
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getalljobs?${queryParams.toString()}`
      );
      const data = await response.json();
      setSearchResults(data.msg);
      setSelectedJob(data.msg[0]);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

useEffect(()=>{
    setSearchTerm('');
    setSearchLocation('');
    setDateFilter('');
    setJobTypeFilter('');
    setJobType('');
    setTaxterm('');
    setMilesFilter('');
  
}, [])

const fetchLocationSuggestions = async (input) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/locations?search=${input}`
    );
    const data = await response.json();
    console.log("loc checking", data.msg);
    setLocationSuggestions(data.msg);
    console.log("location suggestion check", data.msg)
    // setSearchLocation(data.msg[data.msg.length-1].zip)
  } catch (error) {
    console.error("Error fetching location suggestions:", error);
  }
};

const handleLocationChange = (e) => {
  const input = e.target.value;
  setZiploc(input)
  setSearchLocation(input); // Set the input value immediately

  // Fetch location suggestions
  fetchLocationSuggestions(input);
};

const handleLocationSuggestionClick = (suggestion) => {
  setSearchLocation(`${suggestion.city} ${suggestion.state}, ${suggestion.zip}`); 
  setZiploc(suggestion.zip)
  setTimeout(() => {
  setLocationSuggestions([])
  }, 3000);

  // Optionally, you can also populate zip code if available
};

const renderLocationSuggestions = () => {
  if (locationSuggestions.length === 0) return null;
  return (
    <ul className="location-suggestions">
      {locationSuggestions.map((suggestion, index) => (
        <li key={index} onClick={() => handleLocationSuggestionClick(suggestion)}>{suggestion.city} {suggestion.state}, {suggestion.zip}</li>
      ))}
    </ul>
  );
};

  // Function to handle click on a job
  const handleJobClick = (job) => {
    setSelectedJob(job);
  };

  // Function to render job details when a job is clicked
  const renderJobDetails = () => {
    if (selectedJob && searchResults.find(job => job._id === selectedJob._id)) {
      return (
        <div className="job-details">
          <h2 style={{ fontWeight: "600" }}>{selectedJob.position}</h2>
          <p>
            <span style={{ fontWeight: "600" }}></span>
            {selectedJob.companyName}
          </p>
          <p>
          {selectedJob.city} {selectedJob.state}
          </p>
          <p>
          <span
                style={{
                  background: "#E7F0FA",
                  borderRadius: "3px",
                  padding: "3px 6px",
                  margin: "-88px 10px 5px 0px",
                }}
              >
                {selectedJob.salary}
              </span>
              <span
                style={{
                  background: "#CEECD5",
                  borderRadius: "3px",
                  padding: "3px 6px",
                  marginRight:"10px"
                }}
              >
                {selectedJob.jobLocType}
              </span>
              <span
                style={{
                  background: "#F9DCDC",
                  borderRadius: "3px",
                  padding: "3px 6px",
                }}
              >
                {selectedJob.jobType}
              </span>
          </p>
          <button className="applyButton mt-2 mb-2">Apply now</button>
          <p>
            <h6 style={{ fontWeight: "600" }}>Job description :-</h6>
            {ReactHtmlParser(selectedJob.jobDescription.trim())}
          </p>
          <p>
            <h6 style={{ fontWeight: "600" }}>Location:</h6>
            {selectedJob.city}
          </p>
          {/* Add more details as needed */}
        </div>
      );
    }
  };

  // Function to render list of jobsa
  const renderJobList = () => {
    const jobsToDisplay = searchResults.length > 0 ? searchResults.slice(0, displayedJobs) : (jobs ? jobs.slice(0, displayedJobs) : []);
  
    const handleLoadMore = () => {
      setDisplayedJobs(prev => prev + 5);
      setLocationSuggestions([])
    };
  
    return (
      <div className="job-list">
        {jobsToDisplay.length > 0 ? (
           <ul>
           {jobsToDisplay.map((job) => (
             <div
               className={`jobBox ${
                 selectedJob && selectedJob._id === job._id ? "selected" : ""
               }`}
               key={job._id}
               onClick={() => handleJobClick(job)}
             >
               <h2 className="companyName">{job.position}</h2>
               <li>{job.companyName}</li>
               <li style={{ marginBottom: "10px" }}>
                 {job.city} {job.state}
               </li>
               <span
                 style={{
                   background: "#E7F0FA",
                   borderRadius: "3px",
                   padding: "3px 6px",
                   margin: "-88px 10px 5px 0px",
                 }}
               >
                 {job.salary}
               </span>
               <span
                 style={{
                   background: "#CEECD5",
                   borderRadius: "3px",
                   padding: "3px 6px",
                   marginRight: "10px"
                 }}
               >
                 {job.jobLocType}
               </span>
               <span
                 style={{
                   background: "#F9DCDC",
                   borderRadius: "3px",
                   padding: "3px 6px",
                 }}
               >
                 {job.jobType}
               </span>
               <li style={{ marginTop: "10px" }}>
                 <span
                   style={{
                     fontWeight: "600px",
                     marginBottom: "10px",
                     marginTop: "10px",
                   }}
                 >
                   Summary
                 </span>
               </li>
               <li>{ReactHtmlParser(truncateSummary(job.jobDescription))}</li>
             </div>
           ))}
         </ul>
        ) : (
          <p style={{ textAlign: "center", color: "#0A65CC" }}>
            No Jobs Available
          </p>
        )}
        {/* Render load more button if there are more jobs to load */}
        {searchResults.length > displayedJobs && (
        <p onClick={handleLoadMore} style={{ cursor: "pointer", color: "#0A65CC", textAlign: "center" }}>
          Load More
        </p>
      )}
      </div>
    );
  };
  

  const truncateSummary = (summary) => {
    console.log("into truncate function");
    const maxLength = 120; // Max length for summary text

    // Create a temporary HTML element to parse the summary
    const tempElement = document.createElement('div');
    tempElement.innerHTML = summary;

    // Extract plain text content from the temporary element
    const plainText = tempElement.textContent;
    
    // Truncate the plain text if needed
    if (plainText.length <= maxLength) {
        return plainText;
    }
    const truncatedText = plainText.substring(0, maxLength); // Truncate summary
    return truncatedText + '...'; // Return truncated summary as string
};

  const handleDate = (e) => {
    setDatefil(!datefil);
  }
  
  useEffect(() => {
    if (datefil !== null) {
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/alljobsfilter/${datefil}`, {
        method: "GET"
      })
      .then((res) => res.json())
      .then((data) => {
        setSearchResults(data.msg)
        // setJobs(data.msg);
        console.log("data of location sort", data.msg);
      })
      .catch((err) => {
        console.log("error in location sort", err.message);
      });
    }
  }, [datefil]);

  const handleSearch = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
  
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/getalljobs?search=${newSearchTerm}`, {
      method: "GET"
    })
      .then((res) => res.json())
      .then((data) => {
        // setJobs(data.msg)
        setSearchResults(data.msg)
        console.log("search results: " , data.msg)
        // setJobs(data.msg);
      })
      .catch((err) => {
        console.log("error", err.message);
      });
  };
  

  // useEffect hook to fetch jobs when the component mounts
  useEffect(() => {
    fetchJobs();
  }, [searchTerm, searchLocation, dateFilter, jobTypeFilter, jobtype, taxterm, milesFilter]);


  return (
    <div className="container">
      <div className="row">
        <div className="search-container">
          <img src={search} alt="search icon" className="search-icon" color="blue"/>
          <input 
            type="text" 
            className="search-input" 
            value={searchTerm} 
            onChange={(e)=>handleSearch(e)} 
            placeholder="job title, keyword" 
            style={{borderColor: 'rgba(108, 163, 224, 1)'}} 
          />
            
          <img src={location} alt="location icon" className="location-icon" />
         
          <input 
            type="text" 
            className="search-input" 
            value={searchLocation} 
            onChange={handleLocationChange} 
            placeholder="Search by location" 
            style={{borderColor: 'rgba(108, 163, 224, 1)'}} 
          />
         
          
          
          <button className="search-button">Search</button>
          
        </div>
        {renderLocationSuggestions()}
        

        <div className="filters-container">
          <div className="filter" >
          <img src={caretdown} alt="caret down icon" className="caret-icon" />
            <select value={dateFilter} onChange={(e) => setDateFilter(e.target.value)}>
              <option value="">Date Posted</option>
              <option value="24">Last 24 hrs</option>
              <option value="3">Last 3 days</option>
              <option value="7">Last 7 days</option>
              <option value="14">Last 14 days</option>
            </select>
          </div>
          <div className="filter" >
          <img src={caretdown} alt="caret down icon" className="caret-icon" />
            <select value={jobTypeFilter} onChange={(e) => setJobTypeFilter(e.target.value)}>
              <option value="Remote">Remote</option>
              <option value="Onsite">Onsite</option>
              <option value="Hybrid">Hybrid</option>
            </select>
          </div>
          <div className="filter">
          <img src={caretdown} alt="caret down icon" className="caret-icon" />

            <select value={jobtype} onChange={(e) => setJobType(e.target.value)}>
              <option value="">Job Type</option>
              <option value="Fulltime">Full Time</option>
              <option value="Contract">Contract</option>
              <option value="Contract to hire">Contract to hire</option>
              <option value="Internship">Internship</option>
            </select>
          </div>
          <div className="filter">
          <img src={caretdown} alt="caret down icon" className="caret-icon" />

            <select value={taxterm} onChange={(e) => setTaxterm(e.target.value)}>
              <option value="">Tax Term</option>
              <option value="W2">W2</option>
              <option value="C2C">C2C</option>
              <option value="1099">1099</option>
            </select>
          </div>
          {searchLocation && searchLocation.trim() !== '' && (
            <div className="filter">
            <img src={caretdown} alt="caret down icon" className="caret-icon" />
              <select value={milesFilter} onChange={(e) => setMilesFilter(e.target.value)}>
              <option value="">Miles</option>
                <option value="8046">Within 5 miles</option>
                <option value="16093">Within 10 miles</option>
                <option value="24140">Within 15 miles</option>
                <option value="32186">Within 20 miles</option>
                <option value="48280">Within 30 miles</option>
                <option value="80467">Within 50 miles</option>
                <option value="160934">Within 100 miles</option>
              </select>
            </div>
          )}
        </div>
        <p style={{color:"rgba(24, 25, 28, 1)", fontSize:"18px", fontWeight:"400", }}>Suggested Jobs</p>
        <p style={{marginTop:"-10px"}}>Sort by : <span onClick={handleDate} className="datefilter" style={{cursor:"pointer", color:"#0A65CC"}}>Date</span></p>
  
        <div style={{marginLeft:"-22px"}} className="col-md-6">{renderJobList()}</div>
  
        <div style={{marginLeft:"-30px"}} className="col-md-6">{renderJobDetails()}</div>
      </div>
  
      <div className="dashboard-footer text-center body-font-4 text-gray-500">
        © Neverhunt 2024 | All Rights Reserved
      </div>
    </div>
  );
  
  
}

export default JobRecommandations;