import React from 'react';
import Chart from 'react-apexcharts';

const HalfCircleGauge = ({ score }) => {
  const options = {
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: 15,
          size: '30%',
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: '13px',
          },
        },
      },
    },
    colors: ['#007bff'],
    labels: ['Score'],
  };

  const series = [score * 10];

  return (
    <div style={{ width: '150px'}}>
      <Chart
        options={options}
        series={series}
        type="radialBar"
        height={200}
      />
    </div>
  );
};

export default HalfCircleGauge;
